import { useState } from "react";
import './index.less';


interface IProps {
    icon: React.ReactNode
    hoverIcon: React.ReactNode
    className?: string
    onClick?: () => void
}

export default function IconButton(props: IProps) {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <div
            className={`tn-icon-button ${props?.className || ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => { if (props?.onClick) props.onClick() }}
        >
            {hover && props.hoverIcon}
            {!hover && props.icon}
        </div>
    )
}