import SideBarTool from "@/commons/components/side-bar-tool"
import "../../commons/styles/side-bar.less"
import "./index.less"
import { Input, Image, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { WallHeightImg, WallWidthImg } from "@/commons/constants/schematic-diagram";
import { graphicStore } from "@/commons/store/graphic-store";
import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { LayerType } from "@/commons/enums/layer-type";
import genUUID from "@/commons/utils/gen-uuid";
import { Layer } from "@/commons/interface/layer";
import { LineType } from "pytha";
import { useParams } from "react-router";
import { saveLayer } from "@/api/geometry/layer";
import { StoreyStatus } from "@/commons/context/storey-store";
import { StoreyContext } from "@/App";
import RadioGroup from "@/commons/components/radio-group";

export default function WallPanel() {

    const { projectId } = useParams();
    const [height, setHeight] = useState<number>(); // 高度
    const [leftWidth, setLeftWidth] = useState<number>(100); // 左宽
    const [rightWidth, setRightWidth] = useState<number>(100); // 右宽
    const [width, setWidth] = useState<number>(200); // 墙宽
    const storeyContext = useContext<StoreyStatus>(StoreyContext);

    const [useType, setUseType] = useState<boolean>(true);

    useEffect(() => {
        if(storeyContext.currentStorey && storeyContext.currentStorey.height){
            setHeight(storeyContext.currentStorey.height);
        }
    }, [storeyContext.currentStorey])

    const onAssembleWall = () => {
        let heightValue = height;
        if (!!!height) {
            let storeyHeight = storeyContext.currentStorey?.height
            if (!!storeyHeight) {
                heightValue = storeyHeight;
            } else {
                message.warning("请输入高度");
                return;
            }
        }
        if (!!!width) {
            message.warning("请输入左宽或右宽");
            return;
        }
        if (!graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl || graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl.id !== EXTRA_CTRL_TYPE.ASSEMBLE_WALL) {
            // 激活布置墙的二级控制器
            graphicStore.extraContext.getCurrentViewEditor().cmdControl.activateSubCommandControl("ASSEMBLEWALL");
            let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.WALL)?.uuid || null;
            if (!layerUuid) {
                // 不存在墙图层，新建墙图层
                let uuid = genUUID();
                let newLayer: Layer = {
                    uuid: uuid,
                    name: "墙",
                    color: "#DCDCDC",
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    lock: false,
                    hidden: false,
                    projectUuid: projectId,
                    originLayer: false,
                    type: LayerType.WALL,
                };
                saveLayer(newLayer).then(res => {
                    if (res.status === 200) {
                        graphicStore.context.layerContext.addLayer(newLayer);
                        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWall).dispatch(newLayer.uuid, heightValue, leftWidth, rightWidth, useType);
                    }
                }).catch(err => {
                    message.error("无法置为墙图层");
                    console.log(err);
                    let currentLayerUuid = graphicStore.context.layerContext.currentLayerUuid;
                    graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWall).dispatch(currentLayerUuid, heightValue, leftWidth, rightWidth, useType);
                })
            } else {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWall).dispatch(layerUuid, heightValue, leftWidth, rightWidth, useType);
            }
        }

    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    墙
                </div>
                <div className="detail wall-panel">
                    <div className="row compact">
                        <div className="label">高度:</div>
                        <Input
                            placeholder="同层高"
                            className="input-box"
                            value={height}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setHeight(parseInt(value))
                                } else {
                                    if (value == '') {
                                        setHeight(null);
                                    } else {
                                        setHeight(0)
                                    }
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    <div className="row compact">
                        <div className="label">左宽:</div>
                        <Input
                            className="input-box"
                            value={leftWidth}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setLeftWidth(parseInt(value))
                                    setWidth(parseInt(value) + rightWidth);
                                } else {
                                    if (value == '') {
                                        setLeftWidth(null);
                                    } else {
                                        setLeftWidth(0)
                                    }
                                    setWidth(rightWidth);
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    <div className="row compact">
                        <div className="label">右宽:</div>
                        <Input
                            className="input-box"
                            value={rightWidth}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setRightWidth(parseInt(value))
                                    setWidth(parseInt(value) + leftWidth);
                                } else {
                                    if (value == '') {
                                        setRightWidth(null);
                                    } else {
                                        setRightWidth(0)
                                    }
                                    setWidth(leftWidth);
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    <div className="row">
                        <div className="label">绘制方式:</div>
                        <RadioGroup
                            dataList={[
                                { label: '外墙', value: true },
                                { label: '内墙', value: false },
                            ]}
                            selected={useType}
                            onChange={(value) => setUseType(value)}
                        />
                    </div>
                    <div className="row compact">
                        <div className="label">墙宽:</div>
                        <Input
                            readOnly
                            className="input-box onlyread"
                            value={width}
                            suffix="mm"
                        />
                    </div>
                    <div className="schematic-diagram">
                        <Image width={100} height={90} preview src={WallHeightImg} />
                        <Image width={32} height={90} preview src={WallWidthImg} />
                    </div>
                    <div className="ope-area">
                        <div className="primary-btn" onClick={onAssembleWall}>绘制</div>
                        <div className="secondary-btn" onClick={() => {
                            if(storeyContext.currentStorey?.height){
                                setHeight(storeyContext.currentStorey?.height);
                            }else{
                                setHeight(null);
                            }
                            setLeftWidth(100);
                            setRightWidth(100);
                            setWidth(200);
                        }}><div className="text">还原</div></div>
                    </div>
                    {/* <div style={{flex:'1 1 auto'}}></div>
                    <div className="physical-area">
                        <div className="physical-info">
                            <div className="physical-info-title">物理信息</div>
                            <div className="msg-row">
                                <div className="tip">材质：</div>
                                <div className="detail">*</div>
                            </div>
                            <div className="msg-row">
                                <div className="tip">楼层：</div>
                                <div className="detail">*</div>
                            </div>
                            <div className="msg-row">
                                <div className="tip">建筑功能：</div>
                                <div className="detail">*</div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}