import { useNavigate } from "react-router";
import { EditableCell, EditableRow } from "tncet-common";
import { useEffect, useState } from "react";
import { Project } from "./commons/interface/first-party/project";
import { ReactComponent as TrademarkSvg } from '@/commons/icons/project-list/tra.svg'
import { ReactComponent as ShowSvg } from '@/commons/icons/project-list/show.svg'
import { ReactComponent as ReturnSvg } from '@/commons/icons/project-list/return.svg'
import { ReactComponent as SearchSvg } from '@/commons/icons/project-list/search.svg'

import "./project-main.less"
import { GetProp, Input, Table, TablePaginationConfig, TableProps } from "antd";

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}



const tempProjectList = [
    { uuid: '001', houseName: "上海小木桥路办公楼" },
    { uuid: '石泉春晓地块', houseName: "石泉春晓场地" },
    { uuid: 'SQCX1', houseName: "石泉春晓1#2#" },
    { uuid: '002', houseName: "石泉春晓12#" },
    { uuid: '888', houseName: "石泉春晓8#" },
    // { id: 3, houseName: "wGrhirSMMW" },
    // { id: 4, houseName: "FbwWJHtvaA" },
    // { id: 5, houseName: "kjgatNCEso" },
    // { id: 6, houseName: "WePOYVIcOO" },
    // { id: 7, houseName: "dgSPVWKAIZ" },
    // { id: 8, houseName: "mFDAHLRmRo" },
    // { id: 9, houseName: "yHCxxtOuoo" },
    // { id: 10, houseName: "tiSOQNtpdl" },
    // { id: 11, houseName: "vedAhCQcqr" }
]

export default function ProjectMain() {

    // const [projectId, setProjectId] = useState<string>("")
    const [searchInput, setSearchInput] = useState<string>("")
    const [projectList, setProjectList] = useState<Project[]>([])
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    useEffect(() => {
        generateData()
    }, [])

    const generateData = () => {
        // TODO 换成实际接口
        setProjectList(tempProjectList)
    }

    const selectProject = (uuid) => {
        navigate(`/project/${uuid}`)
    }

    const fetchData = () => {
        setLoading(true);
        setProjectList(tempProjectList);
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: tempProjectList.length, //todo 根据api查询总数
            },
        });
        setLoading(false)

        // todo: 切换真实api
        // fetch(`${qs.stringify(getRandomuserParams(tableParams))}`)
        //     .then((res) => res.json())
        //     .then(({ results }) => {
        //         setProjectList(tempProjectList);
        //         setLoading(false);
        //         setTableParams({
        //             ...tableParams,
        //             pagination: {
        //                 ...tableParams.pagination,
        //                 total: 200,
        //                 // 200 is mock data, you should read it from server
        //                 // total: data.totalCount,
        //             },
        //         });
        //     });
    };

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setProjectList(tempProjectList);
        }
    };

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize]);

    return (
        <div className="project">
            <div className="head">
                <TrademarkSvg className="trademark-icon" />
                <ShowSvg className="show-icon" />
                {window.history?.length > 1 && <ReturnSvg className="return-icon" onClick={() => { window.history.back() }} />}
            </div>
            <div className="list-title">
                项目列表
            </div>
            <div className="project-list">
                <div className="list-main">
                    <div className="search">
                        <Input size="small" prefix={<SearchSvg />} placeholder="搜索项目" value={searchInput} onChange={(e) => { setSearchInput(e.target.value) }} />
                    </div>
                    <Table
                        dataSource={projectList.filter(item => item.houseName && item.houseName.toLowerCase().includes(searchInput.toLowerCase()))}
                        className='tn-project-table'
                        pagination={tableParams.pagination}
                        bordered={false}
                        loading={loading}
                        onChange={handleTableChange}
                        size="small"
                        scroll={{ y: 600 }}
                        virtual
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowKey="id"
                        onRow={(row) => {
                            return {
                                onClick: event => { selectProject(row.uuid) },
                            }
                        }}
                    >
                        <Table.Column width={"30.76%"} title="项目名称" dataIndex="houseName" />
                        <Table.Column width={"19.74%"} title="所属单位" dataIndex="firstLevelDeptName" />
                        <Table.Column width={"18.74%"} title="经营单位" dataIndex="operateDeptName" />
                        <Table.Column width={"30.76%"} title="住址" dataIndex="houseAddress" />

                    </Table>
                </div>
            </div>
        </div>
    )
}