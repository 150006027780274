import { Button, Input } from 'antd';
import './index.less';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import localStore, { LOCAL_USER_TYPE, REMEMBER_PASSWORD, REMEMBER_TAG, REMEMBER_USERNAME } from '@/commons/store/local-store';
import { ReactComponent as CheckSvg } from '@/commons/icons/table/check.svg';
import { ReactComponent as CheckedSvg } from '@/commons/icons/table/checked.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TnSvg } from './icons/tn.svg';
import { ReactComponent as UserSvg } from './icons/user.svg';
import { ReactComponent as PwdSvg } from './icons/pwd.svg';
import TnMessage from '@/commons/components/message';
import { getUserByLogin } from '@/api/cooperate/user';
import { User } from '@/commons/interface/cooperate/user';


interface IProps {

}

export default function LoginMain(props: IProps) {
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>(""); // 用户名
    const [password, setPassword] = useState<string>(""); // 密码
    const [remember, setRemember] = useState<boolean>(false); // 记住我
    const [loading, setLoading] = useState<boolean>(false); // loading

    useEffect(() => {
        if (!!localStore?.userId) {
            // 已经登录，跳转到项目列表页
            navigate("/desktop");
        }
        // 从本地存储中获取自动保存的账户信息
        let username = localStore.get(REMEMBER_USERNAME);
        let remember = localStore.get(REMEMBER_TAG);
        let password = localStore.get(REMEMBER_PASSWORD) || '';
        setUsername(username);
        setRemember(remember);
        setPassword(CryptoJS.enc.Base64.parse(password).toString(CryptoJS.enc.Utf8));
    }, [])

    const login = () => {
        if (!username) {
            TnMessage.warning("用户名不能为空");
            return;
        }
        if (!password) {
            TnMessage.warning("密码不能为空");
            return;
        }
        setLoading(true);
        let md5Password = CryptoJS.MD5(password).toString();
        getUserByLogin(username, md5Password).then(res => {
            if (res.status === 200) {
                let user: User = res.data;
                TnMessage.success("登录成功");
                if (remember) {
                    localStore.set(REMEMBER_USERNAME, username);
                    localStore.set(REMEMBER_PASSWORD, CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(password)));
                    localStore.set(REMEMBER_TAG, remember);
                } else {
                    localStore.remove(REMEMBER_USERNAME);
                    localStore.remove(REMEMBER_PASSWORD);
                    localStore.remove(REMEMBER_TAG);
                }
                localStore.userId = user?.id;
                localStore.userUuid = user?.uuid;
                localStore.name = user?.name || '';
                localStore.token = user?.token || '';
                localStore.set(LOCAL_USER_TYPE, CryptoJS.MD5(user?.type?.toString()).toString());
                navigate("/desktop");
            }
        }).catch(err => {
            TnMessage.error(err.data || '登录失败');
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='login-container'>
            <div className='tn-logo'><TnSvg /></div>
            <div className='ellipse-1'></div>
            <div className='ellipse-2'></div>
            <div className="big-bg"></div>
            <div className="login-panel">
                {/* <div className="welcome">屋面视频监测</div> */}
                <Input
                    style={{ marginBottom: 16 }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    prefix={<UserSvg />}
                    placeholder='请输入用户名'
                />

                <Input
                    style={{ marginBottom: 16 }}
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    prefix={<PwdSvg />}
                    placeholder='请输入密码'
                    onPressEnter={login}
                />
                <div className='remeber-me' onClick={() => setRemember(pre => !pre)}>
                    {!remember && <CheckSvg />}
                    {remember && <CheckedSvg />}
                    记住我
                </div>
                <Button onClick={login} loading={loading}>登录</Button>
            </div>
        </div >
    )
}