import { RoadType } from "@/commons/interface/building/road-type";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";


export function getRoadTypeListByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/road-types`, {
        params: {
            ...params
        }
    })
}

export function getRoadTypeDetailByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/road-types/${uuid}`, {
        params: {
            ...params
        }
    })
}

export function deleteByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/road-types/${uuid}`, {
        params: {
            ...params
        }
    })
}

export function deleteByProjectId(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/road-types`, {
        params: {
            ...params
        }
    })
}
// 更新路型，若roadLaneList不为空则同时更新车道列表
export function updateRoadType(uuid: string, data: RoadType, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/road-types/${uuid}`, data, {
        params: {
            ...params
        }
    })
}

export function addRoadType(projectUuid: string, data: RoadType, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/road-types`, data, {
        params: {
            ...params
        }
    })
}



