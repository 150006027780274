
import SideBarTool from "@/commons/components/side-bar-tool";
import { useContext, useState } from "react";
import './index.less';
import { Input, message } from "antd";
import { nonNegativeIntegerVerification } from "@/commons/utils/antd-input";
import { AddLayerCommand, BlockT, LineType, Point } from "pytha";
import genUUID from "@/commons/utils/gen-uuid";
import { Vector3 } from "three";
import { SideContext, StoreyContext } from "@/App";
import { StoreyStatus } from "@/commons/context/storey-store";
import { graphicStore } from "@/commons/store/graphic-store";
import { LayerType } from "@/commons/enums/layer-type";
import { BUILDING_SINGAL, ElevatorCarT } from "tnbimbase";
import { SideStatus } from "@/commons/context/side-store";
import { ReactComponent as BoxSvg } from "@/commons/icons/preview/box.svg";
import { Layer } from "@/commons/interface/layer";
import { useParams } from "react-router-dom";



export default function ElevatorCarPanel() {
    const { projectId } = useParams();
    const [width, setWidth] = useState<string>("3000");
    const [length, setLength] = useState<string>("3000");
    const [height, setHeight] = useState<string>("");
    // const [freshFlag, setFreshFlag] = useState<string>("");
    // const [entities, setEntities] = useState<any[]>([]);
    const storeyContext = useContext<StoreyStatus>(StoreyContext);
    const sideContext = useContext<SideStatus>(SideContext);


    // useEffect(() => {
    //     let height = storeyContext.currentStorey?.height || 0;
    //     setHeight(height.toString());
    //     // setFreshFlag(genUUID());
    // }, [storeyContext.currentStorey])

    const checkInput = () => {
        if (!width || !length || width === '' || length === '') {
            return false;
        }
        let storeyHeight = storeyContext.currentStorey?.height
        let heightValue = height;
        if ((!height || height === '')) {
            if (storeyHeight) {
                heightValue = storeyHeight.toString()
            } else {
                return null;
            }
        }

        let boxWidth = Number(width) || 0;
        let boxLength = Number(length) || 0;
        let boxHeight = Number(heightValue) || 0;
        if (boxWidth <= 0 || boxLength <= 0 || boxHeight <= 0) {
            return false;
        }
        return true;
    }

    const onSave = () => {
        if (!checkInput()) {
            message.warning("宽度、深度、高度值均不能为空且必须大于0");
            return;
        }
        if (!graphicStore.context.layerContext.layers.some(item => item.type === LayerType.ELEVATOR_CAR)) {
            // 没有轿厢图层，新增轿厢图层
            let uuid = genUUID();
            let newLayer: Layer = {
                id: uuid,
                uuid: uuid,
                name: '轿厢',
                color: '#2b2b2b',
                lineWidth: 0.25,
                lineType: LineType.BASE_LINE,
                originLayer: false,
                projectUuid: projectId,
                type: LayerType.ELEVATOR_CAR,

            }
            new AddLayerCommand(newLayer).execute();
        }
        let storeyHeight = storeyContext.currentStorey?.height
        let heightValue = height;
        if ((!height || height === '')) {
            if (storeyHeight) {
                heightValue = storeyHeight.toString()
            }
        }
        let boxWidth = Number(width) || 0;
        let boxLength = Number(length) || 0;
        let boxHeight = Number(heightValue) || 0;
        let basePoint = new Point(0, 0, 0);
        let dirX = new Vector3(1, 0, 0);
        let dirY = new Vector3(0, 1, 0);
        let dirZ = new Vector3(0, 0, 1);
        let box = new ElevatorCarT(basePoint, boxLength, boxWidth, boxHeight, dirX, dirY, dirZ);
        box.color.set(0, 102, 255);

        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("CONTINUOUSLYASSEMBLEBLOCKCONTROL");
        let blockT = new BlockT();
        blockT.setEntities([box]);
        // 标底高
        let offset = 0;
        if (storeyContext.storeys?.some(item => item.uuid === graphicStore.context.ucsContext.currentUcs.uuid)) {
            // 当前是层高坐标系
            offset = (storeyContext.currentStorey?.bottomElevation * 1000) || 0;
        } else {
            // ucs
            offset = graphicStore.context.ucsContext.currentUcs.origin.z;
        }
        let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.ELEVATOR_CAR)?.uuid || null;
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal(BUILDING_SINGAL.onContinuouslyAssembleBlock)
            .dispatch(blockT, [box], new Vector3(0, 0, 0), layerUuid, offset);
        // message.info("请在绘图区布置");
        // if (entities?.length > 0 && entities[0].type !== 'PointT') {  
        // }
    }

    const onCancel = () => {
        sideContext.setSelectedPanelValue(null);
    }

    // useEffect(() => {
    //     if (!checkInput()) {
    //         let p = new PointT(new Point(0, 0, 0));
    //         p.color.set(249, 250, 250);
    //         setEntities([p]);
    //         return;
    //     }
    //     let boxWidth = Number(width) || 0;
    //     let boxLength = Number(length) || 0;
    //     let boxHeight = Number(height) || 0;
    //     let basePoint = new Point(0, 0, 0);
    //     let dirX = new Vector3(1, 0, 0);
    //     let dirY = new Vector3(0, 1, 0);
    //     let dirZ = new Vector3(0, 0, 1);
    //     let box = new ElevatorCarT(basePoint, boxLength, boxWidth, boxHeight, dirX, dirY, dirZ);
    //     box.color.set(0, 102, 255);
    //     setEntities([box]);
    // }, [freshFlag])

    const correctionValue = (value: string) => {
        let res = nonNegativeIntegerVerification(value);
        if (!res || res === '') {
            res = '0';
        }
        // setFreshFlag(genUUID());
        return res;
    }

    return (
        <div className="elevator-car-container">
            <SideBarTool
                activeBtnList={[]}
            // onDeleteBtnClick={onDeleteClick}
            />
            <div className="side-title">轿厢</div>
            <div className="enter-row">
                <div className="tip">宽度：</div>
                <Input
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                    onBlur={(e) => setWidth(correctionValue(e.target.value))}
                    onPressEnter={(e) => setWidth(correctionValue((e.target as HTMLInputElement).value))}
                    suffix={`mm`}
                />
            </div>
            <div className="enter-row">
                <div className="tip">深度：</div>
                <Input
                    value={length}
                    onChange={(e) => setLength(e.target.value)}
                    onBlur={(e) => setLength(correctionValue(e.target.value))}
                    onPressEnter={(e) => setLength(correctionValue((e.target as HTMLInputElement).value))}
                    suffix={`mm`}
                />
            </div>
            <div className="enter-row">
                <div className="tip">高度：</div>
                <Input
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    onBlur={(e) => setHeight(e.target.value)}
                    onPressEnter={(e) => setHeight(correctionValue((e.target as HTMLInputElement).value))}
                    placeholder="层高"
                    suffix={`mm`}
                />
            </div>
            <div className="preview-area">
                {/* <LocalScene
                    entities={entities}
                    color="#f9fafa"
                    react_id={"preview-elevator-car"}
                    width={200}
                    height={70}
                    top={-0.01}
                    left={0.1}
                /> */}
                <BoxSvg />
            </div>
            <div className="btn-group">
                <div className="btn save" onClick={onSave}>确定</div>
                <div className="btn cancel" onClick={onCancel}>取消</div>
            </div>

            <div className="physical-area">
                <div className="divider"></div>
                <div className="physical-info">
                    <div className="title">物理信息</div>
                    <div className="msg-row">
                        <div className="tip">材质：</div>
                        <div className="detail">*</div>
                    </div>
                    <div className="msg-row">
                        <div className="tip">楼层：</div>
                        <div className="detail">*</div>
                    </div>
                    <div className="msg-row">
                        <div className="tip">建筑功能：</div>
                        <div className="detail">*</div>
                    </div>
                </div>
            </div>
        </div>
    )
}