
export const MENU_MODULE_LIST = [
    { value: 1, label: '文件', },
    { value: 2, label: '几何', },
    { value: 3, label: '建筑', },
    { value: 4, label: '强弱电', },
    // { value: 5, label: '给排水', },
    { value: 6, label: '消防', },
    // { value: 7, label: '暖通', },
]

export const MENU_MODULE = {
    FILE: 1,
    GEOMETRY: 2,
    BUILDING: 3,
    ELECTRICITY: 4,
    WATER: 5,
    FIRE: 6,
    HAVC: 7,
}

export function getMenuModule(value: number) {
    return MENU_MODULE_LIST.find(item => item.value === value) || null
}