export const ROAD_TYPE = {
    URBAN_ROAD: 1,   // 市政 
    BASE_ROAD: 2,    // 基地
}

export const ROAD_LANE_TYPE = {
    LEFT: 1,
    RIGHT: 2
}

export function getRoadTypeName(value: number) {
    if (value == ROAD_TYPE.URBAN_ROAD) {
        return "市政道路"
    } else if (value == ROAD_TYPE.BASE_ROAD) {
        return "基地道路"
    } else {
        return ""
    }
}

export function getRoadTypeShortName(value: number) {
    if (value == ROAD_TYPE.URBAN_ROAD) {
        return "市政"
    } else if (value == ROAD_TYPE.BASE_ROAD) {
        return "基地"
    } else {
        return ""
    }
}