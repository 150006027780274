import { updateRoadNetwork } from "@/api/building/road-network";
import { ReactComponent as SelectSvg } from "@/commons/icons/panel-icon/select-black.svg";
import { ReactComponent as SelectHoverSvg } from "@/commons/icons/panel-icon/select-white.svg";
import { RoadNetwork } from "@/commons/interface/building/road-network";
import { Input, message } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { graphicStore } from '@/commons/store/graphic-store'
import { BIM_BASE_CTRL_TYPE } from "tnbimbase";
import { BlockT, DTextT, ModelBase } from "pytha";
import { createPrimitive, findPrimitivesByUuid } from "@/api/geometry/primitive";
import { findFontGlyphs } from "@/api/geometry/font";
import { useParams } from "react-router-dom";
import { OrthographicCamera } from "three";

interface Iprops {
    data: RoadNetwork,
    selectList: string[]

    setSelectList: (n: string[]) => void
}

export default function RoadNetWorkListItem(props: Iprops) {

    const [btnState, setBtnState] = useState<boolean>(false)

    const [data, setData] = useState<RoadNetwork>(null)

    const [edit, setEdit] = useState<boolean>(false)

    const [roadName, setRoadName] = useState<string>("")

    const { projectId } = useParams()

    useEffect(() => {
        setData(props.data)
        setRoadName(props.data?.name || "")
    }, [props.data])

    const onClick = (e) => {
        if (e.ctrlKey) {
            if (props.selectList.find(item => item == data.uuid)) {
                let selectList = props.selectList.filter(item => item != data.uuid)
                props.setSelectList([...selectList])
            } else {
                props.setSelectList([...props.selectList, data.uuid])
            }
        } else {
            if (data?.roadUuid?.length > 0) {
                let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[data?.roadUuid]
                if (model) {
                    showEntity(model)
                }
            }
            props.setSelectList([data.uuid])
        }
    }

    const confirmChangeName = () => {
        updateRoadNetwork(data.uuid, { name: roadName }).then(res => {
            data.name = roadName
            setData({ ...data })
            if (data?.roadUuid?.length > 0) {
                updateRoadnetName(data?.roadUuid, roadName)
            }
        }).catch(e => {
            message.error("更新名字失败！")
        }).finally(() => {
            setEdit(false)
        })
    }

    const activeController = () => {
        if (graphicStore.extraContext.getCurrentViewEditor().cmdControl?.currentControl?.id == BIM_BASE_CTRL_TYPE.ADD_ROAD_NETWORK) {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('roadNetworkData').dispatch({ name: roadName, uuid: data.uuid, type: 1, projectUuid: projectId })
        } else {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("ADDROADNETWORK");
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('roadNetworkData').dispatch({ name: roadName, uuid: data.uuid, type: 1, projectUuid: projectId })
        }
    }

    const showEntity = (model: ModelBase) => {
        let box = model.getBoundingBox()

        box.min.project(graphicStore.extraContext.getCurrentViewEditor().camera);
        box.max.project(graphicStore.extraContext.getCurrentViewEditor().camera);
        let x1 = box.min.x
        let y1 = box.min.y
        let x2 = box.max.x
        let y2 = box.max.y

        let scale1 = Math.abs(y1 - y2) / 2;

        let scale2 = Math.abs(x1 - x2) / 2;
        // max
        let scale = Math.max(scale1, scale2) * 1.3;
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let camera = graphicStore.extraContext.getCurrentViewEditor().camera as OrthographicCamera;
        let target = graphicStore.context.ucsContext.unprojectCursorPoint(x, y, camera);

        let lastTarget = graphicStore.extraContext.getCurrentViewEditor().viewControl.target.clone();
        let offset = camera.position.clone().sub(lastTarget);
        const position = camera.position;
        graphicStore.extraContext.getCurrentViewEditor().viewControl.target.copy(target);
        position.copy(target).add(offset);
        graphicStore.extraContext.getCurrentViewEditor().camera.lookAt(target);
        // scale 框和当前边界的比例
        if (camera.type === 'OrthographicCamera') {
            graphicStore.context.renderContext.cameraEdge *= scale;
            let orthoCamera = camera as OrthographicCamera
            orthoCamera.left = orthoCamera.left * scale;
            orthoCamera.right = orthoCamera.right * scale;
            orthoCamera.top = orthoCamera.top * scale;
            orthoCamera.bottom = orthoCamera.bottom * scale;
            orthoCamera.updateProjectionMatrix();
            graphicStore.extraContext.listeners.signals.onZoomChanged.dispatch(1 / scale);
        }
    }

    const updateRoadnetName = (roadUuid: string, name: string) => {
        let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadUuid]

        let params = { ...graphicStore.extraContext.fontContext.findCurrentTtf(), text: name };
        findFontGlyphs(params).then(fontRes => {
            if (model) {
                let block = (model as BlockT)
                let blockEntities = block.getEntities();
                let dTextEntity = blockEntities.find(item => item.baseType == "DTextT") as DTextT
                dTextEntity.text = name
                dTextEntity.needUpdate = true
                dTextEntity.fontData = fontRes.data
                blockEntities = blockEntities.filter(item => item.baseType != "DTextT")
                blockEntities.push(dTextEntity)
                block.setEntities([...blockEntities]);
                block.updateViewGeometry()
                graphicStore.extraContext.getCurrentViewEditor().removeObjectByUuid(roadUuid)
                graphicStore.extraContext.getCurrentViewEditor().addObject(block)
                createPrimitive(block.toJson())
            } else {
                findPrimitivesByUuid(roadUuid).then(res => {
                    let json = res.data.properties
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.type))();
                    entity.fromJson(json);
                    let block = (entity as BlockT)
                    let blockEntities = block.getEntities();
                    let dTextEntity = blockEntities.find(item => item.baseType == "DTextT") as DTextT
                    dTextEntity.text = name
                    dTextEntity.fontData = fontRes.data
                    blockEntities = blockEntities.filter(item => item.baseType != "DTextT")
                    blockEntities.push(dTextEntity)
                    block.setEntities(blockEntities);
                    createPrimitive(block.toJson())
                })
            }
        }).finally(() => {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
        })



    }

    return (
        <div
            className={classNames({
                "list-item": true,
                "list-item-select": props.selectList.find(item => item == data?.uuid)
            })}
            onClick={(e) => { onClick(e) }}
        >
            <>
                {(data?.name?.length > 0) && !edit ?
                    (
                        <div
                            onDoubleClick={() => {
                                setEdit(true)
                            }}
                            className={classNames({
                                "name": true,
                                "name-active": data?.roadUuid?.length > 0
                            })}>
                            {data.name}
                        </div>
                    ) : (
                        <>
                            <Input
                                onChange={(e) => { setRoadName(e.target.value) }}
                                value={roadName}
                                onPressEnter={(e) => { confirmChangeName() }}
                                placeholder="单击输入路名"
                                className="input"
                            />
                        </>
                    )}
            </>
            <div className="btn"
                onMouseEnter={() => { setBtnState(true) }}
                onMouseLeave={() => { setBtnState(false) }}
                onClick={() => { activeController() }}
            >
                {btnState ? <SelectHoverSvg className="svg" /> : <SelectSvg className="svg" />}

            </div>
        </div>
    )
}