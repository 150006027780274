import { Button, Checkbox, Input, message, Select, Space, Tabs } from 'antd';
import { useEffect, useState } from 'react'
import './CaptureSetting.less';
import Icon from '@ant-design/icons'
import { graphicStore } from '@/commons/store/graphic-store';
import { CommandGroup, ToggleCaptureSwitchCommand, TogglePolarSwitchCommand, UpdateCaptureModeCommand, UpdatePolarAngleCommand } from "pytha";
import { commonlyUsedAngle } from '.';


const { TabPane } = Tabs;
const { Option } = Select;

interface IProps {
    selectedTabPane?: string,
    onCancel?: () => void,
}


export default function CaptureSetting(props: IProps) {
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(true);
    const [capture, setCapture] = useState(graphicStore.extraContext.editStatusContext.capture);
    const [captureModes, setCaptureModes] = useState([]);
    const [autoSnap, setAutoSnap] = useState(graphicStore.extraContext.editStatusContext.autoSnap);
    const [polarAng, setPolarAng] = useState(graphicStore.extraContext.editStatusContext.polarAng);

    useEffect(() => {
        let modes = [];
        graphicStore.extraContext.captureContext.captureModes.forEach((item) => {
            modes.push({ ...item });
        });
        let num = graphicStore.extraContext.captureContext.captureModes.filter((item) => item.active).length;
        setIndeterminate(num > 0 && num < graphicStore.extraContext.captureContext.captureModes.length);
        setCheckAll(num === graphicStore.extraContext.captureContext.captureModes.length);
        setCaptureModes(modes);
    }, [graphicStore.extraContext.captureContext.captureModes]);

    const onCheckAllChange = (e) => {
        let modes = captureModes.map((mode) => {
            mode.active = e.target.checked
            return mode;
        });

        setCheckAll(e.target.checked);
        setIndeterminate(false);
        setCaptureModes(modes);
    };

    // const togglePolar = (e) => {
    //     setAutoSnap(e.target.checked);
    // };

    const updatePolarAngle = (value) => {
        if (typeof value === "string") {
            value = value.trim();
        }
        setPolarAng(value);
    };

    const onCaptureMenuItemClick = (number, active) => {
        let modes = captureModes.map((mode) => {
            if (mode.number === number) {
                mode.active = active;
            }
            return mode;
        });

        let num = modes.filter((item) => item.active).length;
        setIndeterminate(num > 0 && num < graphicStore.extraContext.captureContext.captureModes.length);
        setCheckAll(num === graphicStore.extraContext.captureContext.captureModes.length);
        setCaptureModes(modes);
    };

    const onConfirm = () => {
        // 空或空格或换行或非数字
        if (polarAng.toString().match(/^\s*$/) || isNaN(polarAng)) {
            message.warning('输入无效，请重试！');
            return;
        }
        let switchCmd1 = new ToggleCaptureSwitchCommand(capture);
        let modeCmd = new UpdateCaptureModeCommand(captureModes);
        let switchCmd2 = new TogglePolarSwitchCommand(autoSnap);
        let angleCmd = new UpdatePolarAngleCommand(polarAng);
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new CommandGroup([switchCmd1, modeCmd, switchCmd2, angleCmd]));
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <div className="capture-setting-container">
            <Tabs defaultActiveKey={props.selectedTabPane} type="card" size="small">
                <TabPane tab="极轴追踪" key="polar">
                    <div className="manage-check">
                        <Checkbox onChange={() => {
                            setAutoSnap(!autoSnap)
                        }} checked={autoSnap} >
                            启用极轴追踪（F10）
                        </Checkbox>
                    </div>
                    <div style={{ margin: '10px' }}>增量角(I):</div>
                    <div className="angle-select">
                        <Select style={{ width: '100%' }} value={polarAng} onChange={updatePolarAngle} size="small">
                            {commonlyUsedAngle.map(a => (
                                <Option value={a} key={a}>{a}</Option>
                            ))}
                            {!commonlyUsedAngle.some(i => i === graphicStore.extraContext.editStatusContext.polarAng) &&
                                <Option value={polarAng} key={polarAng}>{polarAng}</Option>
                            }
                        </Select>
                        <Input bordered={false} value={polarAng} onChange={(e) => updatePolarAngle(e.target.value)} style={{ width: 150, height: 25, marginLeft: -183 }} />
                    </div>
                </TabPane>
                <TabPane tab="对象捕捉" key="capture">
                    <div className="manage-check">
                        <Checkbox onChange={() => {
                            setCapture(!capture)
                        }} checked={capture} >
                            启用对象捕捉（F3）
                        </Checkbox>
                        <span className="fill-remaining-space"></span>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            全选
                        </Checkbox>
                    </div>
                    <div className="capture-item-group">
                        {captureModes.map(item => (
                            <div className="capture-item" key={item.number}>
                                <Icon style={{ margin: '5px', fontSize: '16px' }} component={item.icon} />
                                <Checkbox checked={item.active} onChange={() => onCaptureMenuItemClick(item.number, !item.active)}>
                                    <span>{item.name}</span>
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </TabPane>
            </Tabs>
            <div className="manage-foot">
                <span className="fill-remaining-space"></span>
                <Space size="small">
                    <Button size="small" onClick={onConfirm}>确定</Button>
                    <Button size="small" onClick={props.onCancel}>取消</Button>
                </Space>

            </div>
        </div>
    )

}

