import { SettingOutlined } from '@ant-design/icons';


export default function HavcMenu() {

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>空调内机</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>空调外机</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>进风管</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>回风管</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        空调系统
                    </div>
                </div>
            </div>

        </div>
    )
}