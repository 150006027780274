import classNames from 'classnames'
import './index.less'

interface RadioOption {
    label: string,
    value: string | boolean,
}

interface Iprops {
    dataList: RadioOption[],
    selected: string | boolean,
    onChange: (value) => void
}

export default function RadioGroup(props: Iprops) {
    return (
        <div className="tn-radio-group">
            {props.dataList.map((item, index) => (
                <div key={index} className={classNames({
                    "radio-item": true,
                    "selecetd": props.selected == item.value
                })} onClick={() => { props.onChange(item.value) }}>
                    <div className="text">{item.label}</div>
                </div>
            ))}
        </div>
    )
}