import { Modal } from "antd";
import "./index.less"
import { ReactComponent as CloseSvg } from '@/commons/icons/panel-icon/close.svg';
import { useState } from "react";

interface IProp {
    isOpen: boolean
    setIsOpen: (e) => void
    data?: { value: string, uuid: string }[]
    title?: string,
    onConfirmClick: (e) => void
}

export default function SelectModal(props: IProp) {

    const [selectUuid, setSelectUuid] = useState<string>("")

    return (
        <Modal
            mask={false}
            footer={null}
            closeIcon={false}
            open={props.isOpen}
            width={424}
            onCancel={() => { props.setIsOpen(false) }}
            wrapClassName="tn-select-modal"
        >
            <div className="body" onClick={(e) => { e.stopPropagation() }}>
                <div className="title">
                    {props?.title || "标题"}
                    <CloseSvg className="icon" onClick={() => { props.setIsOpen(false) }} />
                </div>
                <div className="list">
                    {props?.data?.map((item, index) => (
                        <div className="list-item" key={index} onClick={(e) => { e.stopPropagation(); setSelectUuid(item.uuid) }}>
                            <div className="value">
                                {item.value}
                            </div>
                            <div className="icon">
                                {selectUuid == item.uuid && <div className="select" />}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-panel">
                    <div className="btn save" onClick={() => { props.onConfirmClick(selectUuid); props.setIsOpen(false) }}>确定</div>
                    <div className="btn cancel" onClick={() => { props.setIsOpen(false) }}>取消</div>
                </div>
            </div>

        </Modal>
    )
}