import './index.less';
import { CaretDownFilled, CaretRightFilled, CaretUpFilled, CaretLeftFilled } from '@ant-design/icons';
import { ReactComponent as PanSvg } from '@/commons/icons/view-tool/pan.svg'
import { ReactComponent as RotateSvg } from '@/commons/icons/view-tool/rotate.svg'
import { ReactComponent as ZoomInSvg } from '@/commons/icons/view-tool/zoom-in.svg'
import { ReactComponent as ZoomOutSvg } from '@/commons/icons/view-tool/zoom-out.svg'
import { ReactComponent as ZoomAllSvg } from '@/commons/icons/view-tool/zoom-all.svg'
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { graphicStore } from '@/commons/store/graphic-store';
import { Camera, Clock, OrthographicCamera, PerspectiveCamera, WebGLRenderer } from 'three';
import { DefaultMouseControl, ViewCube, ViewEditor } from "pytha";
import classNames from 'classnames';
import { EXTRA_SUB_CONTROL } from '@/commons/enums/extra-sub-controls';
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';
import { BIM_SINGAL, BIM_BASE_EXTRA_SINGAL_COMMON, BIM_BASE_EXTRA_SUB_CONTROL_COMMON, BUILDING_SUB_CONTROL, BUILDING_SINGAL } from 'tnbimbase';

const _clock = new Clock();


export enum CAMERA_TYPE {
    Orthographic,
    Perspective
}

interface Iprops {
    sceneUpdate: string,
    viewportId: string,
    viewHelperId: string,
    cameraType?: number,  //默认为正交相机
}

export default function Viewport(props: Iprops) {
    const { projectId } = useParams();
    const viewportId = props.viewportId;
    const viewHelperId = props.viewHelperId;
    const viewCube = useRef<ViewCube>();
    const stats = useRef<Stats>();
    const [activeTool, setActiveTool] = useState<number>(0)

    // const onSelect= (entities: ModelBase) => {
    //     console.log("entities", entities);
    // }

    useEffect(() => {
        if (projectId == null || graphicStore.extraContext == null || !graphicStore.extraContext.sceneContext.scene) return () => { };
        let editor = new ViewEditor(graphicStore.extraContext.listeners, projectId, viewportId);
        graphicStore.extraContext.addViewEditor(viewportId, editor);
        // editor.listeners.signals.onSelectChanged.add(onSelect);
        

        const renderer = createRenderer();
        editor.renderer = renderer;

        const camera = createCamera(graphicStore.context.renderContext.cameraEdge, props.cameraType ? props.cameraType : CAMERA_TYPE.Orthographic);
        editor.camera = camera;

        const viewControl = initControls(editor, renderer, camera);

        //注册二级控制器
        Object.keys(EXTRA_SUB_CONTROL).forEach(key => {
            graphicStore.extraContext.cmdContext.registerCmd({
                name: EXTRA_SUB_CONTROL[key].name,
                cmd: EXTRA_SUB_CONTROL[key].cmd,
                needInit: EXTRA_SUB_CONTROL[key]?.needInit,
            });
            graphicStore.extraContext.cmdContext.registerSubCtrl(EXTRA_SUB_CONTROL[key].cmd, EXTRA_SUB_CONTROL[key].clazz);
        })
        Object.keys(BUILDING_SUB_CONTROL).forEach(key => {
            graphicStore.extraContext.cmdContext.registerCmd({
                name: BUILDING_SUB_CONTROL[key].name,
                cmd: BUILDING_SUB_CONTROL[key].cmd,
                needInit: BUILDING_SUB_CONTROL[key]?.needInit,
            });
            graphicStore.extraContext.cmdContext.registerSubCtrl(BUILDING_SUB_CONTROL[key].cmd, BUILDING_SUB_CONTROL[key].clazz);
        })
        Object.keys(BIM_BASE_EXTRA_SUB_CONTROL_COMMON).forEach(key => {
            graphicStore.extraContext.cmdContext.registerCmd({
                name: BIM_BASE_EXTRA_SUB_CONTROL_COMMON[key].name,
                cmd: BIM_BASE_EXTRA_SUB_CONTROL_COMMON[key].cmd,
                needInit: BIM_BASE_EXTRA_SUB_CONTROL_COMMON[key]?.needInit,
            });
            graphicStore.extraContext.cmdContext.registerSubCtrl(BIM_BASE_EXTRA_SUB_CONTROL_COMMON[key].cmd, BIM_BASE_EXTRA_SUB_CONTROL_COMMON[key].clazz);
        })


        //注册额外信号
        Object.keys(EXTRA_SINGAL).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL[key]);
        })
        //注册专业几何库中的额外信号
        Object.keys(BIM_BASE_EXTRA_SINGAL_COMMON).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(BIM_BASE_EXTRA_SINGAL_COMMON[key]);
        })
        Object.keys(BIM_SINGAL).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(BIM_SINGAL[key]);
        })
        Object.keys(BUILDING_SINGAL).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(BUILDING_SINGAL[key]);
        })

        viewCube.current = createViewCube(camera, viewControl);
        editor.viewCube = viewCube.current;

        graphicStore.extraContext.setCurrentViewportId(viewportId);

        render();

        return (() => {
            if (!!editor) {
                if (!!viewCube?.current) {
                    viewCube?.current.dispose();
                }
                // editor.listeners.signals.onSelectChanged.remove(onSelect);
                editor.renderer.domElement.parentElement.removeChild(editor.renderer.domElement);
                editor.renderer.setAnimationLoop(null);
                editor.dispose();
            }
            graphicStore.extraContext?.deleteViewEditor(viewportId);
        })

    }, [projectId, graphicStore.extraContext, props.sceneUpdate])

    useEffect(() => {
        const editor = graphicStore.extraContext.getViewEditor(viewportId);
        if (!editor) return;
        switch (props.cameraType) {
            case CAMERA_TYPE.Orthographic:
                editor.changeCameraType("OrthographicCamera");
                viewCube.current.camera = editor.camera;
                break;
            case CAMERA_TYPE.Perspective:
                editor.changeCameraType("PerspectiveCamera");
                viewCube.current.camera = editor.camera;
                break;
        }
    }, [props.cameraType])

    useEffect(() => {
        if (!graphicStore.extraContext) return () => { };
        graphicStore.extraContext.listeners.signals.windowResize.add(onWindowResize);
        graphicStore.extraContext.listeners.signals.needRender.add(render);
        graphicStore.extraContext.listeners.signals.onViewCubeWorking.add(onViewCubeWorking);
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.add(reSetToolBar);
        return (() => {
            if (!graphicStore.extraContext) return;
            graphicStore.extraContext.listeners.signals.windowResize.remove(onWindowResize);
            graphicStore.extraContext.listeners.signals.needRender.remove(render);
            graphicStore.extraContext.listeners.signals.onViewCubeWorking.remove(onViewCubeWorking);
            graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.remove(reSetToolBar);
        })
    }, [projectId, graphicStore.extraContext, viewCube])

    useEffect(() => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        const renderer = viewEditor?.renderer;
        if (!!renderer) {
            renderer.setAnimationLoop(animate);
        }
    }, [viewCube])

    const onWindowResize = (offset: number = 0) => {
        const root = document.getElementById(viewportId);
        if (!root) return;
        const aspect = root.offsetWidth / root.offsetHeight;
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (viewEditor == null) return;
        if (!viewEditor.camera) return;
        if (viewEditor.camera.type === 'OrthographicCamera') {
            let camera = viewEditor.camera as OrthographicCamera;


            camera.left = camera.bottom * aspect;
            camera.right = camera.top * aspect;
            camera.updateProjectionMatrix();

        } else if (viewEditor.camera.type === 'PerspectiveCamera') {
            let camera = viewEditor.camera as PerspectiveCamera;
            camera.aspect = aspect;
            camera.updateProjectionMatrix();
        }
        let renderer = viewEditor.renderer;
        renderer.setSize(root.offsetWidth - offset, root.offsetHeight);
        renderer.setViewport(0, 0, root.offsetWidth - offset, root.offsetHeight)
        viewCube?.current?.updateSize();
        render();
    }

    const onViewCubeWorking = (value) => {
        if (!value) {
            const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
            const domElement = viewEditor.renderer?.domElement;
            domElement?.focus();
        }
    }

    const createRenderer = () => {
        const root = document.getElementById(viewportId);
        if (!root) return null;
        root.oncontextmenu = (e) => {
            e.preventDefault();
        }
        const renderer = new WebGLRenderer({
            antialias: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(root.offsetWidth, root.offsetHeight);
        renderer.setClearColor('#f5f5f5', 1.0);
        renderer.autoClear = false;
        renderer.setAnimationLoop(animate);
        root.appendChild(renderer.domElement);
        renderer.domElement.setAttribute('tabindex', '1');
        renderer.domElement.style.outline = '0px';
        return renderer;
    }

    const reSetToolBar = () => {
        setActiveTool(0)
    }

    const animate = () => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (viewEditor == null) return;

        let delta = _clock.getDelta();
        let needsUpdate = false;
        if (viewCube?.current && viewCube.current?.animating) {
            viewCube?.current.update(delta);
            needsUpdate = true;
        }
        if (needsUpdate) {
            render();
        }
        if (stats?.current) {
            stats.current.update();
        }
    }

    const createCamera = (s = 30, cameraType = CAMERA_TYPE.Orthographic) => {
        const root = document.getElementById(viewportId);
        if (!root) return null;
        const aspect = root.offsetWidth / root.offsetHeight;
        if (cameraType === CAMERA_TYPE.Orthographic) {
            const camera = new OrthographicCamera(-s * aspect, s * aspect, s, -s, 0.1, 1e6);
            camera.position.set(0, 0, 1e6 / 2)
            camera.lookAt(0, 0, 0)
            return camera;
        } else if (cameraType === CAMERA_TYPE.Perspective) {
            const fov = 45;
            const camera = new PerspectiveCamera(fov, aspect, 0.1, 1e6);
            camera.position.set(0, 0, 500)
            camera.lookAt(0, 0, 0)
            return camera;
        }
        return null;
    }

    const initControls = (editor: ViewEditor, renderer: WebGLRenderer, camera: Camera) => {
        editor.camera = camera;
        editor.renderer = renderer;
        let viewControl = editor.initControl();
        return viewControl;
    }

    const createViewCube = (camera: Camera, viewControl: DefaultMouseControl) => {
        let viewCube = new ViewCube(camera, viewHelperId, graphicStore.extraContext.listeners);
        viewCube.controls = viewControl;
        viewCube.listeners = graphicStore.extraContext.listeners;
        return viewCube;
    }

    const render = (arg: string = null) => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (!viewEditor) {
            return;
        }
        let renderer = viewEditor.renderer;
        let camera = viewEditor.camera;
        if (!renderer) return;
        const root = document.getElementById(viewportId);
        if (!root) return;
        renderer.setViewport(0, 0, root.offsetWidth, root.offsetHeight)
        renderer.clear();
        if (arg === 'redraw') {
            viewEditor.renderControl.render();
        } else if (arg === 'position') {
            viewEditor.renderControl.updatePositions();
        } else if (arg === 'color') {
            viewEditor.renderControl.updateColors();
        }
        if (!graphicStore.extraContext.sceneContext.scene) return;
        renderer.render(graphicStore.extraContext.sceneContext.scene, camera);
        if (!!viewCube?.current) {
            viewCube?.current.render(renderer);
        }
    }


    return (
        <div id={viewportId} className="viewport"
            onClick={() => {
                if (viewportId !== graphicStore.extraContext.getCurrentViewportId()) {
                    graphicStore.extraContext.setCurrentViewportId(viewportId);
                }
            }}
            onMouseEnter={() => {
                if (viewportId == graphicStore.extraContext.getCurrentViewportId()) {
                    graphicStore.extraContext.getCurrentViewEditor()?.renderer.domElement.focus();
                }
            }}
        >
            <div id={viewHelperId} className="view-helper" >
                <div id={viewHelperId + '_top'} className="top arrow">
                    <CaretDownFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_left'} className="left arrow">
                    <CaretRightFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_down'} className="down arrow">
                    <CaretUpFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_right'} className="right arrow">
                    <CaretLeftFilled className="arrow-icon" />
                </div>
            </div>
            <div className="toolbar">
                <div className={classNames({
                    'tool-btn': true,
                    'is-active': activeTool == 1
                })} onClick={() => {
                    setActiveTool(1)
                    // systemContext.setNoteVisible(false);
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('P');
                }}>
                    <PanSvg />
                </div>
                <div className={classNames({
                    'tool-btn': true,
                    'is-active': activeTool == 2
                })} onClick={() => {
                    setActiveTool(2)
                    // systemContext.setNoteVisible(false);
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('3DO');
                }}>
                    <RotateSvg />
                </div>
                <div className='tool-btn' onClick={() => {
                    // systemContext.setNoteVisible(false);
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('ZI');
                }}>
                    <ZoomInSvg />
                </div>
                <div className='tool-btn' onClick={() => {
                    // systemContext.setNoteVisible(false);
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('ZO');
                }}>
                    <ZoomOutSvg />
                </div>
                <div className='tool-btn' onClick={() => {
                    // systemContext.setNoteVisible(false);
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('ZA');
                }}>
                    <ZoomAllSvg />
                </div>
            </div>
        </div>
    )
}