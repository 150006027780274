import { Component } from 'react'
import { CustomPicker } from 'react-color'
import './ColorPalette.less'
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common'
import { HexStringToRGB, HSLToRGB, HSVToRGB, RGBToHexString, RGBToHSL, RGBToHSV } from '@/commons/utils/color-utils'
import { Button, Space, Tooltip } from 'antd'

function HuePointer() {
    return (
        <div className="hue-pointer"></div>
    )
}

interface IProps {
    onComplete?: (color) => void;
    onCancel?: () => void;
    defaultColor?: string;
}
class ColorPalette extends Component<IProps> {

    state = {
        currentColor: '#2c1698',
        hex: "#2c1698",
        hsl: { h: 250.18867924528305, s: 0.7444395987788921, l: 0.34108375, a: 1 },
        hsv: { h: 250.18867924528305, s: 0.8534999999999999, v: 0.595, a: 1 },
        rgb: { r: 44, g: 22, b: 152, a: 1 },
        oldHue: 250.18867924528305,
    }
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        let hex = this.props.defaultColor || '#2C1698';
        let rgb = HexStringToRGB(hex);
        let hsl = RGBToHSL(rgb);
        let hsv = RGBToHSV(rgb);
        this.setState({
            hex: hex,
            hsl: hsl,
            hsv: hsv,
            rgb: rgb,
            currentColor: hex,
        })
    }
    handleHueChange = (color) => {
        let hsl = color;
        let rgb = HSLToRGB(color);
        let hex = RGBToHexString(rgb)
        let hsv = RGBToHSV(color);
        this.setState({
            hsv: hsv,
            hsl: hsl,
            rgb: rgb,
            hex: hex,
            oldHue: color.h,
            currentColor: hex,
        })
    }

    handleSaturationChange = (color) => {
        // if (this.props.onChange) {
        //     this.props.onChange(color);
        // }
        let hsv = color;
        let rgb = HSVToRGB(hsv);
        let hex = RGBToHexString(rgb)
        let hsl = RGBToHSL(rgb);
        if ((Math.floor(hsv.h) !== hsl.h) && hsl.h === 0) {
            hsl.h = hsv.h
        }
        this.setState({
            hsv: hsv,
            hsl: hsl,
            rgb: rgb,
            hex: hex,
            oldHue: color.h,
            currentColor: hex,
        })
    }

    handleHexChange = (e) => {
        let hex = e.hex || e;
        if (hex.length !== 4 && hex.length !== 7) return;
        let rgb = HexStringToRGB(hex);
        let hsl = RGBToHSL(rgb);
        let hsv = RGBToHSV(rgb);
        this.setState({
            hsv: hsv,
            hsl: hsl,
            rgb: rgb,
            hex: hex,
            oldHue: hsl.h,
            currentColor: hex,
        })
    }

    handleRGBChange = (color) => {

        let rgb = { ...this.state.rgb, ...color };
        let hsl = RGBToHSL(rgb);
        let hsv = RGBToHSV(rgb);
        let hex = RGBToHexString(rgb)
        this.setState({
            hsv: hsv,
            hsl: hsl,
            rgb: rgb,
            hex: hex,
            oldHue: hsl.h,
            currentColor: hex,
        })
    }

    render() {
        const styles = {
            label: {
                display: 'block',
                textAlign: 'center',
                fontSize: '11px',
                color: '#222222',
                paddingTop: '3px',
                paddingBottom: '4px',
                textTransform: 'capitalize',
            },
            input: {
                border: 'none',
                fontSize: '11px',
                padding: '4px 10% 3px',
                boxShadow: 'rgb(204 204 204) 0px 0px 0px 1px inset',
                width: '100%',
            },
            wrap: {

            }
        }
        return (
            <div className="color-palette-container">
                <div className="left-panel">
                    <div className="palette-saturation">
                        <Saturation {...this.props} hsl={this.state.hsl} hsv={this.state.hsv} onChange={this.handleSaturationChange} />
                    </div>
                    <div className="palette-hue">
                        <Hue {...this.props} hsl={this.state.hsl} onChange={this.handleHueChange} pointer={HuePointer} />
                    </div>

                </div>
                <div className="right-panel">
                    <div className="color-wrapper">
                        {defaultColors.map((color, idx) => (
                            <Tooltip title={color} key={idx} color="#fff" overlayClassName="color-tooltip">
                                <div className="color-item" onClick={() => this.handleHexChange(color)}>
                                    <div className="color-box" style={{ backgroundColor: color }}></div>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                    <div className="input-wrapper">
                        <div className="input-item-hex">
                            <EditableInput
                                label="hex"
                                value={this.state.hex}
                                onChange={this.handleHexChange}
                                //@ts-ignore
                                style={styles} />
                        </div>
                        <div className="fill-remaining-space"></div>
                        <div className="input-item-rgb">
                            <EditableInput
                                label="r"
                                value={this.state.rgb?.r}
                                onChange={this.handleRGBChange}
                                //@ts-ignore
                                style={styles} />
                        </div>
                        <div className="input-item-rgb">
                            <EditableInput
                                label="g"
                                value={this.state.rgb?.g}
                                onChange={this.handleRGBChange}
                                //@ts-ignore
                                style={styles} />
                        </div>
                        <div className="input-item-rgb">
                            <EditableInput
                                label="b"
                                value={this.state.rgb?.b}
                                onChange={this.handleRGBChange}
                                //@ts-ignore
                                style={styles} />
                        </div>
                    </div>
                    <span className="fill-remaining-space"></span>
                    <div className="color-footer">
                        <div className="current-color" style={{ backgroundColor: this.state.currentColor }}></div>
                        <span className="fill-remaining-space"></span>
                        <Space style={{ marginLeft: 'auto' }}>
                            <Button size="small" type="primary" onClick={() => {
                                if (this.props.onComplete) {
                                    this.props.onComplete(this.state.currentColor)
                                }
                            }}
                            >确定</Button>
                            <Button size="small" onClick={() => {
                                if (this.props.onCancel) {
                                    this.props.onCancel();
                                }
                            }}>取消</Button>
                        </Space>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomPicker(ColorPalette);

const defaultColors = [
    '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
    '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
    '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E'
];
// const ColorIndex = [
//     {color: '#260000', idx: '18'},
//     {color: '#260900', idx: '28'},
//     {color: '#261300', idx: '38'},
//     {color: '#261c00', idx: '48'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
//     {color: '#260900', idx: '28'},
// ]