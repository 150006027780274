import { SideContext } from '@/App';
import { SideStatus } from '@/commons/context/side-store';
import { graphicStore } from '@/commons/store/graphic-store';
import { useContext } from 'react';
import { SIDE_TYPE } from '@/commons/enums/side-type';
import { ReactComponent as XungengdianSvg } from '@/commons/icons/new/xungengdain.svg';
import { ReactComponent as ShexiangtouSvg } from '@/commons/icons/new/shexiangtou.svg';

export default function ElectricityMenu() {
    const sideContext = useContext<SideStatus>(SideContext);

    const onClick = (route: string) => {
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
        sideContext.setSelectedPanelValue(route);
    }


    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.patrol_point) }}>
                                <div className='icon'><XungengdianSvg /></div>
                                <div className='name'>巡更点</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.monitor) }}>
                                <div className='icon'><ShexiangtouSvg /></div>
                                <div className='name'>摄像头</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        弱电
                    </div>
                </div>
            </div>

        </div>
    )
}