import { Elevator } from "@/commons/interface/building/elevator"
import { StdResponse, bimAxios } from "@/config/axios"


export function getElevators(projectUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/elevators`, {
        params: {
            ...params,
        }
    })
}

export function addElevator(projectUuid: string, data: Elevator, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/elevators`, data, {
        params: {
            ...params,
        }
    })
}


export function updateElevator(uuid: string, data: Elevator, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.put(`/elevators/${uuid}`, data, {
        params: {
            ...params,
        }
    })
}

export function deleteElevator(projectUuid: string, elevatorUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/elevators/${elevatorUuid}`, {
        params: {
            ...params,
        }
    })
}
