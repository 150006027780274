import "./index.less"
import { useState } from 'react';
import { Select, Tooltip } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;


interface Iprops {
    data: { [key: string]: { name: string, value: any, url: string }[] }
    selectedValue: any
    setSelectedValue: (value: any) => void
    style?: any
}


export function CustomSelect(props: Iprops) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (value) => {
        props.setSelectedValue(value);
    };

    return (
        <Select
            size='small'
            value={props.selectedValue}
            style={props.style ? { width: 104, height: 20, ...props.style } : { width: 104, height: 20 }}
            onChange={handleChange}
            placeholder="请选择"
            suffixIcon={isOpen ? <UpOutlined onClick={toggleOpen} /> : <DownOutlined onClick={toggleOpen} />}
            popupClassName={'picture-popup-container'}
            optionLabelProp='label'
            open={isOpen}
            onDropdownVisibleChange={(open) => setIsOpen(open)}
        >
            {
                Object.keys(props.data || []).map(group => (
                    <OptGroup className={'picture-popup-item'} key={group} label={group}>
                        {(props.data || [])[group].map(item => (
                            <Option key={item.value} value={item.value} label={item.name}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={item.url} alt={item.name} style={{ width: 20, height: 20, marginRight: 20 }} />
                                    <Tooltip color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false} title={item.name}>
                                        <span>{item.name}</span>
                                    </Tooltip>
                                </div>
                            </Option>
                        ))}
                    </OptGroup>))
            }

        </Select>
    );
};

