import { SideContext } from '@/App';
import { SideStatus } from '@/commons/context/side-store';
import { graphicStore } from '@/commons/store/graphic-store';
import { SIDE_TYPE } from '@/commons/enums/side-type';
import { useContext } from 'react';

import { ReactComponent as LudengSvg } from '@/commons/icons/new/luwang.svg';
import { ReactComponent as DaoluSvg } from '@/commons/icons/new/daolu.svg';
import { ReactComponent as LuwangSvg } from '@/commons/icons/new/ludeng.svg';
import { ReactComponent as WeiqiangSvg } from '@/commons/icons/new/weiqiang.svg';
import { ReactComponent as ChurukouSvg } from '@/commons/icons/new/churukou.svg';
import { ReactComponent as JianzhulunkuoSvg } from '@/commons/icons/new/jianzhulunkuo.svg';
import { ReactComponent as DimianSvg } from '@/commons/icons/new/dimian.svg';
import { ReactComponent as JidongcheweiSvg } from '@/commons/icons/new/jidongchechewei.svg';
import { ReactComponent as FeijidongcheweiSvg } from '@/commons/icons/new/feijidongchechewei.svg';
import { ReactComponent as QiaoguanmuSvg } from '@/commons/icons/new/qiaoguanmu.svg';

import { ReactComponent as ZuobiaoxiSvg } from '@/commons/icons/new/zuobiaoxi.svg';
import { ReactComponent as ZhouwangSvg } from '@/commons/icons/new/zhouwang.svg';
import { ReactComponent as QiangSvg } from '@/commons/icons/new/qiang.svg';
import { ReactComponent as LoubanSvg } from '@/commons/icons/new/louban.svg';
import { ReactComponent as DiaodingSvg } from '@/commons/icons/new/diaoding.svg';
import { ReactComponent as LiangSvg } from '@/commons/icons/new/liang.svg';
import { ReactComponent as ZhuSvg } from '@/commons/icons/new/zhu.svg';
import { ReactComponent as MenSvg } from '@/commons/icons/new/men.svg';
import { ReactComponent as ChuangSvg } from '@/commons/icons/new/chuang.svg';
import { ReactComponent as DongkouSvg } from '@/commons/icons/new/dongkou.svg';
import { ReactComponent as JiaoxiangSvg } from '@/commons/icons/new/jiaoxiang.svg';
import { ReactComponent as LoutiSvg } from '@/commons/icons/new/louti.svg';
import { ReactComponent as JiaochakouSvg } from '@/commons/icons/new/jiaochakou.svg';

import { ReactComponent as LoucengSvg } from '@/commons/icons/new/louceng.svg';
import { ReactComponent as FangjianSvg } from '@/commons/icons/new/fangjian.svg';
import { ReactComponent as DiantiSvg } from '@/commons/icons/new/dianti.svg';
import { ReactComponent as XiaokongshiSvg } from '@/commons/icons/new/xiaokongshi.svg';
import { ReactComponent as BengfangSvg } from '@/commons/icons/new/bengfang.svg';

import { ReactComponent as FenggeSvg } from '@/commons/icons/new/fengge.svg';
import { ReactComponent as CaizhiSvg } from '@/commons/icons/new/caizhi.svg';
import { ReactComponent as YingyingSvg } from '@/commons/icons/new/yingying.svg';

export default function BuildingMenu() {
    const sideContext = useContext<SideStatus>(SideContext);

    const onClick = (route: string) => {
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
        sideContext.setSelectedPanelValue(route);
    }

    const activeSubControl = (cmd: string) => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch(cmd);
    }



    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.road_network) }}>
                                <div className='icon'><LuwangSvg /></div>
                                <div className='name'>路网</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.road) }}>
                                <div className='icon'><DaoluSvg /></div>
                                <div className='name'>道路</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { activeSubControl("LANEINTERSECTION") }}>
                                <div className='icon'><JiaochakouSvg /></div>
                                <div className='name'>交叉路口</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.street_lamp) }}>
                                <div className='icon'><LudengSvg /></div>
                                <div className='name'>路灯</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.fence) }}>
                                <div className='icon'><WeiqiangSvg /></div>
                                <div className='name'>围墙</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><ChurukouSvg /></div>
                                <div className='name'>出入口</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><JianzhulunkuoSvg /></div>
                                <div className='name'>建筑轮廓</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.ground) }}>
                                <div className='icon'><DimianSvg /></div>
                                <div className='name'>地面</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.parking_space) }}>
                                <div className='icon'><JidongcheweiSvg /></div>
                                <div className='name'>机动车位</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.no_motor_parking_space) }}>
                                <div className='icon'><FeijidongcheweiSvg /></div>
                                <div className='name'>非机动车位</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.tree) }}>
                                <div className='icon'><QiaoguanmuSvg /></div>
                                <div className='name'>乔灌木</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        场地
                    </div>
                </div>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><ZuobiaoxiSvg /></div>
                                <div className='name'>坐标系</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.axis_net) }}>
                                <div className='icon'><ZhouwangSvg /></div>
                                <div className='name'>轴网</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.wall) }}>
                                <div className='icon'><QiangSvg /></div>
                                <div className='name'>墙</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.floor) }}>
                                <div className='icon'><LoubanSvg /></div>
                                <div className='name'>楼板</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.ceiling) }}>
                                <div className='icon'><DiaodingSvg /></div>
                                <div className='name'>吊顶</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><LiangSvg /></div>
                                <div className='name'>梁</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.column) }}>
                                <div className='icon'><ZhuSvg /></div>
                                <div className='name'>柱</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.door) }}>
                                <div className='icon'><MenSvg /></div>
                                <div className='name'>门</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.window) }}>
                                <div className='icon'><ChuangSvg /></div>
                                <div className='name'>窗</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.hole) }}>
                                <div className='icon'><DongkouSvg /></div>
                                <div className='name'>洞口</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.elevator_car) }}>
                                <div className='icon'><JiaoxiangSvg /></div>
                                <div className='name'>轿厢</div>
                            </div>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><LoutiSvg /></div>
                                <div className='name'>楼梯</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        楼栋
                    </div>
                </div>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.storey) }}>
                                <div className='icon'><LoucengSvg /></div>
                                <div className='name'>楼层</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.room) }}>
                                <div className='icon'><FangjianSvg /></div>
                                <div className='name'>房间</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.elevator) }}>
                                <div className='icon'><DiantiSvg /></div>
                                <div className='name'>电梯</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.fire_control_room) }}>
                                <div className='icon'><XiaokongshiSvg /></div>
                                <div className='name'>消控室</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.pump_room) }}>
                                <div className='icon'><BengfangSvg /></div>
                                <div className='name'>泵房</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        编组
                    </div>
                </div>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 61 }} onClick={() => { }}>
                                <div className='icon'><FenggeSvg /></div>
                                <div className='name'>风格</div>
                            </div>
                            <div className='ope-item' style={{ width: 61 }} onClick={() => { }}>
                                <div className='icon'><YingyingSvg /></div>
                                <div className='name'>阴影</div>
                            </div>
                            <div className='ope-item' style={{ width: 61 }} onClick={() => { onClick(SIDE_TYPE.texture) }}>
                                <div className='icon'><CaizhiSvg /></div>
                                <div className='name'>材质</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        渲染
                    </div>
                </div>
            </div>
        </div>
    )
}