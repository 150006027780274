import { RoadNetwork } from "@/commons/interface/building/road-network";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";
import qs from "qs";

export function getRoadNetworkListByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/road-networks`, {
        params: {
            ...params
        }
    })
}

export function getRoadNetworkByUuids(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/road-networks`, {
        params: {
            uuids: uuids,
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}

export function addRoadNetwork(projectUuid: string, data: RoadNetwork, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/road-networks`, data, {
        params: {
            ...params
        }
    })
}

export function addRoadNetworkToUndo(data: RoadNetwork[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/road-networks`, data, {
        params: {
            ...params
        }
    })
}



export function updateRoadNetwork(uuid: string, data: RoadNetwork, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/road-networks/${uuid}`, data, {
        params: {
            ...params
        }
    })
}

export function deleteRoadNetworkByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/road-networks/${uuid}`, {
        params: {
            ...params
        }
    })
}

export function deleteRoadNetworkByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/road-networks`, {
        params: {
            ...params
        }
    })
}

export function deleteRoadNetworkByUuids(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/road-networks`, {
        params: {
            uuids: uuids,
            ...params
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}