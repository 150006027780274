import { Input } from "antd";
import { ReactComponent as DownArrowSvg } from '@/commons/icons/arrow/table-down-arrow.svg';
import { ReactComponent as DeleteHoverSvg } from '@/commons/icons/panel-icon/delete-white.svg';
import { ReactComponent as DeleteSvg } from '@/commons/icons/panel-icon/delete-black.svg';
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { floatVerification } from "@/commons/utils/antd-input";
import classNames from "classnames";
import './axis-create-list.less';
import genUUID from "@/commons/utils/gen-uuid";

export interface AxisNetItem {
    spacing?: string
    count?: string
}

interface IProps {
    title: string
    defaultAxisNumber: string
    setBlurFlag: (value: string) => void
}

export default function AxisNetCreateList(props: IProps, ref) {

    useImperativeHandle(ref, () => ({
        list: list,
        axisNumber: axisNumber,
    }))

    const bodyRef = useRef(null);
    const [list, setList] = useState<AxisNetItem[]>([{ spacing: '', count: '' }]);
    const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState<boolean>(false);
    const [hover, setHover] = useState<boolean>(false);
    const [axisNumber, setAxisNumber] = useState<string>("");

    useEffect(() => {
        const element = bodyRef.current;
        if (element) {
            const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;
            setHasVerticalScrollbar(hasVerticalScrollbar);
        }
    }, [list]);

    useEffect(() => {
        setAxisNumber(props.defaultAxisNumber);
    }, [props.defaultAxisNumber])

    const onListChange = (value: string, idx: number, field: string) => {
        list[idx][field] = value;
        // 如果修改的是最后一个元素，那么尾插一个新的元素
        if (idx === list?.length - 1) {
            list.push({
                spacing: "",
                count: "",
            })
        }
        setList([...list]);
    }

    const onListBlur = (value: string, idx: number, field: string) => {
        let newValue = floatVerification(value);
        if (!newValue || newValue == '') {
            newValue = "0";
        }
        if (Number(newValue) < 0) {
            newValue = "0";
        }
        list[idx][field] = newValue;
        setList([...list]);
        props.setBlurFlag(genUUID());
    }

    const clearData = () => {
        setList([{ spacing: '', count: '' }]);
        props.setBlurFlag(genUUID());
    }

    return (
        <div className="axis-net-list">
            <div className="head-row">
                <div className={classNames({
                    "head-cell": true,
                    "spacing": !hasVerticalScrollbar,
                    "spacing-scroll": hasVerticalScrollbar,
                    "bottom-border": true,
                    "left-border": true,
                })}>{props.title}</div>
                <div className={classNames({
                    "head-cell": true,
                    "count": !hasVerticalScrollbar,
                    "count-scroll": hasVerticalScrollbar,
                    "bottom-border": true,
                    "left-border": true,
                    "right-border": true,
                })}>个数</div>
            </div>
            <div className="content">
                <div className={classNames({
                    "content-body": true,
                })} ref={bodyRef}>
                    {list?.map((item, idx) => (
                        <div className="body-row" key={idx}>
                            <div className={classNames({
                                "body-cell arrow": true,
                                "bottom-border": true,
                                "right-border": true,
                            })}>
                                {idx === list?.length - 1 && <DownArrowSvg />}
                            </div>
                            <div className={classNames({
                                "body-cell spacing left-border": true,
                                "bottom-border": true,
                            })}>
                                <Input
                                    value={item?.spacing}
                                    onChange={(e) => onListChange(e.target.value, idx, "spacing")}
                                    onBlur={(e) => onListBlur(e.target.value, idx, "spacing")}
                                    onPressEnter={(e) => onListBlur((e.target as HTMLInputElement).value, idx, "spacing")}
                                />
                            </div>
                            <div className={classNames({
                                "body-cell count left-border": true,
                                "bottom-border": true,
                                "right-border": hasVerticalScrollbar,
                            })}>
                                <Input
                                    value={item?.count}
                                    onChange={(e) => onListChange(e.target.value, idx, "count")}
                                    onBlur={(e) => onListBlur(e.target.value, idx, "count")}
                                    onPressEnter={(e) => onListBlur((e.target as HTMLInputElement).value, idx, "count")}
                                />
                            </div>
                        </div>
                    ))}
                    <div className="gray-block"></div>
                </div>
                <div className="extra">
                    <div className="text">起始轴线号：</div>
                    <Input
                        width={30}
                        value={axisNumber}
                        onChange={(e) => setAxisNumber(e.target.value)}
                    />
                </div>
            </div>
            <div
                className="footer"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={clearData}
            >
                {!hover && <DeleteSvg />}
                {hover && <DeleteHoverSvg />}
            </div>
        </div >
    )
}