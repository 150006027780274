import localStore from "@/commons/store/local-store";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function saveUcs(data: any, params?: {}): Promise<AxiosResponse<any>> {

    const projectUuid = localStore.projectId
    data = {
        ...data,
        projectId: projectUuid
    }
    return bimAxios.post(`/projects/${projectUuid}/ucs`, data, {
        params: {
            ...params,
        }
    })

}


export function findUcsInProject(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/ucs`, {
        params: {
            ...params,
        }
    })
}

export function deleteUcs(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/ucs/${uuid}`, {
        params: {
            ...params,
        }
    })
}
export function updateUcs(data: any, params?: {}): Promise<AxiosResponse<any>> {
    const modelId = localStore.modelId
    return bimAxios.put(`/models/${modelId}/ucs`, data, {
        params: {
            ...params,
        }
    })
}

export function findUcsByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/ucs/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function findGlobalUcs(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/global-ucs`, {
        params: {
            ...params,
        }
    })
}

export function deleteUcsMulti(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/ucs/delete-multi`, uuids, {
        params: {
            ...params,
        }
    })
}
