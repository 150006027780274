import { Modal, Select, Tooltip, message } from 'antd'
import './index.less'
import {
    AntDesignOutlined, CameraOutlined
} from '@ant-design/icons';
import { ReactComponent as UndoSvg } from '@/commons/icons/operation-bar/undo.svg'
import { ReactComponent as UndoNoneSvg } from '@/commons/icons/operation-bar/undo-none.svg'
import { ReactComponent as RndoSvg } from '@/commons/icons/operation-bar/redo.svg'
import { ReactComponent as RndoNoneSvg } from '@/commons/icons/operation-bar/redo-none.svg'
import { ReactComponent as FilterSvg } from '@/commons/icons/operation-bar/filter.svg'
import { ReactComponent as ArraySvg } from '@/commons/icons/operation-bar/array.svg'
import { ReactComponent as MoveSvg } from '@/commons/icons/operation-bar/move.svg'
import { ReactComponent as CopySvg } from '@/commons/icons/operation-bar/copy.svg'
import { ReactComponent as MirrorSvg } from '@/commons/icons/operation-bar/mirror.svg'
import { ReactComponent as NineSvg } from '@/commons/icons/operation-bar/nine.svg'
import { ReactComponent as RotateSvg } from '@/commons/icons/operation-bar/rotate.svg'
import { ReactComponent as DeleteSvg } from '@/commons/icons/operation-bar/delete.svg'
import { ReactComponent as Light1Svg } from '@/commons/icons/operation-bar/light1.svg'
import { ReactComponent as Light2Svg } from '@/commons/icons/operation-bar/light2.svg'
import { ReactComponent as Light3Svg } from '@/commons/icons/operation-bar/light3.svg'
import { ReactComponent as Twovg } from '@/commons/icons/operation-bar/two.svg'
import { ReactComponent as MessageSvg } from '@/commons/icons/operation-bar/message.svg'
import { ReactComponent as InfoSvg } from '@/commons/icons/operation-bar/info.svg'
import { ReactComponent as LayerSvg } from '@/commons/icons/operation-bar/layer.svg'
import { ReactComponent as UcsSvg } from '@/commons/icons/operation-bar/ucs.svg'
import { ReactComponent as ArrowSvg } from '@/commons/icons/operation-bar/arrow.svg'
import { ReactComponent as StoreySvg } from '@/commons/icons/operation-bar/storey.svg'

// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate-2.svg'
// import { ReactComponent as SelectSvg } from '@/commons/icons/operation-bar/select.svg'
// import { ReactComponent as TrimSvg } from '@/commons/icons/operation-bar/trim.svg'
// import { ReactComponent as ChamferSvg } from '@/commons/icons/operation-bar/chamfer.svg'
// import { ReactComponent as PasteSvg } from '@/commons/icons/operation-bar/paste.svg'
// import { ReactComponent as ShearSvg } from '@/commons/icons/operation-bar/shear.svg'
// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate.svg'
// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate.svg'
// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate.svg'
// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate.svg'
// import { ReactComponent as Rotate2Svg } from '@/commons/icons/operation-bar/rotate.svg'
import { ReactComponent as ComponentSvg } from '@/commons/icons/operation-bar/component.svg'
import { graphicStore } from '@/commons/store/graphic-store'
import { useEffect, useState, useContext, useRef } from 'react'
import ColorChange from '../color/ColorChange';
import { AddPrimitivesCommand, AxisT, CommandGroup, EraseCommand, LineType, ModelBase, Point, ToggleDrawColorCommand, ToggleEntityColorCommand, ToggleEntityLockCommand, ToggleUcsCommand, UpdatePrimitivesCommand } from "pytha";
import LayerManage from '../layer/LayerManage';
import { DragModal } from 'tncet-common';
import UcsManage from '../ucs/UcsManage';
import { ExportComponent } from './export-component';
import { LayerType } from '@/commons/enums/layer-type';
import { ComponentT, SCircleT, SLineT } from 'tnbimbase';
import { Vector3 } from 'three';
import { SideContext } from '@/App';
import { SystemContext, StoreyContext } from '@/App';
import { SystemStatus } from "@/commons/context/system-store";
import { CAMERA_TYPE } from '@/panel/draw/viewport';
import { useParams } from 'react-router-dom';
import { StoreyStatus } from '@/commons/context/storey-store';
import { getStoreys } from '@/api/building/storey';
import { Storey } from '@/commons/interface/building/storey';
import { SideStatus } from '@/commons/context/side-store';
import { SIDE_TYPE } from '@/commons/enums/side-type';
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';
import CreateComponentPanel from '@/panel/component/create-component';
import { onActiveSubControl } from '@/commons/utils/graphic-method';
import { ComponentType } from '@/commons/enums/component-type';
import { createComponent, getComponentInfoByName, updateComponent } from '@/api/spec-common/component';
import genUUID from '@/commons/utils/gen-uuid';
import { findPrimitivesByName, deletePrimitivesInGraphic, updateComponentEntities } from '@/api/geometry/primitive';
import { Primitive } from '@/commons/interface/primitive';
import { Component } from '@/commons/interface/speccommon/component';

export default function OperationBar() {
    const { projectId } = useParams();
    const [undos, setUndos] = useState<any[]>([]);
    const [redos, setRedos] = useState<any[]>([]);
    const [currentColor, setCurrentColor] = useState(null);
    const [isColorByLayer, setIsColorByLayer] = useState(true);
    const [showLayerManage, setShowLayerManage] = useState<boolean>(false);
    const [showUcsManage, setShowUcsManage] = useState<boolean>(false);
    const [showComponent, setShowComponent] = useState<boolean>(false);
    const [currentLayerUuid, setCurrentLayerUuid] = useState<string>('');
    const [tempAxisT, setTempAxisT] = useState<AxisT>(new AxisT());
    // const [helperBox3, setHelperBox3] = useState<LineSegments>();
    const storeyContext = useContext<StoreyStatus>(StoreyContext);
    const sideContext = useContext<SideStatus>(SideContext);
    const systemContext = useContext<SystemStatus>(SystemContext);

    // 当前是否在编辑组件
    const [editingComponent, setEditingComponent] = useState<boolean>(false);
    const editingComponentRef = useRef(systemContext.editingComponent);
    // 当前编辑的组件实例
    const currentComponent = useRef<ComponentT>(null);
    // 当前编辑的组件对应的定义
    const currentComponentDefined = useRef<Component>(null);
    // 临时锁定图元的命令，用于编辑组件时锁定其他图元
    const lockEntitiesCommand = useRef(null);
    // 组件实例
    const [blockEntities, setBlockEntities] = useState<ComponentT[]>([]);

    useEffect(() => {
        editingComponentRef.current = editingComponent;
    }, [editingComponent])

    useEffect(() => {
        if (!projectId) return;
        // 注册层高
        getStoreys(projectId).then(res => {
            if (res.status === 200) {
                let storeys: Storey[] = res.data || [];
                storeys = storeys?.sort((a, b) => a.serialNum - b.serialNum);
                storeyContext.setStoreys([...storeys]);
            }
        })
    }, [projectId])

    useEffect(() => {
        if (!graphicStore?.context?.ucsContext?.currentUcs?.uuid) return;
        let storey = storeyContext.storeys?.find(item => item.uuid === graphicStore?.context?.ucsContext?.currentUcs?.uuid);
        if (!!storey) {
            storeyContext.setCurrentStorey(storey);
        } else {
            storeyContext.setCurrentStorey(null);
        }
    }, [graphicStore?.context?.ucsContext?.currentUcs?.uuid])

    useEffect(() => {
        if (!graphicStore?.extraContext?.getCurrentViewEditor()) return () => { };
        // 注册撤销重做
        graphicStore.extraContext.listeners.signals.undos.add(handleUndos);
        graphicStore.extraContext.listeners.signals.redos.add(handleRedos);
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.openComponentModal).add(openComponentModal);
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisStart).add(onAddTempAxisStart);
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisEnd).add(onAddTempAxisEnd);
        graphicStore.extraContext.listeners.signals.onProcessEscape.add(onProcessEscape);
        // graphicStore.extraContext.listeners.signals.onAddObjectsFinish.add(onAddObjectsFinish);
        // graphicStore.extraContext.listeners.signals.onRemoveObjectsFinish.add(onRemoveObjectsFinish);

        return () => {
            if (!graphicStore.extraContext.getCurrentViewEditor()) return;
            graphicStore.extraContext.listeners.signals.undos.remove(handleUndos);
            graphicStore.extraContext.listeners.signals.redos.remove(handleRedos);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.openComponentModal).remove(openComponentModal);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisStart).remove(onAddTempAxisStart);
            graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisEnd).remove(onAddTempAxisEnd);
            graphicStore.extraContext.listeners.signals.onProcessEscape.remove(onProcessEscape);
            // graphicStore.extraContext.listeners.signals.onAddObjectsFinish.add(onAddObjectsFinish);
            // graphicStore.extraContext.listeners.signals.onRemoveObjectsFinish.add(onRemoveObjectsFinish);
        }
    }, [graphicStore?.extraContext?.getCurrentViewEditor(), tempAxisT])

    const handleUndos = () => {
        setUndos([...graphicStore.extraContext.getCurrentViewEditor()?.history?.undos]);
    }

    const handleRedos = () => {
        setRedos([...graphicStore.extraContext.getCurrentViewEditor()?.history?.redos])
    }

    const undoClick = () => {
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.undo.dispatch();
    }

    const redoClick = () => {
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.redo.dispatch();
    }

    const onLayerChange = (layerUuid) => {
        let curLayer = graphicStore.context.layerContext.layers.find(item => item.uuid === layerUuid);
        graphicStore.context.layerContext.currentLayerUuid = layerUuid;
        graphicStore.context.layerContext.currentLayerColor = curLayer.color;
        graphicStore.context.layerContext.currentColor = curLayer.color;
        setCurrentLayerUuid(layerUuid);
        // 选中的图元
        let selectedEntities: ModelBase[] = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList();
        if (!selectedEntities || selectedEntities?.length === 0) {
            return;
        }
        if (curLayer?.type === LayerType.AXIS_NET) {
            let needAddPriArr = [];
            let changeEntities = selectedEntities?.map(item => {
                let clone = item.clone();
                clone.uuid = item.uuid;
                clone.layerUuid = layerUuid;
                // 图元选中的是线，切换的图层为轴网图层
                // 将线转为轴线
                if (clone.type === 'LineT') {
                    let lineJson: any = clone.toJson();
                    lineJson.type = 'SLineT';
                    lineJson.isGrid = true;
                    lineJson.isGridMain = true;
                    lineJson.isGridSplit = false;
                    lineJson.isDimension = false;
                    lineJson.gridUuid = lineJson.uuid;

                    clone = new SLineT();
                    clone.fromJson(lineJson);

                    // 为轴线插上翅膀
                    addExtendLineAndCircle(clone, needAddPriArr);
                    return clone;
                }
                return clone;
            })
            let command = new CommandGroup();
            command.cmds.push(new UpdatePrimitivesCommand(selectedEntities, changeEntities));
            command.cmds.push(new AddPrimitivesCommand(needAddPriArr));
            graphicStore.extraContext.getCurrentViewEditor().history.execute(command);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(changeEntities);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
        } else {
            let changeEntities = selectedEntities?.map(item => {
                item.layerUuid = layerUuid;
                return item;
            })
            new UpdatePrimitivesCommand(selectedEntities, changeEntities).execute();
        }

    }

    const addExtendLineAndCircle = (item, arr) => {
        let gridUuid = item.uuid;
        let lineDistance = graphicStore.context.renderContext.gridLineDistanceToCircle;
        let radius = graphicStore.context.renderContext.gridCircleSize;
        let circleDistance = lineDistance + radius;

        // 计算起点到终点延长线上距离x的另一个点，并将这个终点到这个点做一条线
        let startToEndV = item._geo.delta();
        let start = item._geo.start;
        let end = item._geo.end;
        let point1 = new Vector3(end.x, end.y, end.z);
        let direction1 = startToEndV.normalize();
        let newPoint1 = point1.clone().add(direction1.clone().multiplyScalar(lineDistance));
        let endExtendLine = new SLineT(end, newPoint1);
        endExtendLine.isGrid = true;
        endExtendLine.isDimension = true;
        endExtendLine.gridUuid = gridUuid;
        endExtendLine.lineType = LineType.DASH_DOT_LINE;
        endExtendLine.isLineTypeByLayer = false;
        arr.push(endExtendLine);

        let endToStartV = startToEndV.clone().negate();
        let point2 = new Vector3(start.x, start.y, start.z);
        let direction2 = endToStartV.normalize();
        let newPoint2 = point2.clone().add(direction2.clone().multiplyScalar(lineDistance));
        let startExtendLine = new SLineT(start, newPoint2);
        startExtendLine.isGrid = true;
        startExtendLine.isDimension = true;
        startExtendLine.gridUuid = gridUuid;
        startExtendLine.lineType = LineType.DASH_DOT_LINE;
        startExtendLine.isLineTypeByLayer = false;
        arr.push(startExtendLine);

        // 画圆
        let circleCenter1 = point1.clone().add(direction1.clone().multiplyScalar(circleDistance));
        let circle1 = new SCircleT(circleCenter1, radius);
        circle1.isGrid = true;
        circle1.gridUuid = gridUuid;
        circle1.lineType = LineType.DASH_DOT_LINE;
        circle1.isLineTypeByLayer = false;
        arr.push(circle1);

        let circleCenter2 = point2.clone().add(direction2.clone().multiplyScalar(circleDistance));
        let circle2 = new SCircleT(circleCenter2, radius);
        circle2.isGrid = true;
        circle2.gridUuid = gridUuid;
        circle2.lineType = LineType.DASH_DOT_LINE;
        circle2.isLineTypeByLayer = false;
        arr.push(circle2);
    }

    useEffect(() => {
        if (!graphicStore?.context?.layerContext?.currentLayerUuid) return;
        setCurrentLayerUuid(graphicStore.context.layerContext.currentLayerUuid);
    }, [graphicStore?.context?.layerContext?.currentLayerUuid])

    const handleChangeColor = (color) => {
        let oldColor = currentColor;
        let oldIsColorByLayer = isColorByLayer;
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        if (selects.length === 0) {

            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: false,
            }, {
                color: oldColor,
                isColorByLayer: oldIsColorByLayer,
            }))

        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, false));
        }
        editor.listeners.signals.onColorChanged.dispatch();
        setCurrentColor(color);
        setIsColorByLayer(false);
    }

    const handleColorByLayer = () => {
        if (!graphicStore.extraContext.getCurrentViewEditor()) return;
        let oldIsColorByLayer = isColorByLayer;
        let editor = graphicStore.extraContext.getCurrentViewEditor();
        let selects = editor.selectControl.getSelectedEntityList();
        let color = graphicStore.context.layerContext.currentLayerColor;
        if (selects.length === 0) {
            editor.history.execute(new ToggleDrawColorCommand({
                color: color,
                isColorByLayer: true,
            }, {
                color: graphicStore.context.layerContext.currentColor,
                isColorByLayer: oldIsColorByLayer,
            }));
        } else {
            editor.history.execute(new ToggleEntityColorCommand(selects, color, true));
        }
        editor.listeners.signals.onColorChanged.dispatch();
        setCurrentColor(color);
        setIsColorByLayer(true);
    }

    const onUcsChange = (ucsUuid) => {
        if (ucsUuid == null) return;
        let ucs = graphicStore?.context?.ucsContext?.ucsList?.find((ucs: AxisT) => ucs.uuid === ucsUuid);
        if (ucs == null) return;
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(ucs));
    }

    const onChangeCameraType = () => {
        if (systemContext.currentCameraType === CAMERA_TYPE.Orthographic) {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Perspective);
            message.success("切换至透视相机");
        } else {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Orthographic);
            message.success("切换至正交相机");
        }
    }
    const onStoreyChange = (storeyUuid) => {
        if (!storeyUuid) {
            // 取消层高
            storeyContext.setCurrentStorey(null);
            let wcs = graphicStore.context.ucsContext.getWcs();
            // graphicStore.context.ucsContext.setCurrentUcs(wcs);
            graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(wcs));
            return;
        }
        let storey = storeyContext.storeys?.find(item => item.uuid === storeyUuid);
        storeyContext.setCurrentStorey(storey);
        let workPlaneUcs = new AxisT();
        workPlaneUcs.origin = new Vector3(0, 0, (storey?.bottomElevation * 1000 || 0));
        workPlaneUcs.axisX = new Vector3(1, 0, 0);
        workPlaneUcs.axisY = new Vector3(0, 1, 0);
        workPlaneUcs.axisZ = new Vector3(0, 0, 1);
        workPlaneUcs.uuid = storeyUuid;
        workPlaneUcs.name = `层-${storey?.name}`;
        if (!graphicStore.context.ucsContext.ucsList?.some(item => item.uuid === storeyUuid)) {
            graphicStore.context.ucsContext.addUcsNoCurrent(workPlaneUcs);
        }
        graphicStore.extraContext.getCurrentViewEditor().history.execute(new ToggleUcsCommand(workPlaneUcs));
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redaw');
    }

    const openComponentModal = (closestPosition) => {
        setShowComponent(true);
        let tempAxisT = new AxisT();
        tempAxisT.origin.copy(closestPosition);
        tempAxisT.axisX.copy(graphicStore.context.ucsContext.currentUcs.axisX);
        tempAxisT.axisY.copy(graphicStore.context.ucsContext.currentUcs.axisY);
        tempAxisT.axisZ.copy(graphicStore.context.ucsContext.currentUcs.axisZ);
        tempAxisT.setWorkPlane(graphicStore.context.ucsContext.currentUcs.axisZ);
        tempAxisT.setValue('origin', closestPosition);
        tempAxisT.render();
        graphicStore.extraContext.sceneContext.scene.add(tempAxisT.viewObj);
        setTempAxisT(tempAxisT);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch("redraw");
    }

    const onAddTempAxisStart = () => {

    }

    const onAddTempAxisEnd = (helpAxis: AxisT) => {
        // 1、删除旧的坐标系
        if (tempAxisT && tempAxisT.viewObj) {
            graphicStore.extraContext.sceneContext.scene.remove(tempAxisT.viewObj);

            if (helpAxis && helpAxis.viewObj) {
                // 2、添加新的坐标系
                graphicStore.extraContext.sceneContext.scene.add(helpAxis.viewObj);
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch("redraw");
            }
            // 3、修改store中的值
            systemContext.cacheComponent.position = helpAxis.origin;
            systemContext.setCacheComponent({ ...systemContext.cacheComponent });
            setShowComponent(true);
            setTempAxisT(helpAxis);
        }
    }

    const onComponentSave = (cacheComponent, radioCheck) => {
        // 1 如果没有选择类型，默认归为自定义类型
        let type = systemContext.cacheComponent?.componentType;
        if (!type) {
            type = ComponentType.DIY;
        }
        // 2 将图元编为组件
        let entities = systemContext.cacheComponent?.primitives;
        let position = new Point(0, 0, 0);
        if (systemContext?.cacheComponent?.position) {
            position.set(systemContext?.cacheComponent?.position.x, systemContext?.cacheComponent?.position.y, systemContext?.cacheComponent?.position.z);
        }
        let normal = new Vector3(0, 0, 1);
        if (systemContext?.cacheComponent?.normal) {
            normal.set(systemContext?.cacheComponent?.normal.x, systemContext?.cacheComponent?.normal.y, systemContext?.cacheComponent?.normal.z);
        }
        let axisX = new Point(1, 0, 0);
        if (systemContext?.cacheComponent?.axisX) {
            axisX.set(systemContext?.cacheComponent?.axisX.x, systemContext?.cacheComponent?.axisX.y, systemContext?.cacheComponent?.axisX.z);
        }
        let component: ComponentT = new ComponentT(
            type,
            position,
            entities,
            cacheComponent?.name,
            normal,
            axisX,
        );
        let componentJson = component.toJson();
        createComponent({ ...componentJson, projectUuid: projectId }).then(res => {

            // TODO 3 判断是否选择了用组件替换图元，如果选择了，则删除旧的，替换为新的
            if (radioCheck) {
                graphicStore.extraContext.getCurrentViewEditor().removeObjects(entities);
                let componentPri = component.clone();
                // componentPri.templateUuid = component.uuid;
                graphicStore.extraContext.getCurrentViewEditor().addObjects([componentPri]);
                let addCommand = new AddPrimitivesCommand([componentPri]);
                let eraseCommand = new EraseCommand(entities);
                graphicStore.extraContext.getCurrentViewEditor().history.execute(new CommandGroup([addCommand, eraseCommand]))
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
                if (tempAxisT && tempAxisT?.viewObj) {
                    graphicStore.extraContext.sceneContext.scene.remove(tempAxisT.viewObj);
                    setTempAxisT(null);
                    systemContext.setCacheComponent({});
                    systemContext.setRefreshComponentFlag(genUUID());
                }
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
            }
            message.success("组件创建成功！");
        }).catch(err => {
            console.error("Error creating component:", err);
        });


        setShowComponent(false);
    }

    const onComponentCancel = () => {
        setShowComponent(false);
        if (tempAxisT && tempAxisT?.viewObj) {
            graphicStore.extraContext.sceneContext.scene.remove(tempAxisT.viewObj);
            setTempAxisT(null);
            systemContext.setCacheComponent({});
        }
    }

    // 打散
    const editComponent = () => {
        let selectedEntities = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList().filter(entity => !entity.lock && entity.type == 'ComponentT');
        if (selectedEntities.length == 0) {
            message.warning("请选择一个组件");
            return;
        }
        // 1 获取选中图元，作为component，组件实例
        let com = selectedEntities[0] as ComponentT;
        currentComponent.current = com;
        getComponentInfoByName(projectId, com.name).then(res => {
            // 组件定义
            let component: Component = res.data;
            currentComponentDefined.current = component;
        })
        findPrimitivesByName(projectId, com.name).then(res => {
            // 同名的其余组件
            let jsonList = res.data || [];
            let entities = jsonList.map((json: Primitive) => {
                let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.properties.type))();
                entity.fromJson(json.properties);
                return entity;
            });
            setBlockEntities(entities);
        })
        // 2 锁定其他图元
        let otherEntities = graphicStore.extraContext.getCurrentViewEditor().getEntities().filter(entity => entity.uuid != com.uuid);
        lockEntitiesCommand.current = new ToggleEntityLockCommand(graphicStore.extraContext.getCurrentViewEditor(), otherEntities, true);
        lockEntitiesCommand.current.execute();
        // 3 将选中的块原地打散
        graphicStore.extraContext.getCurrentViewEditor().removeObject(com);
        graphicStore.extraContext.getCurrentViewEditor().addObjects(com.getEntities());
        graphicStore.extraContext.listeners.signals.emptySelectedEntities.dispatch();
        // 4 将打散状态变更为true
        systemContext.setEditingComponent(true);
        setEditingComponent(true);

        graphicStore.extraContext.listeners.signals.needRender.dispatch('redraw');

    }

    // 保存组件
    const saveComponent = () => {
        systemContext.setEditingComponent(false);
        setEditingComponent(false);
        // 删除组件内零散的图元
        let entities = graphicStore.extraContext.getCurrentViewEditor().getEntities().filter(entity => !entity.lock);
        if (entities.length > 100) {
            cancelEditComponent();
            return;
        }
        deletePrimitivesInGraphic(entities.map(entity => entity.uuid));
        // 组件定义
        let blockDefinedJson = currentComponentDefined.current;
        let blockDefined = new ComponentT(blockDefinedJson.componentType).fromJson(blockDefinedJson);
        // 当前组件实例
        let block = currentComponent.current;
        graphicStore.extraContext.getCurrentViewEditor().removeObjects(entities);
        // 根据两个block的normal，axisX和scaleRatio，将newEntities进行移动、旋转、缩放变换，替换blockDefined的entities
        blockDefined.setEntities(entities.map(entity => entity.clone()));
        let moveDir = blockDefined.position.clone().sub(block.position);
        // 移动blockDefined基点
        blockDefined.getEntities().forEach(entity => entity.move(moveDir.clone().normalize(), moveDir.length()));
        let normalRotateAxis = block.normal.clone().cross(blockDefined.normal).normalize();
        if (Math.abs(block.normal.dot(blockDefined.normal) + 1) < 1e-6) {
            normalRotateAxis = block.axisX.clone();
        }
        let normalDeltaAngle = blockDefined.normal.angleTo(block.normal);
        // 绕normal1 × normal2，旋转normal1-normal2角度，即旋转到blockDefined所在的normal方向
        blockDefined.getEntities().forEach(entity => entity.rotate(blockDefined.position, normalRotateAxis, normalDeltaAngle));
        let tempAxisX = block.axisX.clone().applyAxisAngle(normalRotateAxis, normalDeltaAngle);
        let axisXRotateAxis = tempAxisX.clone().cross(blockDefined.axisX).normalize();
        let axisXDeltaAngle = tempAxisX.angleTo(blockDefined.axisX);
        if (Math.abs(tempAxisX.dot(blockDefined.axisX) + 1) < 1e-6) {
            axisXRotateAxis = blockDefined.normal.clone();
        }
        // 绕tempAxisX × axisX2，旋转tempAxisX-axisX2角度，即旋转到blockDefined所在的axisX方向
        blockDefined.getEntities().forEach(entity => entity.rotate(blockDefined.position, axisXRotateAxis, axisXDeltaAngle));
        // 按缩放比例缩放entities
        blockDefined.getEntities().forEach(entity => entity.scale(blockDefined.position, blockDefined.scaleRatio / block.scaleRatio));

        // 更新组件定义
        updateComponent(blockDefined.toJson()).then(res => {
            // 更新项目中其余组件实例
            for (let i = 0; i < blockEntities.length; i++) {
                let oneBlock = blockEntities[i] as ComponentT;
                // 如果是当前实例，不用进行图元转换，跳过
                if (oneBlock.uuid === block.uuid) {
                    oneBlock.setEntities(entities);
                    continue;
                }
                // 同上面更新块定义一样，移动、旋转图元
                oneBlock.setEntities(entities.map(entity => entity.clone()));
                let moveDir = oneBlock.position.clone().sub(block.position);
                oneBlock.getEntities().forEach(entity => entity.move(moveDir.clone().normalize(), moveDir.length()));
                let normalRotateAxis = block.normal.clone().cross(oneBlock.normal).normalize();
                if (Math.abs(block.normal.dot(oneBlock.normal) + 1) < 1e-6) {
                    normalRotateAxis = block.axisX.clone();
                }
                let normalDeltaAngle = oneBlock.normal.angleTo(block.normal);
                oneBlock.getEntities().forEach(entity => entity.rotate(oneBlock.position, normalRotateAxis, normalDeltaAngle));
                let tempAxisX = block.axisX.clone().applyAxisAngle(normalRotateAxis, normalDeltaAngle);
                let axisXRotateAxis = tempAxisX.clone().cross(oneBlock.axisX).normalize();
                let axisXDeltaAngle = tempAxisX.angleTo(oneBlock.axisX);
                if (Math.abs(tempAxisX.dot(oneBlock.axisX) + 1) < 1e-6) {
                    axisXRotateAxis = oneBlock.normal.clone();
                }
                oneBlock.getEntities().forEach(entity => entity.rotate(oneBlock.position, axisXRotateAxis, axisXDeltaAngle));
                oneBlock.getEntities().forEach(entity => entity.scale(oneBlock.position, oneBlock.scaleRatio / block.scaleRatio));
            }
            // 撤销冻结
            lockEntitiesCommand.current.undo();
            // 更新所有块实例
            updateComponentEntities(blockEntities.map((entity: ModelBase) => {
                if (entity.toJson) {
                    return entity.toJson();
                }
                return null
            }));
            graphicStore.extraContext.getCurrentViewEditor().addObjects(blockEntities);
            // graphicStore.extraContext.listeners.signals.needRender.dispatch('redraw');
        })

    }

    // 放弃编辑组件
    const cancelEditComponent = () => {
        systemContext.editingComponent = false;
        setEditingComponent(false);
        lockEntitiesCommand.current.undo();
        // 删除组件内零散的图元
        let entities = graphicStore.extraContext.getCurrentViewEditor().getEntities().filter(entity => !entity.lock);
        if (entities.length > 100) {
            return;
        }
        deletePrimitivesInGraphic(entities.map(entity => entity.uuid));
        graphicStore.extraContext.getCurrentViewEditor().removeObjects(entities);
        let block = currentComponent.current;
        graphicStore.extraContext.getCurrentViewEditor().addObject(block);
        graphicStore.extraContext.listeners.signals.needRender.dispatch('redraw')
    }

    const onProcessEscape = () => {
        // if (helperBox3) {
        //     graphicStore.extraContext.sceneContext.scene.remove(helperBox3);
        //     setHelperBox3(null);
        //     graphicStore.extraContext.listeners.signals.needRender.dispatch('redraw');
        // }
        if (editingComponentRef.current == true) {
            // 当前正处于编辑组件，esc放弃编辑组件
            cancelEditComponent();
        }
    }

    // const onAddObjectsFinish = (entities: ModelBase[]) => {
    //     if (!systemContext.editingComponent) {
    //         return;
    //     }
    //     // 添加到临时图元
    //     let editor = graphicStore.extraContext.getCurrentViewEditor();
    //     for (let entity of entities) {
    //         if (!editor.tmpBlockUuids.has(entity.uuid)) {
    //             editor.tmpBlockUuids.add(entity.uuid);
    //         }
    //     }
    // }

    // const onRemoveObjectsFinish = (entities: ModelBase[]) => {
    //     if (!systemContext.editingComponent) {
    //         return;
    //     }
    // }


    return (
        <div className="operation-bar-container">
            <Tooltip title="撤回" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                {undos?.length > 0 && <UndoSvg onClick={undoClick} />}
                {(!undos || undos?.length === 0) && <UndoNoneSvg />}
            </Tooltip>
            <Tooltip title="重做" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                {redos?.length > 0 && <RndoSvg onClick={redoClick} />}
                {(!redos || redos?.length === 0) && <RndoNoneSvg />}
            </Tooltip>
            <Tooltip title="图层" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <div className='layer-select-container'>
                    <div className='layer-icon' onClick={() => {
                        setShowLayerManage(true);
                        if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
                        graphicStore?.extraContext?.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
                    }}>
                        <LayerSvg></LayerSvg>
                    </div>
                    <Select
                        style={{ width: '132px' }}
                        size="small"
                        suffixIcon={<ArrowSvg />}
                        // value={graphicStore?.context?.layerContext?.currentLayerUuid}
                        value={currentLayerUuid}
                        dropdownAlign={{
                            points: ['tc', 'bc'],
                            offset: [0, 0],
                            overflow: { adjustY: 0, adjustX: 0 },
                        }}
                        onChange={(e) => onLayerChange(e)}
                    >
                        {graphicStore?.context?.layerContext?.layers?.map((layer: any, key) => (
                            <Select.Option value={layer?.uuid} key={layer?.uuid}>{layer?.name}</Select.Option>
                        ))}
                    </Select>
                </div>

            </Tooltip>
            <Tooltip title="UCS" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <div className='ucs-select-container'>
                    <div className='ucs-icon' onClick={() => setShowUcsManage(true)}>
                        <UcsSvg></UcsSvg>
                    </div>
                    <Select
                        style={{ width: '132px' }}
                        size="small"
                        suffixIcon={<ArrowSvg />}
                        value={graphicStore?.context?.ucsContext?.currentUcs?.uuid}
                        dropdownAlign={{
                            points: ['tc', 'bc'],
                            offset: [0, 0],
                            overflow: { adjustY: 0, adjustX: 0 },
                        }}
                        onChange={(e) => onUcsChange(e)}
                    >
                        {graphicStore?.context?.ucsContext?.ucsList?.sort((a, b) => (b.isWcs ? 1 : 0) - (a.isWcs ? 1 : 0))?.map((ucs: any, key) => (
                            <Select.Option value={ucs?.uuid} key={key}>{ucs?.name}</Select.Option>
                        ))}
                    </Select>
                </div>
            </Tooltip>
            <ColorChange
                editor={graphicStore?.extraContext?.getCurrentViewEditor()}
                selectColor={(color) => { handleChangeColor(color); }}
                selectByLayer={() => handleColorByLayer()}
            />
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <FilterSvg />
            </Tooltip>
            <Tooltip title="阵列" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <ArraySvg onClick={() => onActiveSubControl('ARRAY')} />
            </Tooltip>
            <Tooltip title="移动" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <MoveSvg onClick={() => onActiveSubControl('MOVE')} />
            </Tooltip>
            <Tooltip title="复制" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <CopySvg onClick={() => onActiveSubControl('COPY')} />
            </Tooltip>
            <Tooltip title="镜像" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <MirrorSvg onClick={() => onActiveSubControl('MIRROR')} />
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <NineSvg />
            </Tooltip>
            <Tooltip title="旋转" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <RotateSvg onClick={() => onActiveSubControl('ROTATE')} />
            </Tooltip>
            <Tooltip title="删除" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <DeleteSvg onClick={() => onActiveSubControl('ERASE')} />
            </Tooltip>
            <Tooltip title="TODO文字" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <AntDesignOutlined onClick={() => onActiveSubControl('DT')} />
            </Tooltip>
            <Tooltip title="TODO修剪" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <AntDesignOutlined onClick={() => onActiveSubControl('TRIM')} />
            </Tooltip>
            <Tooltip title="TODO缩放" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <AntDesignOutlined onClick={() => onActiveSubControl('SCALE')} />
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <Light1Svg />
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <Light2Svg />
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <Light3Svg />
            </Tooltip>
            <Tooltip title="组件" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <ComponentSvg onClick={() => {
                    graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
                    sideContext.setSelectedPanelValue(SIDE_TYPE.component);
                }} />
            </Tooltip>
            <Tooltip title="组件" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <span onClick={() => {
                    editingComponent ? saveComponent() : editComponent();
                }}>{editingComponent ? '保存组件' : '编辑组件'}</span>
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <Twovg />
            </Tooltip>
            <Tooltip title="TODO" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <InfoSvg />
            </Tooltip>
            <Tooltip title="导出为组件" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                {/* todo 图标需更换，将选中图元导出为组件 */}
                <MessageSvg onClick={() => ExportComponent(graphicStore.extraContext.getCurrentViewEditor())} />
            </Tooltip>
            <Tooltip title={systemContext.currentCameraType === CAMERA_TYPE.Orthographic ? "切换为透视相机" : "切换为正交相机"} color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <CameraOutlined onClick={onChangeCameraType} />
            </Tooltip>

            <Tooltip title="层高" color="#555555" mouseEnterDelay={0} mouseLeaveDelay={0} arrow={false}>
                <div className='storey-select-container' style={{ marginLeft: 'auto', marginRight: 4 }}>
                    <div className='storey-icon' onClick={() => { }}>
                        <StoreySvg></StoreySvg>
                    </div>
                    <Select
                        style={{ width: '132px' }}
                        size="small"
                        suffixIcon={<ArrowSvg />}
                        value={storeyContext.currentStorey?.uuid}
                        dropdownAlign={{
                            points: ['tc', 'bc'],
                            offset: [0, 0],
                            overflow: { adjustY: 0, adjustX: 0 },
                        }}
                        onChange={(e) => onStoreyChange(e)}
                        allowClear
                    >
                        {storeyContext.storeys?.map((item: Storey, key) => (
                            <Select.Option value={item?.uuid} key={key}>{item?.name}</Select.Option>
                        ))}
                    </Select>
                </div>
            </Tooltip>


            <DragModal
                title="图层管理"
                visible={showLayerManage}
                width={1000}
                mask
                destroyOnClose
                onClose={() => setShowLayerManage(false)}>
                <LayerManage />
            </DragModal>

            <DragModal
                title="UCS"
                visible={showUcsManage}
                width={400}
                mask
                destroyOnClose
                onClose={() => setShowUcsManage(false)}>
                <UcsManage storeys={storeyContext.storeys} />
            </DragModal>

            <Modal
                open={showComponent}
                mask={false}
                destroyOnClose
                onCancel={() => {
                    setShowComponent(false);
                }}
                centered={false}
                width={325}
                footer={null}
                closeIcon={false}
                wrapClassName="tn-base-modal"
            >
                <CreateComponentPanel
                    onClose={() => setShowComponent(false)}
                    onSave={onComponentSave}
                    onCancel={onComponentCancel}
                />
            </Modal>
        </div>
    )
}