import { Component } from "@/commons/interface/speccommon/component";
import { message } from "antd";
import { Vector3 } from "three";

export function ExportComponent(editor) {
    // 当前选中的图元
    const entities = editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock);
    if (entities.length == 0) {
        message.warning("请选择图元")
        return;
    }
    // 计算当前选中图元的包围盒
    let minX = +Infinity;
    let minY = +Infinity;
    let minZ = +Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;
    let boundingBoxs = [];
    entities.forEach(item => {
        boundingBoxs.push(item.getBoundingBox());
    })
    for (let i = 0, l = boundingBoxs.length; i < l; i++) {
        minX = Math.min(minX, boundingBoxs[i].min.x);
        minY = Math.min(minY, boundingBoxs[i].min.y);
        minZ = Math.min(minZ, boundingBoxs[i].min.z);
        maxX = Math.max(maxX, boundingBoxs[i].max.x);
        maxY = Math.max(maxY, boundingBoxs[i].max.y);
        maxZ = Math.max(maxZ, boundingBoxs[i].max.z);
    }
    // 基点（组件的插入点），包围盒的最小角点
    let basePoint = new Vector3(minX, minY, minZ);
    // 计算以基点为坐标原点，当前坐标系下，图元的几何信息
    let componentNormal = editor.context.ucsContext.currentUcs.axisZ;
    let componentAxisX = editor.context.ucsContext.currentUcs.axisX;
    let moveDir = basePoint.clone().negate();
    let componentDefinedEntities = entities.map(entity => entity.clone());
    componentDefinedEntities.forEach(entity => entity.move(moveDir.clone().normalize(), moveDir.length()));
    let origin = new Vector3(0, 0, 0);
    let worldX = new Vector3(1, 0, 0);
    let worldZ = new Vector3(0, 0, 1);
    let normalRotateAxis = componentNormal.clone().cross(worldZ).normalize();
    if (Math.abs(componentNormal.dot(worldZ) + 1) < 1e-6) {
        normalRotateAxis = componentAxisX.clone();
    }
    let normalDeltaAngle = worldZ.angleTo(componentNormal);
    componentDefinedEntities.forEach(entity => entity.rotate(origin, normalRotateAxis, normalDeltaAngle));
    let tempAxisX = componentAxisX.clone().applyAxisAngle(normalRotateAxis, normalDeltaAngle);
    let axisXRotateAxis = tempAxisX.clone().cross(worldX).normalize();
    let axisXDeltaAngle = tempAxisX.angleTo(worldX);
    if (Math.abs(tempAxisX.dot(worldX) + 1) < 1e-6) {
        axisXRotateAxis = worldZ.clone();
    }
    componentDefinedEntities.forEach(entity => entity.rotate(origin, axisXRotateAxis, axisXDeltaAngle));
    let component: Component = {
        entities: componentDefinedEntities.map(item => item.toJson())
    }
    let data = component;
    let json = JSON.stringify(data, undefined, 4);
    const blob = new Blob([json], { type: 'text/json' });
    let href = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.download = '组件.txt';
    link.href = href;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
}