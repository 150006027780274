/**
 * 定义在几何库外部的二级控制器的控制器类型编号
 */
export enum EXTRA_CTRL_TYPE {
    ASSEMBLE_FENCE = 10001, // 布置围墙
    ASSEMBLE_HOLE, // 布置洞口
    ASSEMBLE_WALL, // 布置墙
    ASSEMBLE_DOOR, // 布置门
    ASSEMBLE_WINDOW, // 布置窗
    SELECT_COM_AXIS_CONTROL, // 选择组件轴
}