import React, { useEffect } from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import { UploadFileState, DownloadState, useUploadService, useDownloadService, UploadPanel, DownloadPanel } from 'tncet-common';
import { SideStatus, useSideService } from './commons/context/side-store';
import { SystemStatus, useSystemService } from './commons/context/system-store';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { checkFileExists, getFileUploadUrl } from './api/geometry/file';
import zhCN from 'antd/lib/locale/zh_CN'
import ProjectLayout from './layout/project';
import { StoreyStatus, useStoreyService } from './commons/context/storey-store';
import ProjectMain from './project-main';
import LoginMain from './layout/login';
import { getUsersCheckLogin } from './api/cooperate/user';
import localStore, { LOCAL_USER_TYPE } from './commons/store/local-store';
import { User } from './commons/interface/cooperate/user';
import CryptoJS from 'crypto-js';

export const SystemContext = React.createContext<SystemStatus>(null);
export const UploadFileContext = React.createContext<UploadFileState>(null);
export const DownloadContext = React.createContext<DownloadState>(null);
export const StoreyContext = React.createContext<StoreyStatus>(null);
export const SideContext = React.createContext<SideStatus>(null);

function App() {

    const systemContext = useSystemService();
    const navigate = useNavigate();

    useEffect(() => {
        let timer$ = setInterval(() => {
            checkLogin();
        }, 60000)
        return (() => {
            clearInterval(timer$);
        })
    }, [])

    const checkLogin = () => {
        if (!localStore.userId) return;
        getUsersCheckLogin().then(res => {
            if (res.status === 200) {
                let user: User = res.data;
                localStore.userId = user?.id;
                localStore.userUuid = user?.uuid;
                localStore.name = user?.name || '';
                localStore.set(LOCAL_USER_TYPE, CryptoJS.MD5(user?.type?.toString()).toString());
            }
        }).catch(err => {
            localStore.clear();
            navigate("/login");
        })
    }

    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                components: {
                    Radio: {
                        dotSize: 10,
                        radioSize: 14,
                        buttonPaddingInline: 8,
                        wrapperMarginInlineEnd: 4,
                    },
                },
            }}
        >
            <SystemContext.Provider value={systemContext}>
                <UploadFileContext.Provider value={useUploadService()}>
                    <DownloadContext.Provider value={useDownloadService()}>
                        <StoreyContext.Provider value={useStoreyService()}>
                            <SideContext.Provider value={useSideService()}>
                                {/* <ProjectMain /> */}
                                <div className="tn-base">
                                    <Routes>
                                        <Route path='/desktop' element={<ProjectMain />} />
                                        <Route path='/project/:projectId' element={<ProjectLayout />} />
                                        <Route path='/login' element={<LoginMain />} />
                                        <Route path='*' element={<Navigate to="/desktop" />} />
                                    </Routes>
                                    <div className='upload-download-panel'>
                                        <UploadPanel checkFileExists={checkFileExists} getUploadUrl={getFileUploadUrl} uploadContext={UploadFileContext} />
                                        <DownloadPanel downloadContext={DownloadContext} />
                                    </div>
                                </div>
                            </SideContext.Provider>
                        </StoreyContext.Provider>
                    </DownloadContext.Provider>
                </UploadFileContext.Provider>
            </SystemContext.Provider>
        </ConfigProvider>
    );
}

export default App;