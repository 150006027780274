import { Storey } from "@/commons/interface/building/storey"
import { StdResponse, bimAxios } from "@/config/axios"
import qs from "qs"


export function getStoreys(projectUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/storeys`, {
        params: {
            ...params,
        }
    })
}

export function addStorey(projectUuid: string, data: Storey, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/storeys`, data, {
        params: {
            ...params,
        }
    })
}


export function updateStorey(uuid: string, data: Storey, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.put(`/storeys/${uuid}`, data, {
        params: {
            ...params,
        }
    })
}

export function deleteStoreys(projectUuid: string, uuids: string[], params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/storeys/delete`, {
        params: {
            ...params,
            uuids: uuids
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}
