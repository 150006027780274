
const tempSprayHeadData: { value: string, uuid: string }[] = [
    { value: "喷头_DE", uuid: "2dd44112-90e1-4201-9899-7e5be5e820a1" },
    { value: "喷头_ny", uuid: "8bcf51a4-9ac1-43b8-a3b1-9287db8a72e2" },
    { value: "喷头_eu", uuid: "6937b18c-3b4a-4bce-8401-089c9e2c289b" },
    { value: "喷头_Gn", uuid: "527dfff8-f72c-4d9b-aeba-433211fcfe7b" },
    { value: "喷头_as", uuid: "38c00a07-1e13-4602-9b39-7da5c9e38f74" },
    { value: "喷头_pz", uuid: "47558861-cf38-420d-80ce-e79e962697ed" },
    { value: "喷头_hn", uuid: "a36eede9-32bf-44e1-a545-1ef9aff6abda" },
    { value: "喷头_NI", uuid: "abdd6087-7694-4b0c-9ef6-7010e7c2b0ef" },
    { value: "喷头_xA", uuid: "5b0dfd36-e439-4453-95d2-e23423e4c4ce" },
    { value: "喷头_Sg", uuid: "75d8351a-c1b9-4ea7-bc76-8eab1913e1cb" },
]

const tempFireHydrantData: { value: string, uuid: string }[] = [
    { value: "消火栓_DE", uuid: "bdb9ec87-5453-4984-9d42-2996cfa2aec0" },
    { value: "消火栓_ny", uuid: "7486855e-80a1-4090-bdfd-0d6066071814" },
    { value: "消火栓_eu", uuid: "b703e3b5-badb-4a18-a174-9b0a951a1ecd" },
    { value: "消火栓_Gn", uuid: "05b763f2-7974-4b2f-b4f9-de0872093eab" },
    { value: "消火栓_as", uuid: "54ee8ed6-bbe3-40c3-ba3c-158a152a74ef" },
    { value: "消火栓_pz", uuid: "8a50c4a6-7fee-4aa7-9069-e411064773a3" },
    { value: "消火栓_hn", uuid: "f8d0c6db-456e-469a-905e-1899a73ce83f" },
    { value: "消火栓_NI", uuid: "28261560-e8ec-4c62-ad48-16088a6a9a0d" },
    { value: "消火栓_xA", uuid: "3f6775fb-5ab7-4a71-b491-7886a95d319c" },
    { value: "消火栓_Sg", uuid: "aa6ca1ca-c3ae-4290-b06d-a430f604a0fe" },
]

const tempFireExtinguisherData: { value: string, uuid: string }[] = [
    { value: "灭火器_DE", uuid: "4970d326-95f8-4359-a7cb-5cb8c42a295f" },
    { value: "灭火器_ny", uuid: "01b57bfa-48e4-4aad-8a23-21d78cb34520" },
    { value: "灭火器_eu", uuid: "ba68bdfe-6e4d-4a00-a89a-6bd6007629cb" },
    { value: "灭火器_Gn", uuid: "abe22e3a-f4a7-4cba-9006-0f44e3f54ab6" },
    { value: "灭火器_as", uuid: "6a16db40-4762-4d03-aed3-ec98821e8998" },
    { value: "灭火器_pz", uuid: "36f81264-ad8b-4dfe-a0fe-eab91d3b1d17" },
    { value: "灭火器_hn", uuid: "a23d64de-53a4-411f-b44b-8c0e2fb41ef9" },
    { value: "灭火器_NI", uuid: "644edafc-04b5-4cd6-a367-14f698779aa3" },
    { value: "灭火器_xA", uuid: "906e9f23-827d-414a-ab05-b0a33489d593" },
    { value: "灭火器_Sg", uuid: "5c7e27f2-ced4-45da-8d48-14853a3d647d" },
]

const tempSmokeDetectorData: { value: string, uuid: string }[] = [
    { value: "烟感器_DE", uuid: "16872600-d3b0-456b-a5f4-714215497e28" },
    { value: "烟感器_ny", uuid: "ed950edd-4a40-48d0-88c3-1c15ed107e9b" },
    { value: "烟感器_eu", uuid: "df8c180d-7b32-4ea3-9543-ad62b1ead46f" },
    { value: "烟感器_Gn", uuid: "d8774ae4-c997-4924-8787-8126da2ff339" },
    { value: "烟感器_as", uuid: "38d139a2-778f-4bc8-98bb-bfe840823c48" },
    { value: "烟感器_pz", uuid: "ab45eb2e-f1de-4e5e-bf09-3c4f1bc215ba" },
    { value: "烟感器_hn", uuid: "9ba83c4e-1862-483a-bcd5-9a9eae6cafaf" },
    { value: "烟感器_NI", uuid: "a2021ce1-942f-43e7-afd8-00f1a1136d87" },
    { value: "烟感器_xA", uuid: "0a4aca43-2ea8-40a7-a6ff-f87499887db2" },
    { value: "烟感器_Sg", uuid: "d82f680f-e226-42f0-9889-5a21744600be" },
]

const tempPatroPointData: { value: string, uuid: string }[] = [
    { value: "巡更点_DE", uuid: "0cc61e2c-39b6-414a-a8ae-cc678f9cf8b3" },
    { value: "巡更点_ny", uuid: "221c5888-9290-4500-9612-dc62e548b92d" },
    { value: "巡更点_eu", uuid: "50654c2a-4ee5-4a91-89a2-833d5f9279e3" },
    { value: "巡更点_Gn", uuid: "585a57cb-5a5f-4107-849d-b4cf4f23831e" },
    { value: "巡更点_as", uuid: "61cf6428-2d0b-4a78-bcdf-acfadc387a8f" },
    { value: "巡更点_pz", uuid: "c5060843-c262-401e-8581-0366e60961b2" },
    { value: "巡更点_hn", uuid: "cc5b2f66-39be-48f5-8cc9-1a9fbf0a1bda" },
    { value: "巡更点_NI", uuid: "e5739d83-83e3-47b9-83eb-9f5b82a3d893" },
    { value: "巡更点_xA", uuid: "e7128277-533a-4af7-a8f6-99e1c03201c3" },
    { value: "巡更点_Sg", uuid: "5bb01597-9159-40cb-8c52-646e058ad758" },
]

const tempMonitorData: { value: string, uuid: string }[] = [
    { value: "摄像头_DE", uuid: "c0f79a9a-8027-5fb8-3631-9f700bab8ed2" },
    { value: "摄像头_ny", uuid: "4df2110d-dd63-d017-fde4-0b9f3abf6658" },
    { value: "摄像头_eu", uuid: "c52f6d33-dc4e-34e5-b560-9bf78142ac29" },
    { value: "摄像头_Gn", uuid: "82a31432-c4e1-61b7-c471-60849ee69ed3" },
    { value: "摄像头_as", uuid: "537e9e3f-6b20-c8e7-3a63-f36455c6639b" },
    { value: "摄像头_pz", uuid: "b061dcfd-af68-4a63-2983-59cc6d601b46" },
    { value: "摄像头_hn", uuid: "3925af84-c60c-a97d-79ea-ecf52824897f" },
    { value: "摄像头_NI", uuid: "d9cfed7f-9a46-09ec-8716-c02e639f8832" },
    { value: "摄像头_xA", uuid: "e0e8da60-dd4d-ab34-4fb1-f7a7ace9e3d9" },
    { value: "摄像头_Sg", uuid: "8e3bef5a-531c-e40a-51b9-dbd4b065d01e" },
]


export default function getTempData(type) {
    if (type == "sprayHead") {
        return tempSprayHeadData
    } else if (type == "fireHydrant") {
        return tempFireHydrantData
    } else if (type == "fireExtinguisher") {
        return tempFireExtinguisherData
    } else if (type == "smokeDetector") {
        return tempSmokeDetectorData
    } else if (type == "patroPoint") {
        return tempPatroPointData
    } else if (type == "monitor") {
        return tempMonitorData
    }
    return []

}