import { Upload, message } from "antd";
import "./index.less";
import { onActiveSubControl } from "@/commons/utils/graphic-method";
import { BIM_SINGAL, BUILDING_SUB_CONTROL } from "tnbimbase";
import { useEffect, useRef, useState } from "react";
import { graphicStore } from "@/commons/store/graphic-store";

import { ReactComponent as UploadSvg } from '@/commons/icons/side/groud-panel/upload.svg'
import { ReactComponent as AssembleSvg } from '@/commons/icons/side/groud-panel/assemble.svg'


import { TEXTURE_CATEGORY, getTypeName } from '@/commons/enums/texture'
import { TextureUploadUrl, getTextureUrl, getTextures } from "@/api/spec-common/texture";
import { useParams } from "react-router-dom";
import { CustomSelect } from "@/commons/components/select-with-picture";
import { Texture } from "@/commons/interface/texture";
import SideBarTool from "@/commons/components/side-bar-tool";
import { ContextMenuFC } from "tncet-common";
import RadioGroup from "@/commons/components/radio-group";

interface Iprops {
    width: number
}

const uploadButtonStyle =
{
    "backgroundColor": "#FFFFFF",
    "overflow": "hidden",
    "color": "#000000",
    "textOverflow": "ellipsis",
    "fontFamily": "Hiragino Sans GB",
    "fontSize": "12px",
    "fontStyle": "normal",
    "fontWeight": 300,
    "lineHeight": "22px",
    "paddingLeft": "12px",
    "paddingRight": "12px",
    "marginBottom": "0px",
    "alignItems": "center",
    "cursor": "pointer",
}



export function GroundPanel(prop: Iprops) {

    const { projectId } = useParams();

    const [useRegion, setUseRegion] = useState<boolean>(true);

    const [data, setData] = useState<{ [key: string]: { name: string, value: any, url: string }[] }>();


    const uploadRef = useRef(null);
    const [cmOpen, setCmOpen] = useState<boolean>(false); // 是否展开右键菜单
    const [top, setTop] = useState<number>();
    const [left, setLeft] = useState<number>();


    const [selectedMatUuid, setSelectedMatUuid] = useState('');

    const generateData = (needUpdateMap: boolean = false) => {
        getTextures(
            projectId,
            {
                category: TEXTURE_CATEGORY.ground.key
            }).then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    let resData = res.data as Texture[];
                    let tmpData: { [key: string]: { name: string, value: any, url: string }[] } = {};
                    if (needUpdateMap) {
                        let textures: Texture[] = res.data || [];
                        textures.forEach(texture => {
                            if (texture && texture.uuid && texture.url) {
                                graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = getTextureUrl(texture.url);
                            }
                        })
                    }
                    resData.forEach(texture => {
                        const typeName = getTypeName(texture.category, texture.type);
                        if (tmpData[typeName]) {
                            tmpData[typeName].push({
                                name: texture.textureName,
                                value: texture.uuid,
                                url: getTextureUrl(texture.url)
                            })
                        } else {
                            tmpData[typeName] = [
                                {
                                    name: texture.textureName,
                                    value: texture.uuid,
                                    url: getTextureUrl(texture.url)
                                }];
                        }
                    });
                    setData(tmpData);
                    if (needUpdateMap) {
                        message.success(`上传成功!`);
                    }
                }
            });
    }

    useEffect(() => {
        generateData();
    }, [projectId])


    const beforeUpload = (file) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            //如果上传的不是图片，则不允许上传
            message.error('只能上传jpg和png图片！');
            return false;
        } else {
            return true;
        }
    }

    const handleChange = (info) => {
        if (info.file.status === 'done') {
            generateData(true);
            setCmOpen(false);
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }


    const onAssembleClick = () => {
        onActiveSubControl(BUILDING_SUB_CONTROL.ADDGROUND.name);
        graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.INIT_GROUND_CTRL).dispatch({ material: selectedMatUuid, useRegion: useRegion })
    }


    const onUploadClick = (e) => {
        e.preventDefault();
        let rect = uploadRef.current.getBoundingClientRect();
        setTop(rect.y + 14);
        setLeft(rect.x + 8);
        setCmOpen(true);
    }



    return (
        // <div>123</div>
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    地面
                </div>
                <div className="detail">

                    <div className="row">
                        <div className="label">地面：</div>
                        <CustomSelect style={{ 'marginTop': "-4px" }} data={data} selectedValue={selectedMatUuid} setSelectedValue={setSelectedMatUuid} />
                        <div ref={uploadRef} className="upload-button" onClick={onUploadClick}><UploadSvg /></div>
                    </div>

                    <div className="row">
                        <div className="label">绘制方式:</div>
                        <RadioGroup
                            dataList={[
                                { label: '面域', value: true },
                                { label: '角点', value: false },
                            ]}
                            selected={useRegion}
                            onChange={(value) => setUseRegion(value)}
                        />
                    </div>

                    <div className="ope-btn">
                        <div className="insert-button" onClick={onAssembleClick}>
                            <div className="icon"><AssembleSvg className="icon-svg" /></div>
                            <div className="text">布置</div>
                        </div>
                    </div>
                </div>
            </div>
            <ContextMenuFC
                visible={cmOpen}
                top={top}
                left={left}
                width={104}
                wrapperClassname='ground-type-menu'
                onCancel={() => { setCmOpen(false) }}
            >
                <Upload name='file'
                    data={{ 'projectUuid': projectId, 'category': TEXTURE_CATEGORY.ground.key, 'type': TEXTURE_CATEGORY.ground.type.soft.key }}
                    showUploadList={false}
                    action={TextureUploadUrl}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}>
                    <div style={uploadButtonStyle}>
                        软质材质
                    </div>
                </Upload>
                <Upload name='file'
                    data={{ 'projectUuid': projectId, 'category': TEXTURE_CATEGORY.ground.key, 'type': TEXTURE_CATEGORY.ground.type.hard.key }}
                    showUploadList={false}
                    action={TextureUploadUrl}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                >
                    <div style={{ ...uploadButtonStyle, "marginTop": "-5px" }}>
                        硬质材质
                    </div>
                </Upload>
            </ContextMenuFC>
        </div >
    )
}