import { AssembleDoorControl } from "@/tool/geo-engine/subcontrol/AssembleDoorControl";
import { AssembleFenceControl } from "@/tool/geo-engine/subcontrol/AssembleFenceControl";
import { AssembleHoleControl } from "@/tool/geo-engine/subcontrol/AssembleHoleControl";
import { AssembleWallControl } from "@/tool/geo-engine/subcontrol/AssembleWallControl";
import { AssembleWindowControl } from "@/tool/geo-engine/subcontrol/AssembleWindowControl";
import { SelectComAxisControl } from "@/tool/geo-engine/subcontrol/SelectComAxisControl";

import { BuildingCopyControl, BuildingMoveControl, EditSDTextControl } from "tnbimbase";

/**
 * 本文件存储了额外在外边注册的二级控制器的名称
 */
export const EXTRA_SUB_CONTROL = {
    ASSEMBLEFENCE: { name: 'ASSEMBLEFENCE', cmd: 'AssembleFenceControl', clazz: AssembleFenceControl },
    ASSEMBLEWALL: { name: 'ASSEMBLEWALL', cmd: 'AssembleWallControl', clazz: AssembleWallControl },
    ASSEMBLEHOLE: { name: 'ASSEMBLEHOLE', cmd: 'AssembleHoleControl', clazz: AssembleHoleControl },
    ASSEMBLEDOOR: { name: 'ASSEMBLEDOOR', cmd: 'AssembleDoorControl', clazz: AssembleDoorControl },
    ASSEMBLEWINDOW: { name: 'ASSEMBLEWINDOW', cmd: 'AssembleWindowControl', clazz: AssembleWindowControl },
    SELECTCOMAXIS: { name: 'SELECTCOMAXIS', cmd: 'SelectComAxisControl', clazz: SelectComAxisControl },


    COPY: { name: 'COPY', cmd: 'CopyControl', clazz: BuildingCopyControl },
    MOVE: { name: 'MOVE', cmd: 'MoveControl', clazz: BuildingMoveControl },
    EDIT: { name: 'EDIT', cmd: 'EditDTextControl', clazz: EditSDTextControl },
}