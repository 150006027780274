/**
 * 用以验证antd输入的字符一定是浮点数字符  
 * 输入输出示例  
 * 1.041  => 1.041  
 * -1.30 => -1.30  
 * ---2.41 => -2.41  
 * ANF-3 => 3  
 * ANF-3.4GF5 => 3.45  
 * AIEGHQING => 空字符串  
 */
export function floatVerification(value: string) {
    if (!value) return ''
    //按照.对字符串进行分割
    const tempArr = value.split('.');
    let minus = "";
    //如果不是空字符串，且第一位为“-”，说明输入的浮点数为负数，在之后的结果中加
    if (value?.length > 0 && value[0] == "-") minus = "-";
    if (tempArr.length >= 2) {
        //则将除了[0-9]和.号的其他字符全部替换为空字符
        return minus + `${tempArr[0]}.${tempArr[1]}`.replace(/([^\d{1,}\.\d{1,}|\d{1,}])|,+/g, '');
    } else {
        return minus + value.replace(/([^\d{1,}\.\d{1,}|\d{1,}])|,+/g, '');
    }
}


/**
 * 用以验证antd输入的字符一定是非负整数字符  
 * 输入输出示例  
 * 1.041  => 1041  
 * -1.30 => 130  
 * ---2.41 => 2.41  
 * ANF-3 => 3  
 * ANF-3.4GF5 => 3.45  
 * AIEGHQING => 空字符串  
 */
export function nonNegativeIntegerVerification(value: string): string {
    if (!value) return '';
    // 将除了 [0-9] 的其他字符全部替换为空字符
    return value.replace(/[^\d]/g, '');
}