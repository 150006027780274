import { useState } from "react"
import { ReactComponent as AddSvg } from "@/commons/icons/panel-icon/add-black.svg";
import { ReactComponent as AddHoverSvg } from "@/commons/icons/panel-icon/add-white.svg";

import { ReactComponent as DeleteSvg } from "@/commons/icons/panel-icon/delete-black.svg";
import { ReactComponent as DeleteHoverSvg } from "@/commons/icons/panel-icon/delete-white.svg";

import { ReactComponent as ComfirmSvg } from "@/commons/icons/panel-icon/confirm-black.svg";
import { ReactComponent as ConfirmHoverSvg } from "@/commons/icons/panel-icon/confirm-white.svg";

import "./index.less"
import { SideBarBtnType } from "@/commons/enums/side-bar-btn-type";
import { LoadingOutlined } from '@ant-design/icons';

interface Iprops {
    // 根据SideBarBtnType传入list
    activeBtnList: number[],
    onDeleteBtnClick?: () => void,
    onAddBtnClick?: () => void,
    onConfirmBtnClick?: () => void,
    loading?: boolean,
}

export default function SideBarTool(prop: Iprops) {
    const [addSvgState, setAddSvgState] = useState<boolean>(false)
    const [deleteSvgState, setDeleteSvgState] = useState<boolean>(false)
    const [confirmSvgState, setConfirmSvgState] = useState<boolean>(false)

    return (
        <div className="side-bar-tool-main">
            <div className="head-tool">
                {prop.activeBtnList.find(item => item == SideBarBtnType.ADD) &&
                    <div className="tool-btn"
                        onMouseLeave={() => { setAddSvgState(false) }}
                        onMouseEnter={() => { setAddSvgState(true) }}
                        onClick={() => { prop?.onAddBtnClick() }}
                    >
                        {!prop.loading && addSvgState && <AddHoverSvg className="svg" />}
                        {!prop.loading && !addSvgState && <AddSvg className="svg" />}
                        {prop.loading && <LoadingOutlined className="svg" />}
                    </div>
                }
                {prop.activeBtnList.find(item => item == SideBarBtnType.DELETE) &&
                    <div className="tool-btn"
                        onMouseLeave={() => { setDeleteSvgState(false) }}
                        onMouseEnter={() => { setDeleteSvgState(true) }}
                        onClick={() => { prop?.onDeleteBtnClick() }}
                    >
                        {!prop.loading && deleteSvgState && <DeleteHoverSvg className="svg" />}
                        {!prop.loading && !deleteSvgState && <DeleteSvg className="svg" />}
                        {prop.loading && <LoadingOutlined className="svg" />}
                    </div>
                }
                {prop.activeBtnList.find(item => item == SideBarBtnType.COMFIRM) &&
                    <div className="tool-btn"
                        onMouseLeave={() => { setConfirmSvgState(false) }}
                        onMouseEnter={() => { setConfirmSvgState(true) }}
                        onClick={() => { prop?.onConfirmBtnClick() }}
                    >
                        {!prop.loading && confirmSvgState && <ConfirmHoverSvg className="svg" />}
                        {!prop.loading && !confirmSvgState && <ComfirmSvg className="svg" />}
                        {prop.loading && <LoadingOutlined className="svg" />}
                    </div>
                }


            </div>
        </div>
    )
}