import SystemManagementArea from "@/commons/components/system-management-area/system-management-area";
import "./index.less";
import DrawPanel from "@/panel/draw";
import { graphicStore } from "@/commons/store/graphic-store";
import SideBar from "./side";
import StmodeInput from "@/panel/stmode-input";
import SystemSwitch from "@/panel/system-switch";
import MenuBar from "./menu";
import OperationBar from "@/commons/components/operation-bar";
import { DymodeInput } from "pytha-ui";


export default function ProjectLayout() {
    const sideBarWidth = 258;

    return (
        <div className="bim-project-container">
            <SystemManagementArea />
            <div className="menu">
                <MenuBar></MenuBar>
            </div>
            <div className="main">
                <div className="side-bar" style={{ width: sideBarWidth }}>
                    <SideBar
                        width={sideBarWidth}
                    />
                </div>
                <div className="draw">
                    <OperationBar />
                    <DrawPanel />
                    <DymodeInput
                        context={graphicStore.context}
                        extraContext={graphicStore.extraContext}
                        leftOffset={20}
                        topOffset={20}
                    />
                </div>
            </div>
            <div className="bottom">
                <StmodeInput width={sideBarWidth} />
                <SystemSwitch />
            </div>
        </div>
    )
}
