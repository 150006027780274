import './index.less';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import localStore from '@/commons/store/local-store';
import { graphicStore } from '@/commons/store/graphic-store';
import { TnEngineContext, TnEngineExtraContext, AxisT, ttf, font, ModelBase } from "pytha";
import { cmdRegister } from './cmd-register';
import { AmbientLight, Color, DirectionalLight, Mesh, MeshBasicMaterial, PointLight, Scene, SphereGeometry } from 'three';
import Viewport from './viewport';
import genUUID from '@/commons/utils/gen-uuid';
import { MaskLoadingModal } from 'tncet-common';
import axios from 'axios';
import { SystemContext } from '@/App';
import { SystemStatus } from '@/commons/context/system-store';
import { Ucs } from '@/commons/interface/ucs';
import { findGlobalUcs, findUcsInProject } from '@/api/geometry/ucs';
import { findLayersInProjectUuid } from '@/api/geometry/layer';
import { Layer } from '@/commons/interface/layer';
import { findFontGlyphs, findFontTtfs, findFontsInGraphic } from '@/api/geometry/font';
import { Primitive } from '@/commons/interface/primitive';
import { findPrimitivesInGraphicByTypes } from '@/api/geometry/primitive';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WsConfig } from '@/config/ws';
import { initProject } from '@/api/cooperate/project';
import { getTextureUrl, getTextures } from '@/api/spec-common/texture';
import { Texture } from '@/commons/interface/texture';
import { BimBaseContext, ModelsInBimCommon, ModelsInBimStructure, ModelsInBuilding, ModelsInFire } from 'tnbimbase';


export default function DrawPanel() {
    const { projectId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTip, setLoadingTip] = useState<string>('');
    const [sceneUpdate, setSceneUpdate] = useState<string>(''); // 场景更新的信号
    const systemContext = useContext<SystemStatus>(SystemContext);
    const drawRef = useRef(null);

    if (graphicStore.context == null) {
        graphicStore.context = TnEngineContext.getInstance();
    }

    if (graphicStore.extraContext == null) {
        graphicStore.extraContext = TnEngineExtraContext.getInstance();
    }

    if (graphicStore.bimContext == null) {
        graphicStore.bimContext = BimBaseContext.getInstance();
    }

    const editor = graphicStore.extraContext.getCurrentViewEditor();
    // const [block, setBlock] = useState<Block>();
    const initScene = () => {
        return new Promise((resolve, reject) => {
            if (graphicStore.extraContext.sceneContext.scene == null) {
                let scene = new Scene();

                // 在初始化部分添加光源
                // 添加光照
                const ambientLight = new AmbientLight(0xffffff, 1);
                scene.add(ambientLight);

                const directionalLight = new DirectionalLight(0xffffff, 0.8);
                directionalLight.position.set(1, 1, 1).normalize();
                directionalLight.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight);

                const directionalLight1 = new DirectionalLight(0xffffff, 0.6);
                directionalLight1.position.set(-1, 1, 1).normalize();
                directionalLight1.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight1);

                // const directionalLight2 = new DirectionalLight(0xffffff, 0.7);
                // directionalLight2.position.set(1, 3, 1).normalize();
                // directionalLight2.castShadow = true; // 使光源投射阴影
                // scene.add(directionalLight2);
                const sphere = new SphereGeometry(0.5, 16, 8);
                const pointLight1 = new PointLight(0xffaa00, 4000, 1000000)
                pointLight1.position.set(0, 0, 0)
                pointLight1.add(new Mesh(sphere, new MeshBasicMaterial({ color: 0xff0040 })));
                scene.add(pointLight1)

                // // 创建一个光源帮助器（可选）
                // const pointLightHelper = new PointLightHelper(pointLight1, 0.5);
                // scene.add(pointLightHelper);

                graphicStore.extraContext.sceneContext.scene = scene;

                setSceneUpdate(genUUID());
                resolve(1);
            }
        })
    }

    useEffect(() => {
        // 将命令api注册到图形库中
        cmdRegister();
    }, [])

    useEffect(() => {
        if (!projectId) {
            localStore.projectId = null;
            return () => { };
        }
        localStore.projectId = projectId;
        initScene();
        document.oncontextmenu = () => {
            return false;
        }
        if (graphicStore?.context) {
            Object.keys(ModelsInBimCommon).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBimCommon[type]);
            })
            Object.keys(ModelsInBimStructure).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBimStructure[type]);
            })
            Object.keys(ModelsInBuilding).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBuilding[type]);
            })
            Object.keys(ModelsInFire).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInFire[type]);
            })
        }
        // 模型变化，更新数据
        generateData();
        window.addEventListener('resize', onWindowResize);
        return (() => {
            window.removeEventListener('resize', onWindowResize);
            graphicStore.extraContext.sceneContext.scene.clear();
            graphicStore.extraContext.sceneContext.scene = null;
        })
    }, [projectId, systemContext.updateDrawPanel])

    const onWindowResize = () => {
        graphicStore.extraContext.listeners?.signals.windowResize.dispatch();
    }

    const generateData = () => {
        // TODO 缺少登录，暂时写死，后续添加登录后删除
        // localStore.userId = genUUID();
        if (!projectId) return;
        setLoading(true);
        // 设置世界坐标的默认颜色
        graphicStore.context.renderContext.axisColor.set(new Color(0, 0, 0))
        // 设置鼠标默认颜色
        graphicStore.context.renderContext.cusorColor.set(new Color(0, 0, 1))
        initProjectInfo().then((_) => {
            return initUcs();
        }).then((_) => {
            return initLayers();
        }).then((_) => {
            return initFonts();
        }).then((_) => {
            return initTexture();
        }).then((_) => {
            return initModels();
        }).then((_) => {
            return initWs();
        }).finally(() => {
            graphicStore.extraContext.getCurrentViewEditor()?.showAllEntities(true);
            // TODO 全局设置
            graphicStore.context.renderContext.gridLineDistanceToCircle = 3000;
            graphicStore.context.renderContext.textHeight = 300;
            graphicStore.extraContext.listeners.signals.onEditStatusChanged.dispatch();
            setLoading(false);
        })

    }

    const initProjectInfo = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载项目');
            initProject(projectId).then(res => {
                if (res.status === 200) {
                    localStore.projectId = projectId;
                    resolve(1);
                }
            })
        })

    }

    const initUcs = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载坐标系');
            findUcsInProject(projectId).then(res => {
                let jsonList: Ucs[] = res.data || [];
                let ucsList = [];
                jsonList.forEach((json) => {
                    if (json.isWcs) return;
                    let ucs: AxisT = new AxisT().fromJson(json);
                    ucsList.push(ucs);
                });
                findGlobalUcs(projectId).then(res => {
                    let json = res.data;
                    let wcs = new AxisT().fromJson(json);
                    if (wcs != null) {
                        ucsList.push(wcs);
                    }
                    if (wcs == null && ucsList.length > 0) wcs = ucsList[0];
                    graphicStore.context.ucsContext.setUcsList(ucsList);
                    graphicStore.context.ucsContext.setCurrentUcs(wcs);
                    if (wcs != null) {
                        let viewObj = wcs.render();
                        graphicStore.extraContext.sceneContext.scene?.add(viewObj);
                    }
                    resolve(1);
                })
            })
        })
    }

    const initLayers = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载图层');
            findLayersInProjectUuid(projectId).then(res => {
                let layers: Layer[] = res.data || [];
                layers.forEach((layer) => {
                    layer.lock = false;
                    layer.hidden = false;
                });
                let originLayer = layers.find((layer) => layer.originLayer && layer.projectUuid == projectId);
                if (originLayer == null) originLayer = layers[0];
                if (originLayer != null) {
                    graphicStore.context.layerContext.setCurrentLayerUuid(originLayer.uuid);
                    graphicStore.context.layerContext.setCurrentLayerColor(originLayer.color);
                    graphicStore.context.layerContext.setCurrentColor(originLayer.color);
                }
                graphicStore.context.layerContext.setLayers([...layers]);
                resolve(1);
            })
        });
    };

    const initFonts = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载文字样式');
            // 初始化时加载标注所用字体及默认字体
            if (!!graphicStore.extraContext.fontContext.state.dimFontData && !!graphicStore.extraContext.fontContext.state.currentFont) {
                resolve(1);
            }
            let ttfPromise = findFontTtfs();
            let fontPromise = findFontsInGraphic(projectId);
            // 加载0~9和.所需要的glyphs
            let params = {
                unicodeFile: "times.ttf",
                rangeStart: 0,
                rangeEnd: 1919,
                bakFile: "stsong.ttf",
                text: '0123456789. \\:;/*=+-_abcdefghigklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
            };
            let glyphPromise = findFontGlyphs(params);
            axios.all([ttfPromise, fontPromise, glyphPromise]).then((res) => {
                let ttfList: ttf[] = res[0].data || [];
                graphicStore.extraContext.fontContext.generateTtfNameList(ttfList);
                let fontList: font[] = res[1].data || [];
                let defaultFont;
                fontList.forEach((font) => {
                    let ttf = ttfList.find(
                        (item) => item.name === font.fontname && item.style === font.style
                    );
                    font.ttf = ttf;
                    if (font.originFont) {
                        defaultFont = font;
                    }
                });
                graphicStore.extraContext.fontContext.setFontList(fontList);
                if (!!defaultFont) {
                    graphicStore.extraContext.fontContext.setCurrentFont(defaultFont);
                }
                graphicStore.extraContext.fontContext.setDimFontData(res[2].data);
                resolve(1);
            });
        });
    }

    const initTexture = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载纹理/材质');
            getTextures(projectId).then(res => {
                let textures: Texture[] = res.data || [];
                textures.forEach(texture => {
                    if (texture && texture.uuid && texture.url) {
                        let imageUrl = getTextureUrl(texture.url)
                        graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = imageUrl;
                        // convertImageToBase64(imageUrl).then(res => {
                        //     graphicStore.extraContext.materialContext.materialBase64Map[texture.uuid] = res
                        // })
                    }
                })
                resolve(1);
            })
        });
    }
    // TODO: 根据url获取base64，待解除 
    // async function convertImageToBase64(url) {
    //     let response = await fetch(url)
    //     let blob = await response.blob()
    //     return new Promise((resolve, reject) => {
    //         let reader = new FileReader()
    //         reader.onloadend = () => {
    //             let res = reader.result
    //             resolve(res)
    //         }
    //         reader.onerror = reject;
    //         reader.readAsDataURL(blob);
    //     })
    // }

    const initWs = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在建立通信同步');
            setTimeout(() => {
                // 用户uuid
                const uuid = localStore.userId;
                let rws = new ReconnectingWebSocket(
                    `${WsConfig.address}:${WsConfig.port}/api-asset-bim/ws/bim?uuid=${uuid}&projectUuid=${projectId}`
                );
                systemContext.resetRws(rws);
                resolve(1);
            }, 500);
        });
    };


    useEffect(() => {
        if (!systemContext.rws) return;
        systemContext.rws.onmessage = (value) => {
            const body = JSON.parse(value.data);
            switch (body.type) {
                case "POST":
                    let json = body.data;
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.type))();
                    entity.fromJson(json);
                    editor.addObject(entity);
                    editor.listeners.signals.needRender.dispatch("redraw");
                    break;
                case "MULTI_POST":
                    let jsonList: any[] = body.data;
                    jsonList?.forEach((json) => {
                        let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.type))();
                        entity.fromJson(json);
                        editor.addObject(entity);
                    });
                    editor.listeners.signals.needRender.dispatch("redraw");
                    break;
                case "DELETE":
                    let uuids: string[] = body.data;
                    uuids.forEach((uuid) => {
                        editor.removeObjectByUuid(uuid);
                    });
                    editor.listeners.signals.needRender.dispatch("redraw");
                    break;
                case "LAYER_POST":
                    let layer: Layer = body.data;
                    let item = graphicStore.context.layerContext.layers.find(item => item.uuid === layer.uuid);
                    if (!item) {
                        layer = {
                            ...layer,
                            lock: false,
                            hidden: false,
                            originLayer: false,
                        };
                        graphicStore.context.layerContext.addLayer(layer);
                    } else {
                        item.color = layer.color;
                        item.lineWidth = layer.lineWidth;
                        item.name = layer.name;
                        graphicStore.context.layerContext.flush();
                        let entities = editor.getEntities()?.filter(e => e.layerUuid === layer.uuid && e.isColorByLayer);
                        entities.forEach(e => {
                            let newE = editor.entityDict[e.uuid];
                            newE.changeColor(layer.color, true);
                        })
                        editor.listeners.signals.needRender.dispatch("redraw");
                    }
                    break;
                case "LAYER_DELETE":
                    let deleteLayer: Layer = body.data;
                    graphicStore.context.layerContext.deleteLayer(deleteLayer);
                    break;
                case "UCS_POST":
                    let ucsJson = body.data;
                    let ucs: AxisT = new AxisT().fromJson(ucsJson);
                    let tmp: AxisT = graphicStore.context.ucsContext.ucsList.find(item => item.uuid === ucs.uuid);
                    if (tmp != null) {
                        tmp.fromJson(ucsJson);
                        tmp.updateViewGeometry();
                    } else {
                        graphicStore.context.ucsContext.addUcs(ucs, false);
                    }
                    editor.listeners.signals.needRender.dispatch();
                    break;
                case "UCS_DELETE":
                    let deleteUcsJson = body.data;
                    graphicStore.context.ucsContext.removeUcsByUuid(deleteUcsJson.uuid);
                    break;
            }
        }
    }, [systemContext.rws])


    const initModels = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载模型');

            //将tnbimbase项目中的额外图元注册进去
            if (graphicStore.context) {
                Object.keys(ModelsInBimCommon).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBimCommon[type]);
                })
                Object.keys(ModelsInBimStructure).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBimStructure[type]);
                })
                Object.keys(ModelsInBuilding).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBuilding[type]);
                })
                Object.keys(ModelsInFire).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInFire[type]);
                })
            };
            findPrimitivesInGraphicByTypes(projectId, { types: ['HoleT', 'DoorT', 'WindowT'] }).then((res) => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    let jsonList: Primitive[] = res.data || [];
                    let entities = jsonList.map((json: Primitive) => {
                        let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.properties.type))();
                        entity.fromJson(json.properties);
                        entity.opacity = 0.5;
                        return entity;
                    });
                    graphicStore.extraContext.getCurrentViewEditor().entityDict = {};
                    graphicStore.extraContext.getCurrentViewEditor().addObjects(entities);
                }
                return findPrimitivesInGraphicByTypes(projectId, { notTypes: ['HoleT', 'DoorT', 'WindowT'] })
            }).then((res) => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    let jsonList: Primitive[] = res.data || [];
                    let entities = jsonList.map((json: Primitive) => {
                        let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.properties.type))();
                        entity.fromJson(json.properties);
                        return entity;
                    });
                    graphicStore.extraContext.getCurrentViewEditor().addObjects(entities);
                }
                resolve(1);
            });
        });
    }


    return (
        <div className="draw-panel" ref={drawRef}>
            <div className='viewport-item'>
                {/* todo 只能用"viewport"，pythagoras库里model都用的viewport取得element，model修改后这里可以修改viewportId */}
                <Viewport sceneUpdate={sceneUpdate} viewportId='viewport' viewHelperId='viewHelper-1' cameraType={systemContext.currentCameraType} />
            </div>
            <MaskLoadingModal
                visible={loading}
                height='70px'
                loadingTip={loadingTip}
            />
        </div>
    )
}