import SideBarTool from "@/commons/components/side-bar-tool";
import { Input, message } from "antd";
import { useEffect, useState } from "react";
import './index.less';
import { ExtrudeSolidT, Line, LineType, Point, Polygon, RenderMode } from "pytha";
import { Vector3 } from "three";
import { WindowT } from "tnbimbase";
import { graphicStore } from "@/commons/store/graphic-store";
import { LayerType } from "@/commons/enums/layer-type";
import genUUID from "@/commons/utils/gen-uuid";
import { Layer } from "@/commons/interface/layer";
import { useParams } from "react-router-dom";
import { saveLayer } from "@/api/geometry/layer";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import LocalScene from "@/commons/components/local-scene";
import { getTextures } from "@/api/spec-common/texture";
import { TEXTURE_CATEGORY } from "@/commons/enums/texture";
import { Texture } from "@/commons/interface/texture";

export default function WindowPanel() {
    const { projectId } = useParams();
    const [width, setWidth] = useState<number>(3600); // 窗宽
    const [height, setHeight] = useState<number>(2300); // 窗高
    const [horizontalSpace, setHorizontalSpace] = useState<string>('600,1200*2,600'); // 横向分割
    const [verticalSpace, setVerticalSpace] = useState<string>('500,1800'); // 纵向分割
    const [entities, setEntities] = useState<any[]>([]);
    const [aluminiumAlloyTexture, setAluminiumAlloyTexture] = useState<string>(''); // 铝合金材质
    const [glassTexture, setGlassTexture] = useState<string>(''); // 玻璃材质

    useEffect(() => {
        // 查询铝合金和玻璃的材质
        getTextures(projectId, { category: TEXTURE_CATEGORY.aluminiumAlloy.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setAluminiumAlloyTexture(resData[0].uuid);
            }
        })
        getTextures(projectId, { category: TEXTURE_CATEGORY.glass.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setGlassTexture(resData[0].uuid);
            }
        })
    }, [])

    // 网格修改失焦
    const gridBlur = (field: string) => {
        let value: string = field == "horizontal" ? horizontalSpace : verticalSpace;
        if (!value) {
            return;
        }
        // 只保留数字、逗号和星号
        let newValue = value?.replace("，", ',').replace(/[^0-9,*]/g, '');
        if (newValue.endsWith(',')) {
            newValue = newValue.slice(0, -1);
        }
        if (field == "horizontal") {
            setHorizontalSpace(newValue);
            let horizontalArr: number[] = parseNumberArrAccumulate(newValue);
            // 横向长度，即窗宽
            let totalHorizontal: number = horizontalArr?.length > 0 ? horizontalArr[horizontalArr?.length - 1] : 0;
            setWidth(totalHorizontal);
        } else {
            setVerticalSpace(newValue);
            let verticalArr: number[] = parseNumberArrAccumulate(newValue);
            // 纵向长度，即窗高
            let totalVertical: number = verticalArr?.length > 0 ? verticalArr[verticalArr?.length - 1] : 0;
            setHeight(totalVertical);
        }
    }

    useEffect(() => {
        updateScene();
    }, [horizontalSpace, verticalSpace])

    const updateScene = () => {
        // 判断横向分割
        if (horizontalSpace == undefined || horizontalSpace === '' || horizontalSpace?.trim() === '') {
            setEntities([]);
            return null;
        }
        // 判断纵向分割
        if (verticalSpace == undefined || verticalSpace === '' || verticalSpace?.trim() === '') {
            setEntities([]);
            return null;
        }
        let horizontalArr: number[] = parseNumberArrAccumulate(horizontalSpace);
        let verticalArr: number[] = parseNumberArrAccumulate(verticalSpace);
        let width: number = horizontalArr?.length > 0 ? horizontalArr[horizontalArr?.length - 1] : 0;
        let height: number = verticalArr?.length > 0 ? verticalArr[verticalArr?.length - 1] : 0;
        if (width == 0) {
            // 窗宽为0
            setEntities([]);
            return null;
        }
        if (height == 0) {
            // 窗高为0
            setEntities([]);
            return null;
        }
        // 构造窗图元
        // 窗框，窗框宽50mm，深100mm
        let frameWidth = 50;
        let frameDeep = 100;
        let direction = new Vector3(0, 0, -1);
        let outFramePolygon = new Polygon([], new Vector3(1, 0, 0), new Point(0, 0, 0));
        let outFramePoints: Point[] = [
            new Point(0, 0, 0),
            new Point(0, height + frameWidth * 2, 0),
            new Point(width + frameWidth * 2, height + frameWidth * 2, 0),
            new Point(width + frameWidth * 2, 0, 0),
        ]
        let outCurves = [];
        for (let i = 0; i < outFramePoints.length; i++) {
            if (i != outFramePoints.length - 1) {
                outCurves.push(new Line(outFramePoints[i], outFramePoints[i + 1]));
            } else {
                outCurves.push(new Line(outFramePoints[i], outFramePoints[0]));
            }
        }
        outFramePolygon.set(outCurves);
        let inFramePoints: Point[] = [
            new Point(frameWidth, frameWidth, 0),
            new Point(width + frameWidth, frameWidth, 0),
            new Point(width + frameWidth, height + frameWidth, 0),
            new Point(frameWidth, height + frameWidth, 0),
        ]
        let inCurves = [];
        for (let i = 0; i < inFramePoints.length; i++) {
            if (i != inFramePoints.length - 1) {
                inCurves.push(new Line(inFramePoints[i], inFramePoints[i + 1]));
            } else {
                inCurves.push(new Line(inFramePoints[i], inFramePoints[0]));
            }
        }
        let hole = new Polygon(inCurves, new Vector3(1, 0, 0));
        outFramePolygon.holes = [hole];
        let frameSolid = new ExtrudeSolidT(outFramePolygon, direction, frameDeep);
        // 赋铝合金的材质
        if (aluminiumAlloyTexture) {
            frameSolid.materialMap = { [aluminiumAlloyTexture]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] };
            frameSolid.needUpdate = true;
            frameSolid.needTextureMaterial = true;
        }
        let windowEntities = [];
        windowEntities.push(frameSolid);

        let baseX: number = Number(frameWidth);
        let baseY: number = Number(frameWidth);
        let baseZ: number = Number(0);

        let heightY = baseY + height;

        // 窗的纵分割杆，根据横向分割生成
        if (horizontalArr?.length > 1) {
            for (let i = 0; i < horizontalArr.length - 1; i++) {
                let item = horizontalArr[i];
                let leftBottom = new Vector3(baseX + item - frameWidth / 2, baseY, baseZ);
                let leftTop = new Vector3(baseX + item - frameWidth / 2, heightY, baseZ);
                let rightTop = new Vector3(baseX + item + frameWidth / 2, heightY, baseZ);
                let rightBottom = new Vector3(baseX + item + frameWidth / 2, baseY, baseZ);
                let solid = new ExtrudeSolidT(new Polygon([
                    new Line(leftBottom, leftTop),
                    new Line(leftTop, rightTop),
                    new Line(rightTop, rightBottom),
                    new Line(rightBottom, leftBottom),
                ], new Vector3(1, 0, 0), new Point(leftBottom.x, leftBottom.y, leftBottom.z)), direction, frameDeep);
                // 赋铝合金的材质
                if (aluminiumAlloyTexture) {
                    solid.materialMap = { [aluminiumAlloyTexture]: [0, 1, 2, 3, 4, 5] };
                    solid.needUpdate = true;
                    solid.needTextureMaterial = true;
                }
                windowEntities.push(solid);
            }
        }

        let widthX = baseX + width;

        // 窗的横分割杆，根据纵向分割生成
        if (verticalArr?.length > 1) {
            for (let i = 0; i < verticalArr.length - 1; i++) {
                let item = verticalArr[i];
                let leftBottom = new Vector3(baseX, heightY - item - frameWidth / 2, baseZ);
                let leftTop = new Vector3(baseX, heightY - item + frameWidth / 2, baseZ);
                let rightTop = new Vector3(widthX, heightY - item + frameWidth / 2, baseZ);
                let rightBottom = new Vector3(widthX, heightY - item - frameWidth / 2, baseZ);
                let solid = new ExtrudeSolidT(new Polygon([
                    new Line(leftBottom, leftTop),
                    new Line(leftTop, rightTop),
                    new Line(rightTop, rightBottom),
                    new Line(rightBottom, leftBottom),
                ], new Vector3(1, 0, 0), new Point(leftBottom.x, leftBottom.y, leftBottom.z)), direction, frameDeep);
                // 赋铝合金的材质
                if (aluminiumAlloyTexture) {
                    solid.materialMap = { [aluminiumAlloyTexture]: [0, 1, 2, 3, 4, 5] };
                    solid.needUpdate = true;
                    solid.needTextureMaterial = true;
                }
                windowEntities.push(solid);
            }
        }

        // 窗，窗厚20mm
        let windowDeep = 20;
        let windowPolygon = new Polygon([], new Vector3(1, 0, 0), new Point(frameWidth, (frameDeep - windowDeep) / 2, frameWidth));
        let windowPoints: Point[] = [
            new Point(frameWidth, frameWidth, 0),
            new Point(frameWidth, height + frameWidth, 0),
            new Point(width + frameWidth, height + frameWidth, 0),
            new Point(width + frameWidth, frameWidth, 0),
        ]
        let windowCurves = [];
        for (let i = 0; i < windowPoints.length; i++) {
            if (i != windowPoints.length - 1) {
                windowCurves.push(new Line(windowPoints[i], windowPoints[i + 1]));
            } else {
                windowCurves.push(new Line(windowPoints[i], windowPoints[0]));
            }
        }
        windowPolygon.set(windowCurves);
        let windowSolid = new ExtrudeSolidT(windowPolygon, direction, windowDeep);
        // 赋玻璃的材质
        if (glassTexture) {
            windowSolid.materialMap = { [glassTexture]: [0, 1] };
            windowSolid.needUpdate = true;
            windowSolid.needTextureMaterial = true;
        }
        windowEntities.push(windowSolid);
        setEntities(windowEntities);
        return windowEntities;
    }

    // 将数组累加
    const parseNumberArrAccumulate = (str: string) => {
        let parsedArray = parseStr2NumberArr(str);
        return parsedArray?.reduce((accumulator, currentValue, index) => {
            if (index === 0) {
                accumulator.push(currentValue);
            } else {
                const accumulatedValue = accumulator[index - 1] + currentValue;
                accumulator.push(accumulatedValue);
            }
            return accumulator;
        }, []);
    }

    // 将网格数据转为可编辑数据
    const parseStr2NumberArr = (str: string) => {
        if (!str) {
            return [];
        }
        const parts = str.split(',');

        try {
            const result = parts.flatMap((part) => {
                const multiplicationParts = part.split('*');
                if (multiplicationParts.length === 2) {
                    const number = parseInt(multiplicationParts[0], 10);
                    const count = parseInt(multiplicationParts[1], 10);

                    if (!isNaN(number) && !isNaN(count)) {
                        return Array.from({ length: count }, () => number);
                    }
                } else {
                    const number = parseInt(part, 10);
                    if (!isNaN(number)) {
                        return [number];
                    }
                }

                return [];
            });

            return result;
        } catch (error) {
            return [];
        }
    }

    const onAssembleWindow = () => {
        // 判断横向分割
        if (horizontalSpace == undefined || horizontalSpace === '' || horizontalSpace?.trim() === '') {
            message.warning('横向分割不能为空');
            return;
        }
        // 判断纵向分割
        if (verticalSpace == undefined || verticalSpace === '' || verticalSpace?.trim() === '') {
            message.warning('综向分割不能为空');
            return;
        }
        let horizontalArr: number[] = parseNumberArrAccumulate(horizontalSpace);
        let verticalArr: number[] = parseNumberArrAccumulate(verticalSpace);
        let width: number = horizontalArr?.length > 0 ? horizontalArr[horizontalArr?.length - 1] : 0;
        let height: number = verticalArr?.length > 0 ? verticalArr[verticalArr?.length - 1] : 0;
        if (width == 0) {
            // 窗宽为0
            message.warning("窗宽不能为0");
            return;
        }
        if (height == 0) {
            // 窗高为0
            message.warning("窗高不能为0");
            return;
        }
        let entities = updateScene();
        // 窗框，窗框宽50mm，深100mm
        let frameWidth = 50;
        let totalWidth = frameWidth + width + frameWidth;
        let totalHeight = frameWidth + height + frameWidth;
        let window = new WindowT(new Point(0, 0, 0), entities, '窗', new Vector3(0, 0, 1), new Vector3(1, 0, 0), totalWidth, totalHeight);
        if (!graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl || graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl.id !== EXTRA_CTRL_TYPE.ASSEMBLE_WINDOW) {
            // 激活布置窗的二级控制器
            graphicStore.extraContext.getCurrentViewEditor().cmdControl.activateSubCommandControl("ASSEMBLEWINDOW");
            let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.WINDOW)?.uuid || null;
            if (!layerUuid) {
                // 不存在窗图层，新建窗图层
                let uuid = genUUID();
                let newLayer: Layer = {
                    uuid: uuid,
                    name: "窗",
                    color: "#c9c9c9",
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    lock: false,
                    hidden: false,
                    projectUuid: projectId,
                    originLayer: false,
                    type: LayerType.WINDOW,
                };
                saveLayer(newLayer).then(res => {
                    if (res.status === 200) {
                        graphicStore.context.layerContext.addLayer(newLayer);
                        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWindow).dispatch(newLayer.uuid, window);
                    }
                }).catch(err => {
                    message.error("无法置为窗图层");
                    console.log(err);
                    let currentLayerUuid = graphicStore.context.layerContext.currentLayerUuid;
                    graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWindow).dispatch(currentLayerUuid, window);

                })
            } else {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleWindow).dispatch(layerUuid, window);
            }
        }
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    窗
                </div>
                <div className="detail window-panel">
                    <div className="title-row">
                        <div className="title-row-text">尺寸</div>
                    </div>
                    <div className="row">
                        <div className="label">窗宽:</div>
                        <Input
                            readOnly
                            className="input-box onlyread"
                            value={width}
                            suffix="mm"
                        />
                    </div>
                    <div className="row">
                        <div className="label">窗高:</div>
                        <Input
                            readOnly
                            className="input-box onlyread"
                            value={height}
                            suffix="mm"
                        />
                    </div>
                    <div className="row">
                        <div className="label">横向分割:</div>
                        <Input
                            className="input-box"
                            value={horizontalSpace}
                            onChange={(e) => setHorizontalSpace(e.target.value)}
                            onBlur={() => gridBlur('horizontal')}
                            onPressEnter={() => gridBlur('horizontal')}
                            suffix="mm"
                        />
                    </div>
                    <div className="row compact">
                        <div className="label">纵向分割:</div>
                        <Input
                            className="input-box"
                            value={verticalSpace}
                            onChange={(e) => setVerticalSpace(e.target.value)}
                            onBlur={() => gridBlur('vertical')}
                            onPressEnter={() => gridBlur('vertical')}
                            suffix="mm"
                        />
                    </div>
                    {/* <div className="schematic-diagram">
                        <Image width={167} height={97} preview src={WindowImg} />
                    </div> */}
                    <div className="show-scene">
                        <LocalScene
                            entities={entities}
                            color="#F9FAFA"
                            react_id={"window-panel-scene"}
                            width={201}
                            height={118}
                            top={1}
                            left={1}
                            onlyRenderType={RenderMode.WIREFRAME}
                        />
                    </div>
                    <div className="ope-area">
                        <div className="primary-btn" onClick={onAssembleWindow}>确定</div>
                        <div className="secondary-btn"><div className="text">取消</div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}