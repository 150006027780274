// 组件类型
export const ComponentType = {
    LAMP: 'street-lamp',                            // 路灯
    FENCEUPRIGHT: 'fence-upright',                  // 围墙立柱
    FENCESOLIDWALL: 'fence-solid-wall',             // 围墙实墙
    FENCEBANISTER: 'fence-banister',                // 围墙栅栏
    PARKINGSPACE: "parking-space",                  // 机动车位
    NOMOTORSPARKINGSPACE: "no-motor-parking-space", // 非机动车位
    SMOKE_DETECTOR: 'smoke-detector',               // 烟感器
    FIRE_HYDRANT: 'fire-hydrant',                   // 消火栓
    SPRAY_HEAD: 'spray-head',                       // 喷淋头
    FIRE_EXTINGUISHER: 'fire-extinguisher',         // 灭火器
    /**
     * 巡更点
     */
    PATROLPOINT: 'patrol-point',
    MONITOR: 'monitor', // 摄像头


    TREE: 'tree-shrubs',// 乔灌木

    DIY: 'diy', // 自定义
}

export const ComponentTypeList = [
    { value: ComponentType.DIY, label: "自定义" },
    { value: ComponentType.LAMP, label: "路灯" },
    { value: ComponentType.FENCEUPRIGHT, label: "围墙立柱" },
    { value: ComponentType.FENCESOLIDWALL, label: "围墙实墙" },
    { value: ComponentType.FENCEBANISTER, label: "围墙栅栏" },
    { value: ComponentType.PARKINGSPACE, label: "机动车位" },
    { value: ComponentType.NOMOTORSPARKINGSPACE, label: "非机动车位" },
    { value: ComponentType.SMOKE_DETECTOR, label: "烟感器" },
    { value: ComponentType.FIRE_HYDRANT, label: "消火栓" },
    { value: ComponentType.SPRAY_HEAD, label: "喷淋头" },
    { value: ComponentType.FIRE_EXTINGUISHER, label: "灭火器" },
    { value: ComponentType.PATROLPOINT, label: "巡更点" },
    { value: ComponentType.MONITOR, label: "摄像头" },
]