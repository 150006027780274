import { useEffect, useRef, useState } from 'react'
import './index.less'
import { Input, InputRef } from 'antd'
import { graphicStore } from '@/commons/store/graphic-store';
import { cmdOption, sysCmd, DYMODE_MODE, dymode } from "pytha";
import classnames from 'classnames';
import { ContextMenuModal } from 'tncet-common';
import { ReactComponent as CancelSvg } from '@/commons/icons/stmode-input/cancel.svg'
import { ReactComponent as ConsoleSvg } from '@/commons/icons/stmode-input/console.svg'
import { ReactComponent as ToolSvg } from '@/commons/icons/stmode-input/tool.svg'
import { ReactComponent as ArrowUpSvg } from '@/commons/icons/stmode-input/arrow-up.svg'
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';
import { LayerType } from '@/commons/enums/layer-type';
import { BIM_SINGAL } from 'tnbimbase';

interface StmodeContent {
    cmd: string,
    tip: string,
    options: cmdOption[],
    logs: string[],
    currentLogVisible: boolean,
}

interface Iprops {
    width: number
}

const canDoWithDisabled: Set<string> = new Set<string>([
    "cm",
    "CM",
    "CHANGEMODELCONTROL",
    "changemodelcontrol",
    "T",
    "t"
]);

export default function StmodeInput(props: Iprops) {
    const stRef = useRef<InputRef>();
    const containerRef = useRef<any>();
    const menuRef = useRef<HTMLDivElement>();
    const [value, setValue] = useState<string>('');
    const [logHistoryMenuVisible, setLogHistoryMenuVisible] = useState<boolean>(false);
    const [logHistoryMenuLeft, setLoglHistoryMenuLeft] = useState<number>(0);
    const [logHistoryMenuWidth, setLoglHistoryMenuWidth] = useState<number>(0);
    const [displayCmdList, setDisplayCmdList] = useState<sysCmd[]>([]);
    const [command, setCommand] = useState<string>('');
    const [context, setContext] = useState<StmodeContent>();
    const [isReplace, setIsReplace] = useState<boolean>(false); //  标识是否是替换中文逗号

    useEffect(() => {
        //判断空值
        if (graphicStore.extraContext && graphicStore.extraContext.stmodeContext) {
            graphicStore.extraContext.stmodeContext.isActiveList = displayCmdList;
        }
    }, [displayCmdList])

    useEffect(() => {
        //判断空值
        if (!graphicStore.extraContext) {
            return () => { };
        }
        graphicStore.extraContext.listeners.signals.onStmodeInput.add(onStmodeInput);
        graphicStore.extraContext.listeners.signals.onProcessEscape.add(onProcessEscape);
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.add(onProcessEscape);
        graphicStore.extraContext.listeners.signals.onListCommand.add(historyMenuVisibleChange);
        graphicStore.extraContext.listeners.signals.onStmodeChanged.add(onStmodeChanged);
        graphicStore.extraContext.listeners.signals.onOpeCommandActive.add(clearCmdList);


        //注册二级控制器
        // Object.keys(EXTRA_SUB_CONTROL).forEach(key => {
        //     graphicStore.extraContext.cmdContext.registerCmd({
        //         name: EXTRA_SUB_CONTROL[key].name,
        //         cmd: EXTRA_SUB_CONTROL[key].cmd
        //     });
        //     graphicStore.extraContext.cmdContext.registerSubCtrl(EXTRA_SUB_CONTROL[key].cmd, EXTRA_SUB_CONTROL[key].clazz);
        // })

        //注册额外信号
        Object.keys(EXTRA_SINGAL).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(EXTRA_SINGAL[key]);
        })

        //注册bim-base中的额外信号
        Object.keys(BIM_SINGAL).forEach(key => {
            graphicStore.extraContext.listeners.registerSignal(BIM_SINGAL[key]);
        })

        return (() => {
            if (!graphicStore.extraContext) {
                return;
            }
            graphicStore.extraContext.listeners.signals.onStmodeInput.remove(onStmodeInput);
            graphicStore.extraContext.listeners.signals.onProcessEscape.remove(onProcessEscape);
            graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.remove(onProcessEscape);
            graphicStore.extraContext.listeners.signals.onListCommand.remove(historyMenuVisibleChange);
            graphicStore.extraContext.listeners.signals.onStmodeChanged.remove(onStmodeChanged);
            graphicStore.extraContext.listeners.signals.onOpeCommandActive.remove(clearCmdList);
        })
    }, [])

    const onStmodeChanged = () => {
        let context = graphicStore.extraContext.stmodeContext;
        setContext({
            cmd: context.cmd,
            tip: context.tip,
            options: context.options,
            logs: context.logs,
            currentLogVisible: context.currentLogVisible,
        });
    }

    const clearCmdList = () => {
        setDisplayCmdList([])
    }

    const onStmodeInput = () => {
        stRef.current?.focus();
    }

    const onProcessEscape = () => {
        graphicStore.extraContext.stmodeContext.reset();
        reset();
    }

    const onInputChange = (e) => {
        if (isReplace) {
            setIsReplace(false);
            return;
        }
        // if (!localStore.getAuthority()?.some(item => item === AUTHORITY_TYPE.EDIT_MODEL)) {
        //     message.info("您暂无编辑权限", 1);
        //     return;
        // }
        let value: string = e.target.value;
        if (value.includes('，')) {
            setIsReplace(true);
            value = value.replace(/，/g, ',');
        }
        setValue(value.toUpperCase().trim());
        findCmdList(value.toUpperCase().trim());
    }

    const onInputKeyup = (e) => {
        e.stopPropagation();
        const keyCode = e.keyCode;
        if (keyCode === 188) {
            // ,
            graphicStore.extraContext.dymodeContext.mode = DYMODE_MODE.POINT
        } else if (keyCode === 108 || keyCode === 13 || keyCode === 32 || keyCode === 9) {
            let cmdList = graphicStore.extraContext.cmdContext.getCmdList();
            if (cmdList.find(item => item.name.indexOf(value) === 0)) {
                onDymodeConfirm(value);
            } else {
                setValue("")
            }
        }
    }

    const onDymodeConfirm = (value: string, cmd?: string) => {
        if (graphicStore.extraContext.editStatusContext.disabled && !canDoWithDisabled.has(value)) {
            graphicStore.extraContext.stmodeContext.pushLog("已锁定，无法执行操作！");
            reset();
            graphicStore.extraContext.dymodeContext.enabled = false
            graphicStore.extraContext.getCurrentViewEditor()?.renderer.domElement.focus();
            return;
        }
        let mode = graphicStore.extraContext.dymodeContext.mode;
        let data: dymode = { mode: mode };
        // find option first 
        let option = graphicStore.extraContext.stmodeContext.options.find((item: cmdOption) => item.key === value.toUpperCase().trim());
        if (!!option) {
            data.mode = DYMODE_MODE.CHOOSE;
            mode = DYMODE_MODE.CHOOSE;
        }
        if (mode === DYMODE_MODE.POINT) {
            let point = value.split(',') || [];
            data.x = +point[0] || 0;
            data.y = +point[1] || 0;
            data.z = +point[2] || 0;
            // 坐标转成世界坐标
            let ucs = graphicStore.context.ucsContext.getAxisList();
            // data.x = data.x + ucs.origin.x;
            // data.y = data.y + ucs.origin.y;
            // data.z = data.z + ucs.origin.z;
            let vector = ucs.axisX.clone().multiplyScalar(data.x).add(ucs.axisY.clone().multiplyScalar(data.y)).add(ucs.axisZ.clone().multiplyScalar(data.z));
            let world = ucs.origin.clone().add(vector);
            data.x = world.x;
            data.y = world.y;
            data.z = world.z;
            graphicStore.extraContext.stmodeContext.pushLog(value);
        } else if (mode === DYMODE_MODE.COMMAND) {
            let cmdStr = cmd ? cmd : command;
            data.command = cmdStr.toUpperCase().trim();
            graphicStore.extraContext.stmodeContext.pushLog(data.command);
        } else if (mode === DYMODE_MODE.ANGLE) {
            data.angle = +value || 0;
            graphicStore.extraContext.stmodeContext.pushLog(value);
        } else if (mode === DYMODE_MODE.DIST) {
            data.dist = +value || 0;
            graphicStore.extraContext.stmodeContext.pushLog(value);
        } else if (mode === DYMODE_MODE.NUMBER) {
            data.number = +value || 0;
            graphicStore.extraContext.stmodeContext.pushLog(value);
        } else if (mode === DYMODE_MODE.CHOOSE) {
            data.choose = value.toUpperCase().trim();
            if (!!option) {
                // 输入的选项存在
                graphicStore.extraContext.stmodeContext.pushLog(data.choose);
            } else {
                // 输入的选项不存在
                setValue('');
                return;
            }
        }
        reset();
        let curLayer = graphicStore.context.layerContext.layers.find(item => item.uuid === graphicStore.context.layerContext.currentLayerUuid);
        if (curLayer?.type === LayerType.AXIS_NET && (data.command === "L" || data.command === "LINE")) {
            data.command = "BUILDGRIDLINE";
        }
        graphicStore.extraContext.listeners.signals.onDymodeChanged.dispatch(data);
        graphicStore.extraContext.getCurrentViewEditor()?.renderer.domElement.focus();
    }

    const reset = () => {
        setValue('');
        setDisplayCmdList([]);
    }

    const onItemClick = (value: string) => {
        let data: dymode = {
            mode: DYMODE_MODE.CHOOSE,
            choose: value,
        }
        // TODO: ucs转换
        reset();
        graphicStore.extraContext.listeners.signals.onDymodeChanged.dispatch(data);
        graphicStore.extraContext.getCurrentViewEditor().renderer.domElement.focus();
    }

    const historyMenuVisibleChange = () => {
        let rect = containerRef.current?.getBoundingClientRect();
        setLoglHistoryMenuLeft(rect?.x || 0);
        setLoglHistoryMenuWidth(rect?.width - 10 || 0);
        setLogHistoryMenuVisible(true);
        setTimeout(() => {
            menuRef.current.scrollTop = menuRef.current.scrollHeight - menuRef.current.clientHeight
        }, 0)
    }

    const handleHistoryMenuVisibleChange = (value) => {
        let rect = containerRef.current?.getBoundingClientRect();
        setLoglHistoryMenuLeft(rect?.x || 0);
        setLoglHistoryMenuWidth(rect?.width - 10 || 0);
        setLogHistoryMenuVisible(value);
        if (value) {
            setTimeout(() => {
                menuRef.current.scrollTop = menuRef.current.scrollHeight - menuRef.current.clientHeight
            }, 0)
        }
    }

    const onCmdItemClick = (cmd: sysCmd) => {
        setCommand(cmd.name);
        setValue(cmd.name);
        onDymodeConfirm(cmd.name, cmd.name);
    }

    const findCmdList = (value: string) => {
        if (graphicStore.extraContext.dymodeContext.mode !== DYMODE_MODE.COMMAND) return;
        let cmdString = value;
        let cmdList: sysCmd[] = graphicStore.extraContext.cmdContext.getCmdList();
        let display = cmdList.filter(item => item.name.indexOf(cmdString) === 0) || [];
        let recent = display.find(item => item.name === cmdString)?.name || display.find(item => item.name.indexOf(cmdString) >= 0)?.name || cmdString;
        setCommand(recent);
        setDisplayCmdList(display);
    }

    return (
        <div className="static-input-container" ref={containerRef}>
            <div className='static-tool-bar'>
                <div className='cancel'><CancelSvg /></div>
                <div className='tool'><ToolSvg /></div>
            </div>
            <Input
                value={value}
                onChange={onInputChange}
                onKeyUp={onInputKeyup}
                size="small"
                placeholder="键入命令"
                autoComplete="off"
                ref={stRef}
                suffix={
                    <ArrowUpSvg style={{ cursor: 'pointer' }} onClick={() => handleHistoryMenuVisibleChange(true)} />
                }
                prefix={
                    <div className="static-prefix">
                        <ConsoleSvg />
                        <div className="tip-cmd">{context?.cmd}</div>
                        <div>{context?.tip}</div>
                        <div className={classnames({
                            "cmd-options": true,
                            "active": context?.options.length > 0
                        })}>
                            {context?.options.map((option: cmdOption, idx) => (
                                <div className="cmd-options-item" key={idx} onClick={() => onItemClick(option.key)}>
                                    <span>{option.name}</span>
                                    (<span className="key">{option.key}</span>)
                                </div>
                            ))}
                        </div>
                        {context?.tip && (<span>: </span>)}
                    </div>
                } />

            {context?.logs.slice(-3).reverse().map((log, idx) => (
                <div key={idx}
                    className={`log-history-tag log-history-tag-${idx} ${context?.currentLogVisible && 'visible'}`}
                    style={{ marginLeft: props.width >= 248 ? props.width - 248 : 0 }}
                >
                    {log}
                </div>
            ))}

            <div className={classnames({
                "stmode-command-list": true,
                'visible': displayCmdList.length > 0,
            })}
                style={{ marginLeft: props.width >= 248 ? props.width - 248 : 0 }}
            >
                {displayCmdList.map((cmd, idx) => (
                    <div key={idx} className={classnames({
                        "cmd-item": true,
                        'active': command === cmd.name && graphicStore.extraContext.dymodeContext.mode === DYMODE_MODE.COMMAND
                    })} onClick={() => onCmdItemClick(cmd)}>
                        <span>{cmd.name}</span>
                        {cmd.alias && (
                            <span className="cmd-alias"> ({cmd.alias}) </span>
                        )}
                    </div>
                ))}
            </div>

            <ContextMenuModal
                visible={logHistoryMenuVisible}
                bottom={28}
                left={logHistoryMenuLeft}
                width={logHistoryMenuWidth}
                mask
                onCancel={() => handleHistoryMenuVisibleChange(false)}
                onRightClick={() => handleHistoryMenuVisibleChange(false)}
                onEscape={() => {
                    handleHistoryMenuVisibleChange(false);
                    // graphicStore.extraContext.listeners.signals.onProcessEscape.dispatch();
                }}>
                <div className="log-history-menu" ref={menuRef}>
                    <div className="scrollable">
                        {graphicStore.extraContext.stmodeContext.logs.map((log, idx) => (
                            <div className="log-history-text" key={idx}>{log}</div>
                        ))}
                    </div>

                </div>
            </ContextMenuModal>
        </div>
    )

}