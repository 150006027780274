import { SideContext } from '@/App';
import { SideStatus } from '@/commons/context/side-store';
import { SIDE_TYPE } from '@/commons/enums/side-type';
import { graphicStore } from '@/commons/store/graphic-store';
import { useContext } from 'react';

import { ReactComponent as YanganqiSvg } from '@/commons/icons/new/yanganqi.svg';
import { ReactComponent as YanganqixianluSvg } from '@/commons/icons/new/yanganqixianlu.svg';
import { ReactComponent as PenlintouSvg } from '@/commons/icons/new/penlintou.svg';
import { ReactComponent as XiaohuoshuanSvg } from '@/commons/icons/new/xiaohuoshuan.svg';
import { ReactComponent as MiehuoqiSvg } from '@/commons/icons/new/miehuoqi.svg';

export default function FireMenu() {
    const sideContext = useContext<SideStatus>(SideContext);

    const onClick = (route: string) => {
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
        sideContext.setSelectedPanelValue(route);
    }

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { onClick(SIDE_TYPE.smoke_detector) }}>
                                <div className='icon'><YanganqiSvg /></div>
                                <div className='name'>烟感器</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><YanganqixianluSvg /></div>
                                <div className='name'>烟感器线路</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.fire_hydrant) }}>
                                <div className='icon'><XiaohuoshuanSvg /></div>
                                <div className='name'>消火栓</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.spray_head) }}>
                                <div className='icon'><PenlintouSvg /></div>
                                <div className='name'>喷淋头</div>
                            </div>
                            <div className='ope-item' onClick={() => { onClick(SIDE_TYPE.fire_extinguisher) }}>
                                <div className='icon'><MiehuoqiSvg /></div>
                                <div className='name'>灭火器</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        消防
                    </div>
                </div>
            </div>

        </div>
    )
}