import { Select, Upload, message } from "antd";
import "./index.less";
import { Texture } from "@/commons/interface/texture";
import { useParams } from "react-router-dom";
import { TEXTURE_CATEGORY } from "@/commons/enums/texture";
import { TextureUploadUrl } from "@/api/spec-common/texture";
import { ReactComponent as UploadSvg } from '@/commons/icons/side/groud-panel/upload.svg'
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { LoadingOutlined } from '@ant-design/icons';


interface Iprops {
    selectUuid: string
    textureList: Texture[]
    onChange: (value: string) => void
    category: string
    beforeUpload: (file: RcFile, FileList: RcFile[]) => any
    onUploadChange: (info: UploadChangeParam<UploadFile<any>>) => void
    loading: boolean
}

export function TextureSelect(props: Iprops) {
    const { projectId } = useParams();

    const beforeUpload = (file, _) => {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            //如果上传的不是图片，则不允许上传
            message.error('只能上传jpg和png图片！');
            return false;
        }else{
            return props.beforeUpload(file, _);
        }
    }
    
    return (
        <div className="texture-select-container">
            <Select
                size='small'
                className="select-box"
                value={props.selectUuid}
                // allowClear
                onChange={props.onChange}
                optionLabelProp='label'
                dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
            >
                {props.textureList.map((item) => (
                    <Select.Option value={item.uuid} key={item.uuid} label={item.textureName}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}>
                            <img src={item.completeUrl} alt={item.textureName} style={{ width: 20, height: 20, marginRight: 20 }} />
                            {item.textureName}
                        </div>
                    </Select.Option>
                ))}
            </Select>
            <Upload name='file'
                data={{ 'projectUuid': projectId, 'category': TEXTURE_CATEGORY[props.category].key }}
                showUploadList={false}
                action={TextureUploadUrl}
                onChange={props.onUploadChange}
                beforeUpload={beforeUpload}
            >
                {props.loading &&
                    <div className='upload-button-warp'>
                        <LoadingOutlined />
                    </div>
                }
                {!props.loading &&
                    <div className='upload-button-warp'>
                        <UploadSvg />
                    </div>
                }

            </Upload>
        </div>
    )
}