import { useEffect, useState } from "react";
import "./sub-dropdown-box-item.less";
import { ReactComponent as CheckSvg } from './icons/check.svg'
import { Input, message } from "antd";
import classNames from "classnames";
import { graphicStore } from "@/commons/store/graphic-store";
import { ReactComponent as LinkSvg } from '@/commons/icons/panel-icon/link.svg';
import SelectModal from '@/commons/components/select-modal';
import { createPrimitive } from '@/api/geometry/primitive';
import getTempData from "./tempData";



interface Iprops {
    name: string
    uuid: string
    selected: boolean
    setSelected: (uuid: string, selected: boolean) => void
    updateName: (uuid: string, name: string) => void
    type: string
    title: string
}


export function SubDropdownBoxItem(props: Iprops) {
    const [editing, setEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>(props.name);

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [dataList, setDataList] = useState<any[]>([])

    useEffect(() => {
        if (!(props.type?.length > 0)) return
        let data = getTempData(props.type)
        setDataList(data)
    }, [props.type])

    const onDoubleClickExpand = () => {
        setEditing(!editing);
    }

    const onInputFinish = () => {
        setEditing(false);
        props.updateName(props.uuid, name);
    }

    useEffect(() => {
        setName(props.name);
    }, [props.name])


    /**
     * 切换列表选中状态后，需要反向修改图形区的选中状态
     */
    useEffect(() => {
        if (props.selected) {
            const editor = graphicStore.extraContext.getCurrentViewEditor();
            if (editor) {
                editor.entityDict[props.uuid].isSelected = true;
                editor.selectControl.addEntityToSelectedEntities(editor.entityDict[props.uuid]);
                editor.listeners.signals.needRender.dispatch('redraw');
            }
        } else {
            const editor = graphicStore.extraContext.getCurrentViewEditor();
            if (editor) {
                editor.entityDict[props.uuid].isSelected = false;
                delete editor.selectControl.getSelectedEntities()[props.uuid];
                editor.listeners.signals.needRender.dispatch('redraw');
            }
        }
    }, [props.selected])

    const onClick = () => {
        props.setSelected(props.uuid, !props.selected);
    }

    const onConfirmLink = (e) => {
        const typeName: string = props.type
        const editor = graphicStore.extraContext.getCurrentViewEditor();
        let entity = editor.entityDict[props.uuid]
        if (entity) {
            let data = { ...entity?.extraData || {}}
            data[typeName] = e
            entity.extraData = data
            createPrimitive(entity.toJson()).then(()=>{
                message.success("绑定成功！")
            }).catch((e) => {
                message.error("更新失败！")
            })
        }
    }

    return (
        <div className={classNames({
            "sub-dropdown-box-item": true,
            "selected": props.selected
        })}
            onClick={onClick}
        >
            {!editing &&
                <>
                    <div className="sub-dropdown-box-item-text" onDoubleClick={onDoubleClickExpand}>
                        <span>{name}</span>
                        <LinkSvg onClick={(e) => { e.stopPropagation(); setModalOpen(true) }} />
                    </div>
                </>
            }
            {editing &&
                <>
                    <div className="sub-dropdown-box-item-input" onDoubleClick={onDoubleClickExpand}>
                        <span style={{ width: '90%', marginRight: '1px' }}>
                            <Input
                                height={20}
                                className="input"
                                placeholder="输入名称"
                                style={{
                                    paddingTop: '2px',
                                    height: '20px'
                                }}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </span>
                        <div className="right-svg" onClick={onInputFinish}>
                            <CheckSvg className="svg" />
                        </div>
                    </div>
                </>
            }
            <SelectModal onConfirmClick={(e) => { onConfirmLink(e) }} data={dataList} isOpen={modalOpen} setIsOpen={setModalOpen} title={props.title} />
        </div>
    )

}