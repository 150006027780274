import { useEffect, useState } from 'react';
import "../../../commons/styles/side-bar.less"
import "./index.less";
import SideBarTool from '@/commons/components/side-bar-tool';
import { graphicStore } from '@/commons/store/graphic-store';
import { Input, Image } from 'antd';
import { onActiveSubControl } from "@/commons/utils/graphic-method";
import { BIM_SINGAL, BUILDING_SUB_CONTROL } from "tnbimbase";
import { floatVerification } from "@/commons/utils/antd-input";
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { LayerType } from "@/commons/enums/layer-type";
import { findLayersInProjectUuid, saveLayer } from "@/api/geometry/layer";
import genUUID from '@/commons/utils/gen-uuid';
import { LineType, Layer } from "pytha";

export function FloorPanel() {
    const { projectId } = useParams();


    const [thickness, setThickness] = useState<string>('120'); // 厚度
    const [zbias, setZbias] = useState<string>('0');  //z偏移

    const [loading, setLoading] = useState<boolean>(false);

    const onThicknessChange = (e) => {
        setThickness(floatVerification(e.target.value));
    }

    const onZbiasChange = (e) => {
        setZbias(floatVerification(e.target.value));
    }

    useEffect(() => {
        setLoading(false);
    }, [])


    const onAssembleClick = (useRegion: boolean = true) => {
        setLoading(true);
        findLayersInProjectUuid(projectId).then(res => {
            let layers = res.data || [];
            let layer = layers.find(layer => layer.type === LayerType.FLOOR);
            if (layer) {
                onActiveSubControl(BUILDING_SUB_CONTROL.ADDFLOOR.name);
                graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.INIT_FLOOR_CTRL)
                    .dispatch({ zbias: zbias, useRegion: useRegion, thickness: thickness, layerUuid: layer.uuid });
                setLoading(false);
            } else {
                let uuid = genUUID();
                let newLayer: Layer = {
                    uuid: uuid,
                    name: "楼板",
                    color: "#000000",
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    lock: false,
                    hidden: false,
                    originLayer: false,
                    type: LayerType.FLOOR,
                };
                saveLayer(newLayer).then(_ => {
                    onActiveSubControl(BUILDING_SUB_CONTROL.ADDFLOOR.name);
                    graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.INIT_FLOOR_CTRL)
                        .dispatch({ zbias: zbias, useRegion: useRegion, thickness: thickness, layerUuid: newLayer.uuid })
                        let newLayers = [...graphicStore.context.layerContext.layers, newLayer];
                        graphicStore.context.layerContext.setLayers(newLayers);  //更新几何库图层
                }).finally(() => {
                    setLoading(false);
                })
            }
        }).finally(() => {
            if(loading){
                setLoading(false);
            }
        })
       
       
    }

    //TODO 下面的通用物理信息没加



    return (
        <div className="side-bar-main building-floor-container">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    楼板
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">厚度:</div>
                        <Input
                            value={thickness}
                            className="input-box"
                            onChange={onThicknessChange}
                            suffix="mm"
                        />
                    </div>

                    <div className="row">
                        <div className="label">基准偏移:</div>
                        <Input
                            value={zbias}
                            className="input-box"
                            onChange={onZbiasChange}
                            suffix="mm"
                        />
                    </div>
                    <div className='test'>
                        <Image width={228} height={54} preview={false} src={require('./base-bias.png')} className='img' />

                    </div>
                    <div className="ope-area">
                        {loading &&
                            <div className="secondary-btn">
                                <LoadingOutlined />
                            </div>
                        }
                        {!loading &&
                            <div className="secondary-btn" onClick={() => { onAssembleClick() }}>
                                <div className="text">选择面</div>
                            </div>
                        }

                        {loading &&
                            <div className="secondary-btn">
                                <LoadingOutlined />
                            </div>
                        }
                        {!loading &&
                            <div className="secondary-btn" onClick={() => { onAssembleClick(false) }}>
                                <div className="text">绘制板</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}