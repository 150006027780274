import { SettingOutlined } from '@ant-design/icons';


export default function WaterMenu() {

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>生活水管</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>上水管</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>生活水龙头</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        给水
                    </div>
                </div>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>雨水下水口</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>雨水管</div>
                            </div>
                        </div>
                        <div className='ope-col'>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>污水下水口</div>
                            </div>
                            <div className='ope-item' style={{ width: 90 }} onClick={() => { }}>
                                <div className='icon'><SettingOutlined /></div>
                                <div className='name'>污水管</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        排水
                    </div>
                </div>
            </div>

        </div>
    )
}