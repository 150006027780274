import { ReactComponent as BaoCunSvg } from '@/commons/icons/new/baocun.svg';


export default function FileMenu() {

    return (
        <div className='tn-menu-container'>
            <div className='ope-panel'>
                <div className='ope-col-area'>
                    <div className='ope-btn'>
                        <div className='ope-col'>
                            <div className='ope-item' onClick={() => { }}>
                                <div className='icon'><BaoCunSvg /></div>
                                <div className='name'>保存</div>
                            </div>
                        </div>
                    </div>
                    <div className='ope-name'>
                        文件
                    </div>
                </div>
            </div>

        </div>
    )
}