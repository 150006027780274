import { ReactNode } from 'react';
import { message } from 'antd';
import { ReactComponent as SuccessSvg } from '@/commons/icons/message/success.svg';
import { ReactComponent as ErrorSvg } from '@/commons/icons/message/error.svg';
import { ReactComponent as WarningSvg } from '@/commons/icons/message/warning.svg';
import { ReactComponent as InfoSvg } from '@/commons/icons/message/info.svg';
import { ReactComponent as LoadingSvg } from '@/commons/icons/message/loading.svg';
import Icon from '@ant-design/icons';


export interface BaseConfig {
    content?: string;
    icon?: ReactNode;
    className?: string;
    duration?: number;
}

const TnMessage = {
    success: (content: string, props?: BaseConfig) => {
        message.success({
            content: content,
            icon: props?.icon ? props.icon : <SuccessSvg />,
            className: props?.className ? props?.className : '',
            duration: props?.duration ? props?.duration : 3,
        });
    },
    error: (content: string, props?: BaseConfig) => {
        message.error({
            content: content,
            icon: props?.icon ? props.icon : <ErrorSvg />,
            className: props?.className ? props?.className : '',
            duration: props?.duration ? props?.duration : 3,
        });
    },
    warning: (content: string, props?: BaseConfig) => {
        message.warning({
            content: content,
            icon: props?.icon ? props.icon : <WarningSvg />,
            className: props?.className ? props?.className : '',
            duration: props?.duration ? props?.duration : 3,
        });
    },
    info: (content: string, props?: BaseConfig) => {
        message.info({
            content: content,
            icon: props?.icon ? props.icon : <InfoSvg />,
            className: props?.className ? props?.className : '',
            duration: props?.duration ? props?.duration : 3,
        });
    },
    loading: (content: string, props?: BaseConfig) => {
        message.info({
            content: content,
            icon: props?.icon ? props.icon : <Icon component={LoadingSvg} spin />,
            className: props?.className ? props?.className : '',
            duration: props?.duration ? props?.duration : 0,
        });
    },
    destory: () => {
        message.destroy();
    }

};

export default TnMessage;
