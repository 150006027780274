import SideBarTool from '@/commons/components/side-bar-tool';
import './index.less';
import { Input, Modal, Select, message } from 'antd';
import { ReactComponent as HouseSvg } from '@/commons/icons/panel-icon/house.svg';
import { ReactComponent as UploadSvg } from '@/commons/icons/side/groud-panel/upload.svg'
import { useContext, useEffect, useState } from 'react';
import { ComponentType, ComponentTypeList } from '@/commons/enums/component-type';
import { Component } from '@/commons/interface/speccommon/component';
import { useParams } from 'react-router-dom';
import { deleteComponent, getComponents, updateComponent } from '@/api/spec-common/component';
import LocalScene from '@/commons/components/local-scene';
import classNames from 'classnames';
import { graphicStore } from '@/commons/store/graphic-store';
import { ModelBase } from "pytha";
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';
import { SystemContext } from '@/App';
import { SystemStatus } from '@/commons/context/system-store';
import { Vector3 } from 'three';
import { onActiveSubControl } from '@/commons/utils/graphic-method';
import { BUILDING_SINGAL, BUILDING_SUB_CONTROL, ComponentT, DefaultStoreyUuid, FireExtinguisherT, FireHydrantT, GroupType, PatrolPointT, SmokeDetectorT, SprayHeadT } from 'tnbimbase';
import { ContextMenuFC } from 'tncet-common';
import TnModal from '@/commons/widget/base-modal';


const { TextArea } = Input;

const ColorList = ['#E9FFBB', '#84FFD3', '#BBCEFF', '#FFD3BB']

export default function ComponentPanel() {
    const { projectId } = useParams();
    const systemContext = useContext<SystemStatus>(SystemContext);
    const [components, setComponents] = useState<Component[]>([]);
    const [displayComponents, setDisplayComponents] = useState<Component[]>([]);
    const [selectedComponent, setSelectedComponent] = useState<Component>(null);
    const [deleteCom, setDeleteCom] = useState<Component>(null);
    const [selectedComponentType, setSelectedComponentType] = useState<string>('');
    // const [btnActive, setBtnActive] = useState<boolean>(false);
    const [editName, setEditName] = useState<string>('');
    const [editExplain, setEditExplain] = useState<string>('');
    const [cmOpen, setCmOpen] = useState<boolean>(false); // 是否展开右键菜单
    const [top, setTop] = useState<number>();
    const [left, setLeft] = useState<number>();


    useEffect(() => {
        if (!projectId) return;
        getComponents({ projectUuid: projectId }).then(res => {
            let data: Component[] = res.data || [];
            data = data?.map(item => {
                let pris = item.entities?.map(json => {
                    let newEntity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.type))();
                    newEntity.fromJson(json);
                    return newEntity;
                }) || [];
                item.primitives = pris;
                return item;
            })
            setComponents(data);
            if (selectedComponentType) {
                let displayArr = components?.filter(item => item.componentType === selectedComponentType);
                setDisplayComponents(displayArr);
            } else {
                setDisplayComponents(data);
            }
            systemContext.setComponentCount(data?.length || 0);
        })
    }, [projectId, systemContext.refreshComponentFlag])

    // useEffect(() => {
    //     if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
    //     graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisEnd).add(onAddTempAxisEnd);

    //     return () => {
    //         if (!graphicStore.extraContext.getCurrentViewEditor()) return;
    //         graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisEnd).remove(onAddTempAxisEnd);
    //     }
    // }, [graphicStore?.extraContext?.getCurrentViewEditor()])

    const onSelectChange = (componentType) => {
        setSelectedComponentType(componentType);
        systemContext.setSelectedComponentType(componentType);
        if (!componentType) {
            // 清空
            setDisplayComponents([...components]);
        } else {
            // 选择
            let newArr = components?.filter(item => item.componentType === componentType);
            setDisplayComponents(newArr);
        }
    }

    // 返回颜色字符串
    // const getRandomColor = () => {
    //     const randomInt = Math.floor(Math.random() * 16777215);
    //     const hexString = randomInt.toString(16).padStart(6, '0');
    //     return `#${hexString}`;
    // }

    const editBlur = (field: string) => {
        if (!selectedComponent) {
            message.warning("没有选中组件");
            return;
        }
        if (field === 'name' && (!editName || editName?.trim()?.length === 0)) {
            message.warning("名称不能为空");
            return;
        }
        let newComponent = { ...selectedComponent };
        newComponent.name = editName;
        newComponent.explain = editExplain;
        updateComponent({
            uuid: selectedComponent.uuid,
            name: editName,
            explain: editExplain
        }).then(res => {
            let idx1 = components?.findIndex(item => item.uuid === newComponent.uuid);
            if (idx1 !== -1) {
                components[idx1] = newComponent;
                setComponents([...components]);
            }

            let idx2 = displayComponents?.findIndex(item => item.uuid === newComponent.uuid);
            if (idx2 !== -1) {
                displayComponents[idx2] = newComponent;
                setDisplayComponents([...displayComponents]);
            }
        }).catch(err => {
            console.log('err', err)
        })
    }

    const rightClick = (item, idx) => {
        setSelectedComponent(item);
        setEditName(item?.name);
        setEditExplain(item?.explain);
    }

    const createBtnClick = () => {
        let selected: ModelBase[] = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList();
        if (!selected || selected?.length === 0) {
            message.warning("请先在图形区选择图元");
            return;
        }
        systemContext.cacheComponent.primitives = [...selected];
        systemContext.cacheComponent.axisX = graphicStore.context.ucsContext.currentUcs.axisX;
        systemContext.cacheComponent.normal = graphicStore.context.ucsContext.currentUcs.axisZ;

        let closestDistance = Number.MAX_VALUE;
        let closestPosition = new Vector3();

        selected?.forEach((entity: ModelBase) => {
            let boundingBox = entity.getBoundingBox();
            // 根据包围盒计算最接近包围盒点的点
            let minDistance = calculateDistance(boundingBox.min);
            if (minDistance < closestDistance) {
                closestDistance = minDistance;
                closestPosition = boundingBox.min.clone();
            }
            let maxDistance = calculateDistance(boundingBox.max);
            if (maxDistance < closestDistance) {
                closestDistance = maxDistance;
                closestPosition = boundingBox.max.clone();
            }
        })
        systemContext.cacheComponent.position = closestPosition;
        systemContext.setCacheComponent({ ...systemContext.cacheComponent });
        // setBtnActive(true);
        // 打开弹窗
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.openComponentModal).dispatch(closestPosition);
    }

    const calculateDistance = (point: Vector3): number => {
        return Math.sqrt(point.x * point.x + point.y * point.y + point.z * point.z);
    };

    const assmbleComponentClick = (component: Component) => {
        onActiveSubControl(BUILDING_SUB_CONTROL.QUICKLYASSEMBLEBLOCK.name);
        let com;
        let groupArr = []
        if (component.componentType === ComponentType.DIY) {
            com = new ComponentT(ComponentType.DIY).fromJson(component);
        } else if (component.componentType === ComponentType.SMOKE_DETECTOR) {
            com = new SmokeDetectorT().fromJson(component);
            groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        } else if (component.componentType === ComponentType.FIRE_HYDRANT) {
            com = new FireHydrantT().fromJson(component);
            groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        } else if (component.componentType === ComponentType.SPRAY_HEAD) {
            com = new SprayHeadT().fromJson(component);
            groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        } else if (component.componentType === ComponentType.FIRE_EXTINGUISHER) {
            com = new FireExtinguisherT().fromJson(component);
            groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        } else if (component.componentType === ComponentType.FENCEUPRIGHT) {
            com = new ComponentT(ComponentType.FENCEUPRIGHT).fromJson(component);
        } else if (component.componentType === ComponentType.FENCESOLIDWALL) {
            com = new ComponentT(ComponentType.FENCESOLIDWALL).fromJson(component);
        } else if (component.componentType === ComponentType.FENCEBANISTER) {
            com = new ComponentT(ComponentType.FENCEBANISTER).fromJson(component);
        } else if (component.componentType === ComponentType.PARKINGSPACE) {
            com = new ComponentT(ComponentType.PARKINGSPACE).fromJson(component);
        } else if (component.componentType === ComponentType.NOMOTORSPARKINGSPACE) {
            com = new ComponentT(ComponentType.NOMOTORSPARKINGSPACE).fromJson(component);
        } else if (component.componentType === ComponentType.PATROLPOINT) {
            com = new PatrolPointT().fromJson(component);
            groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        }
        // TODO 摄像头 
        // else if (component.componentType === ComponentType.MONITOR) {
        //     com = new MonitorT().fromJson(component);
        //     groupArr.push({ key: GroupType.STOREY, value: DefaultStoreyUuid.DYNAMIC });
        // }
        if (com) {
            com.templateUuid = component.uuid;
        }

        graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal(BUILDING_SINGAL.onQuicklyAssembleBlock)
            .dispatch(com, new Vector3(0, 0, 0), null, component?.name, groupArr);
    }

    const deleteComClick = () => {
        if (!deleteCom) {
            message.error("未选中组件");
        }
        TnModal().deleteModal({
            closable: false,
            onOk() {
                deleteComponent(deleteCom?.uuid).then(res => {
                    if (res.status === 200) {
                        let dIdx = displayComponents?.findIndex(item => item.uuid === deleteCom.uuid);
                        displayComponents.splice(dIdx, 1);
                        setDisplayComponents([...displayComponents]);
                        let cIdx = components?.findIndex(item => item.uuid === deleteCom.uuid);
                        components.splice(cIdx, 1);
                        setComponents([...components]);
                        message.success("删除成功");
                    }
                }).catch(err => {
                    message.error("删除失败");
                }).finally(() => {
                    Modal.destroyAll();
                    setDeleteCom(null);
                    setCmOpen(false);
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        })

    }

    const onHouseClick = () => {
        setSelectedComponentType(null);
        let templateUuids = graphicStore.extraContext.getCurrentViewEditor().getEntities()?.map(item => item?.templateUuid)?.filter(item => item !== undefined && item !== null);
        let uniqueUuids = new Set(templateUuids);
        let displayArr = components?.filter(item => uniqueUuids.has(item?.uuid));
        setDisplayComponents(displayArr);
    }

    // const onAddTempAxisEnd = () => {
    //     // setBtnActive(false);
    // }


    return (
        <div className="left-component-container">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="side-title" onClick={() => {
                let selectedEntities = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList().filter(entity => !entity.lock);
                let block = (selectedEntities[0] as ComponentT);
                graphicStore.extraContext.getCurrentViewEditor().blockToTmp(block);
            }}>组件</div>
            <div className='component-detail-row'>
                {/* TODO 被选择的组件在预览框展示，被选择的组件预览框变蓝 */}
                <div className='left-block'>
                    <LocalScene
                        entities={selectedComponent?.primitives || []}
                        color="#f5f5f5"
                        react_id={"preview-component"}
                        width={66}
                        height={66}
                        top={-0.01}
                        left={0.1}
                    />
                </div>
                <div className='right-block' onClick={() => { }}>
                    <div className='c-top'>
                        <Input
                            // TODO 没有名称的自动编号
                            placeholder='组件名称'
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                            onBlur={() => editBlur("name")}
                        />
                    </div>
                    <div className='c-bottom'>
                        <TextArea
                            placeholder='组件描述'
                            value={editExplain}
                            onChange={(e) => setEditExplain(e.target.value)}
                            onBlur={() => editBlur("explain")}
                        />
                    </div>
                </div>
            </div>
            <div className="side-title-row">
                <span>组件库</span>
                {/* 单击后，只显示模型中已用的组件 */}
                <HouseSvg onClick={onHouseClick} />
                <Select
                    popupClassName='side-title-pop'
                    value={selectedComponentType}
                    onChange={(e) => { onSelectChange(e) }}
                    allowClear
                >
                    {ComponentTypeList.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
                <div className='upload-button'><UploadSvg /></div>
            </div>
            <div className='c-list'>
                {displayComponents?.map((item: Component, idx) => (
                    <div className='c-list-row' key={idx} onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTop(e.clientY);
                        setLeft(e.clientX);
                        setCmOpen(true);
                        setDeleteCom(item);
                    }}>
                        <div className='c-row-left' onClick={() => assmbleComponentClick(item)}>
                            {/* <LocalScene
                                entities={item?.primitives}
                                color="#000"
                                react_id={`preview-component-${idx}`}
                                width={64}
                                height={58}
                                top={-0.01}
                                left={0.1}
                            /> */}
                            <div style={{
                                backgroundColor: ColorList[idx % 4],
                                width: 64, height: 58,
                                border: selectedComponent?.uuid === item.uuid ? '1px solid #165DFF' : 'none'
                            }}></div>
                        </div>
                        <div className='c-row-right' onClick={() => rightClick(item, idx)}>
                            <div className='c-row-right-top'>{item?.name}</div>
                            <div className='c-row-right-middle'>
                                <span>创建人</span>
                                <span>未知</span>
                            </div>
                            <div className='c-row-right-bottom'>{item?.explain}</div>
                        </div>
                    </div>
                ))}
            </div>
            {/* 点击后成为active状态，进入创建组件命令 */}
            <div
                className={classNames({
                    'create-btn': true,
                    'create-btn-active': false,
                })}
                onClick={() => createBtnClick()}
            >创建组件</div>

            <ContextMenuFC
                visible={cmOpen}
                top={top}
                left={left}
                width={104}
                wrapperClassname='component-type-menu'
                onCancel={() => { setCmOpen(false) }}
            >
                <div className='btn' onClick={() => deleteComClick()}>删除组件</div>
            </ContextMenuFC>

        </div>
    )
}