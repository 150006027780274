import { SideBarBtnType } from '@/commons/enums/side-bar-btn-type';
import './index.less';
import SideBarTool from "@/commons/components/side-bar-tool";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Modal, message } from 'antd';
import { ReactComponent as ConfimSvg } from '@/commons/icons/marshalling/confim.svg';
import { ReactComponent as EditSvg } from '@/commons/icons/marshalling/edit.svg';
import { ReactComponent as LinkSvg } from '@/commons/icons/panel-icon/link.svg';
import { ReactComponent as UpSvg } from '@/commons/icons/marshalling/up-arrow.svg';
import { ReactComponent as DownSvg } from '@/commons/icons/marshalling/down-arrow.svg';
import classNames from 'classnames';
import { graphicStore } from '@/commons/store/graphic-store';
import { AddPrimitivesCommand, ModelBase } from "pytha";
import { BimModelBase, GroupType } from 'tnbimbase';
import TnModal from '@/commons/widget/base-modal';
import { Elevator, StoreyElevator } from '@/commons/interface/building/elevator';
import { addElevator, deleteElevator, getElevators, updateElevator } from '@/api/building/elevator';
import SelectModal from '@/commons/components/select-modal';
import { createPrimitiveMulti } from '@/api/geometry/primitive';

const tempElevatorData: { value: string, uuid: string }[] = [
    { value: "MTkDE", uuid: "ddcb6d89-8fc7-47e1-b336-970ad3177463" },
    { value: "phuny", uuid: "94656b85-5ab0-4f40-b75e-dc0a1df29a6c" },
    { value: "JFseu", uuid: "6a1dfbbd-c33a-483d-bc20-1805f16d56de" },
    { value: "ehpGn", uuid: "c5c90c11-ba63-4314-acd9-ec92ee100a75" },
    { value: "eCwas", uuid: "4a0619a2-6b49-40a9-9f50-e100eafbce1c" },
    { value: "TCdpz", uuid: "91f7407d-bd54-43e8-97fb-56acce51e0f8" },
    { value: "AEIhn", uuid: "8a3aa322-ab7d-441c-a7a8-3443c3f84e78" },
    { value: "nlbNI", uuid: "4bcaa3c8-49ee-4937-b478-4e55cc458f3a" },
    { value: "AIpxA", uuid: "857222c9-f621-4fea-807c-6a107ce7eb5f" },
    { value: "roxSg", uuid: "fc45ad60-43f3-4047-b6b3-3fbc762e571a" },
]

export default function ElevatorPanel() {

    const { projectId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [displayElevators, setDisplayElevators] = useState<StoreyElevator[]>([]);
    const [activeStoreyUuid, setActiveStoreyUuid] = useState<string>();
    const [activeElevator, setActiveElevator] = useState<Elevator>();
    const [editElevatorUuid, setEditElevatorUuid] = useState<string>('');
    const [editElevatorName, setEditElevatorName] = useState<string>('');
    const [showAddElevator, setShowAddElevator] = useState<boolean>(false);
    const [showBtnGroup, setShowBtnGroup] = useState<boolean>(false);

    const [modalOpen, setModalOpen] = useState<boolean>(false)


    useEffect(() => {
        getElevators(projectId).then(res => {
            if (res.status === 200) {
                let displayElevators: StoreyElevator[] = res.data || [];
                displayElevators = displayElevators.map(item => {
                    item.expand = false;
                    return item;
                })
                setDisplayElevators(displayElevators);
            }
        })
    }, [projectId])


    const onExpandClick = (item: StoreyElevator, idx) => {
        if (item?.elevators?.length === 0) {
            return;
        }
        item.expand = !item.expand;
        displayElevators[idx] = { ...item };
        setDisplayElevators([...displayElevators]);

    }

    const onDeleteClick = () => {
        if (!activeElevator) {
            message.warning("没有选中电梯");
            return;
        }
        TnModal().deleteModal({
            closable: false,
            onOk() {
                deleteElevator(projectId, activeElevator.uuid).then(res => {
                    message.success("删除成功");
                    // 1 去除属性
                    let sIdx = displayElevators.findIndex(item => item.storeyUuid === activeStoreyUuid);
                    let elevators: Elevator[] = displayElevators[sIdx].elevators;
                    let rIdx = elevators.findIndex(item => item.uuid === activeElevator.uuid);
                    let elevator = elevators[rIdx];
                    let priUuids = elevator?.primitiveUuids || [];
                    if (priUuids?.length > 0) {
                        let newEntities = [];
                        for (let uuid of priUuids) {
                            let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                            if (!(entity as BimModelBase).group[GroupType.ELEVATOR]) {
                                continue;
                            }
                            let groupUuids = (entity as BimModelBase).group[GroupType.ELEVATOR];
                            groupUuids = groupUuids?.filter(item => item !== activeElevator.uuid) || [];
                            (entity as BimModelBase).group[GroupType.ELEVATOR] = groupUuids;
                            newEntities.push(entity);
                        }
                        new AddPrimitivesCommand(newEntities).execute();
                    }
                    // 2 修改列表
                    elevator.primitiveUuids = [];
                    elevators.splice(rIdx, 1);
                    setDisplayElevators([...displayElevators]);
                }).finally(() => {
                    Modal.destroyAll();
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        })
    }

    const onElevatorClick = (item, sub) => {
        setActiveElevator(sub);
        setActiveStoreyUuid(item.storeyUuid);
        let entities: ModelBase[] = sub?.primitiveUuids?.map(uuid => graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid]);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
        if (entities?.length > 0) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(entities);
        }
    }

    const onConfirmLink = (e: string) => {
        if (!(e?.length > 0)) return
        let entities: ModelBase[] = activeElevator?.primitiveUuids?.map(uuid => graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid]);
        let saveJsonList = []
        for (let i = 0; i < entities.length; i++) {
            // createPrimitive
            let data = { ...entities[i]?.extraData || {}, elevatorUuid: e }
            entities[i].extraData = data
            saveJsonList.push(entities[i].toJson())
        }
        createPrimitiveMulti(saveJsonList).catch((e)=>{
            message.error("更新失败！")
        })
    }

    const onAddBtnClick = () => {
        if (!activeStoreyUuid) {
            message.warning("没有选中层");
            return;
        }
        setEditElevatorName("");
        setEditElevatorUuid("");
        let idx = displayElevators.findIndex(item => item.storeyUuid === activeStoreyUuid);
        displayElevators[idx].expand = true;
        setDisplayElevators([...displayElevators]);
        setShowAddElevator(true);
    }

    const onAddSave = () => {
        if (loading) return;
        if (!editElevatorName) {
            setEditElevatorName("");
            setShowAddElevator(false);
            return;
        }
        setLoading(true);
        let data: Elevator = {
            projectUuid: projectId,
            name: editElevatorName,
            storeyUuid: activeStoreyUuid,
        }
        addElevator(projectId, data).then(res => {
            if (res.status === 200) {
                let uuid = res.data;
                message.success("添加成功");
                data.uuid = uuid;
                let idx = displayElevators?.findIndex(item => item.storeyUuid === activeStoreyUuid);
                displayElevators[idx].elevators.push(data);
                setDisplayElevators([...displayElevators]);
            }
        }).catch(() => {
            message.error("添加失败");
        }).finally(() => {
            setLoading(false);
            setShowAddElevator(false);
            setEditElevatorName("");
            setEditElevatorUuid("");
        })
    }

    const onElevatorEditClick = (storey: StoreyElevator, elevator: Elevator) => {
        setEditElevatorUuid(elevator.uuid);
        setEditElevatorName(elevator.name);
        setActiveElevator(elevator);
        setActiveStoreyUuid(storey.storeyUuid);
        setShowBtnGroup(true);
        let entities: ModelBase[] = elevator?.primitiveUuids?.map(uuid => graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid]);
        if (entities?.length > 0) {
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.forceEntitiesSelected.dispatch(entities);
            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onCursorChange.dispatch(3);
        }
    }

    const onClickLink = (sub: Elevator) => {
        setModalOpen(true)
    }

    const onSave = () => {
        // 1.获取当前选中的图元
        let selectedList = graphicStore.extraContext.getCurrentViewEditor().selectControl.getSelectedEntityList();
        let newPriUuids: string[] = selectedList?.map(item => item.uuid) || [];
        let activeElevatorUuids = new Set(activeElevator?.primitiveUuids || []);
        let newPriUuidsSet = new Set(newPriUuids);
        let addedUuids = newPriUuids.filter(uuid => !activeElevatorUuids.has(uuid));
        let removedUuids = [...activeElevatorUuids].filter(uuid => !newPriUuidsSet.has(uuid));

        // 2.传递参数回去
        let data: Elevator = {
            ...activeElevator,
            name: editElevatorName,
        }

        // 3.修改api 同步修改图元属性
        updateElevator(activeElevator.uuid, data).then(res => {
            if (res.status === 200) {
                // 1 修改图元属性（旧的需要删除属性，新的需要新增属性）
                let newEntities = [];
                for (let uuid of addedUuids) {
                    let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                    if (!(entity as BimModelBase).group[GroupType.ELEVATOR]) {
                        (entity as BimModelBase).group[GroupType.ELEVATOR] = [];
                    }
                    let groupUuids = (entity as BimModelBase).group[GroupType.ELEVATOR] || [];
                    groupUuids.push(activeElevator.uuid);
                    (entity as BimModelBase).group[GroupType.ELEVATOR] = groupUuids;
                    newEntities.push(entity);
                }

                for (let uuid of removedUuids) {
                    let entity = graphicStore.extraContext.getCurrentViewEditor().entityDict[uuid];
                    if (!(entity as BimModelBase).group[GroupType.ELEVATOR]) {
                        continue;
                    }
                    let groupUuids = (entity as BimModelBase).group[GroupType.ELEVATOR];
                    groupUuids = groupUuids?.filter(item => item !== activeElevator.uuid) || [];
                    (entity as BimModelBase).group[GroupType.ELEVATOR] = groupUuids;
                    newEntities.push(entity);
                }
                new AddPrimitivesCommand(newEntities).execute();
                // 2 修改列表
                let sIdx = displayElevators.findIndex(item => item.storeyUuid === activeStoreyUuid);
                let elevators = displayElevators[sIdx].elevators;
                let rIdx = elevators.findIndex(item => item.uuid === activeElevator.uuid);
                elevators[rIdx].primitiveUuids = newPriUuids;
                elevators[rIdx].name = editElevatorName;
                setDisplayElevators([...displayElevators]);
                message.success("编辑成功");

            }
        }).catch(err => {
            console.log('err', err)
            message.error("编辑失败");
        }).finally(() => {
            onCancel();
        })
    }

    const onCancel = () => {
        setEditElevatorName("");
        setEditElevatorUuid("");
        setShowBtnGroup(false);
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onCursorChange.dispatch(1);
    }

    return (
        <div className='elevator-container'>
            <SideBarTool
                activeBtnList={[SideBarBtnType.DELETE, SideBarBtnType.ADD]}
                onDeleteBtnClick={onDeleteClick}
                onAddBtnClick={onAddBtnClick}
            />
            <div className="elevator-side-title">电梯</div>
            <div className='elevator-content-area'>
                <div className='elevator-content'>
                    {displayElevators?.map((item, key) => (
                        <>
                            <div
                                key={key}
                                className={classNames({
                                    'elevator-row': true,
                                    'elevator-row-active': activeStoreyUuid === item.storeyUuid,
                                })}
                                onClick={() => setActiveStoreyUuid(item.storeyUuid)}>
                                <div className='text-area'>{item.storeyName}</div>
                                <div
                                    className='icon-area'
                                    onClick={() => onExpandClick(item, key)}
                                >
                                    {item?.elevators?.length > 0 && item?.expand && <UpSvg />}
                                    {item?.elevators?.length > 0 && !item?.expand && <DownSvg />}
                                </div>
                            </div>
                            {item?.elevators?.length > 0 && item?.expand && item?.elevators?.map((sub, idx) => (
                                <div
                                    key={idx}
                                    className={classNames({
                                        'elevator-sub-row': true,
                                        'elevator-sub-row-active': activeElevator?.uuid === sub?.uuid,
                                    })}
                                    onClick={() => { onElevatorClick(item, sub) }}>
                                    <div className='text-area'>
                                        {/* {sub?.name} */}
                                        {editElevatorUuid !== sub.uuid && `${sub?.name}`}
                                        {editElevatorUuid === sub.uuid && <Input
                                            value={editElevatorName}
                                            onChange={(e) => setEditElevatorName(e.target.value)}
                                            onPressEnter={onSave}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Escape') {
                                                    setShowAddElevator(false);
                                                    setEditElevatorName("");
                                                    setEditElevatorUuid("");
                                                    setShowBtnGroup(false);
                                                    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.emptySelectedEntities.dispatch();
                                                }
                                            }}
                                            placeholder='单击输入电梯名称'
                                            autoFocus
                                        />}
                                    </div>
                                    {editElevatorUuid !== sub.uuid && (
                                        <div className='icon-list'>
                                            <EditSvg className='icon-area' onClick={() => onElevatorEditClick(item, sub)} />
                                            <LinkSvg className='icon-area' onClick={() => { onClickLink(sub) }} />
                                        </div>
                                    )}
                                    {editElevatorUuid === sub.uuid && <div
                                        className='icon-area'
                                        onClick={() => onElevatorEditClick(item, sub)}
                                    >
                                        <ConfimSvg />
                                    </div>}
                                </div>
                            ))}
                            {showAddElevator && activeStoreyUuid === item?.storeyUuid && (
                                <div className={classNames({
                                    'elevator-sub-row': true,
                                    'elevator-sub-row-active': true,
                                })}>
                                    <div className='text-area'>
                                        <Input
                                            value={editElevatorName}
                                            onChange={(e) => setEditElevatorName(e.target.value)}
                                            onPressEnter={onAddSave}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Escape') {
                                                    setShowAddElevator(false);
                                                    setEditElevatorName("");
                                                    setEditElevatorUuid("");
                                                }
                                            }}
                                            placeholder='单击输入电梯名称'
                                            autoFocus
                                        />
                                    </div>
                                    <div className='icon-area' onClick={onAddSave}><ConfimSvg /></div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
            {showBtnGroup && <div className='elevator-btn-group'>
                <div className="btn save" onClick={onSave}>确定</div>
                <div className="btn cancel" onClick={onCancel}>取消</div>
            </div>}
            <SelectModal onConfirmClick={(e) => { onConfirmLink(e) }} data={tempElevatorData} isOpen={modalOpen} setIsOpen={setModalOpen} />
        </div>
    )
}