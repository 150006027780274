import { ReactComponent as CloseSvg } from '@/commons/icons/panel-icon/close.svg';
import { ReactComponent as RadioSvg } from '@/commons/icons/operation-bar/radio.svg';
import { ReactComponent as RadioCheckSvg } from '@/commons/icons/operation-bar/radio-check.svg';
import './create-component.less';
import { Input, Select, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ComponentTypeList } from '@/commons/enums/component-type';
import { SystemContext } from '@/App';
import { SystemStatus } from '@/commons/context/system-store';
import { Component } from '@/commons/interface/speccommon/component';
import { EXTRA_SUB_CONTROL } from '@/commons/enums/extra-sub-controls';
import { graphicStore } from '@/commons/store/graphic-store';
import { onActiveSubControl } from '@/commons/utils/graphic-method';
import { EXTRA_SINGAL } from '@/commons/enums/extra-singal';

interface IProps {
    onSave: (cacheComponent: Component, radioCheck: boolean) => void
    onClose: () => void
    onCancel: () => void
}

const { TextArea } = Input;

export default function CreateComponentPanel(props: IProps) {
    const systemContext = useContext<SystemStatus>(SystemContext);

    const [comName, setComName] = useState<string>('');
    const [comExplain, setComExplain] = useState<string>('');
    const [radioActive, setRadioActive] = useState<boolean>(true);

    useEffect(() => {
        // setComName
        const formattedNumber = systemContext.componentCount.toString().padStart(3, '0');
        let name = systemContext.cacheComName ? systemContext.cacheComName : `组件#${formattedNumber}`;
        setComName(name);
        setComExplain(systemContext.cacheComExplain);
    }, [])

    const onSelectChange = (componentType) => {
        systemContext.setSelectedComponentType(componentType);
    }

    const onRadioClick = () => {
        setRadioActive(pre => !pre);
    }

    const alignBtnClick = () => {
        props.onClose();
        // 激活一个 创建临时坐标系，点一个点就结束的二级控制器
        onActiveSubControl(EXTRA_SUB_CONTROL.SELECTCOMAXIS.name);
        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisStart).dispatch();
    }

    const onCancel = () => {
        props.onCancel();
        systemContext.setCacheComponent({});
    }

    const onSave = () => {
        if (!comName || comName?.trim()?.length === 0) {
            message.warning("组件名不能为空");
            return;
        }
        systemContext.cacheComponent.name = comName;
        systemContext.cacheComponent.name = comExplain;
        systemContext.cacheComponent.componentType = systemContext.selectedComponentType;
        systemContext.setCacheComponent({ ...systemContext.cacheComponent });
        let par = {
            ...systemContext.cacheComponent,
            name: comName,
            explain: comExplain,
        }
        props.onSave(par, radioActive);
    }

    return (
        <div className="create-component-modal-container">
            <div className="top-panel">
                <div className="text">组件</div>
                <div className="cancel-icon" onClick={onCancel}><CloseSvg /></div>
            </div>
            <div className='content-panel'>
                <div className='content-row'>
                    <div className='content-row-left'>名称：</div>
                    <div className='content-row-right'>
                        <Input
                            style={{ width: 182 }}
                            value={comName}
                            onChange={(e) => {
                                setComName(e.target.value);
                                systemContext.setCacheComName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className='content-row'>
                    <div className='content-row-left'>描述：</div>
                    <div className='content-row-right'>
                        <TextArea
                            style={{ width: 257, height: 54, backgroundColor: '#fff' }}
                            value={comExplain}
                            onChange={(e) => {
                                setComExplain(e.target.value);
                                systemContext.setCacheComExplain(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className='content-row'>
                    <div className='content-row-left'>对齐：</div>
                    <div className='content-row-right'>
                        <div className='alignment-btn' onClick={() => alignBtnClick()}>设置组件轴</div>
                    </div>
                </div>
                <div className='content-row'>
                    <div className='content-row-left'>类型：</div>
                    <div className='content-row-right'>
                        <Select
                            popupClassName='side-title-pop'
                            value={systemContext?.selectedComponentType}
                            onChange={(e) => { onSelectChange(e) }}
                        >
                            {ComponentTypeList.map((item, index) => (
                                <Select.Option value={item.value} key={index}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className='content-row'>
                    <div className='content-row-radio' onClick={onRadioClick}>
                        {radioActive && <RadioCheckSvg />}
                        {!radioActive && <RadioSvg />}
                        <span>用组件替换选择内容</span>
                    </div>
                </div>
            </div>
            <div className="bottom-panel">
                <div className="btn save" onClick={onSave}>确定</div>
                <div className="btn cancel" onClick={onCancel}>取消</div>
            </div>
        </div>
    )
}