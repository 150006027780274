import { useCallback, useEffect, useState } from "react";
import localStore from "../store/local-store";

export interface PanelPath {
    path: string,
    name: string,
}

export interface SideStatus {
    selectedPanel: string
    setSelectedPanelValue: (value: string) => void
    selectedTaskUuid: string
    setSelectedTaskUuid: (value: string) => void
    panelSecRoute: string
    setPanelSecRoute: (value: string) => void

    panelPath: PanelPath[];
    currentPath: string;
    clearPath: () => void
    clearSidePanel: () => void
    formData: any;
    // setFormData: React.Dispatch<React.SetStateAction<any>>;
    setFormData: (value: string) => void,
    onPathClick: (pathIn: PanelPath) => void;
    navigate: (value: any) => void

    updateLayer: string
    setUpdateLayer: (uuid: string) => void

    lastPanel: string, 
    setLastPanel: (uuid: string) => void
}

export function useSideService() {
    const [selectedPanel, setSelectedPanel] = useState<string>(''); // 侧边打开的panel内容
    const [selectedTaskUuid, setSelectedTaskUuid] = useState<string>(); // 侧边打开任务panel，查看的任务详情uuid
    const [panelSecRoute, setPanelSecRoute] = useState<string>(); // 侧边栏模式

    const [panelPath, setPanelPath] = useState<PanelPath[]>([]); // 左侧面板的路径
    const [currentPath, setCurrentPath] = useState<string>(); // 左侧面板当前路径
    const [formData, setFormData] = useState<any>(); // 左侧面板缓存

    const [updateLayer, setUpdateLayer] = useState<string>(); // 图层变化

    const [lastPanel, setLastPanel] = useState<string>(null);

    const setSelectedPanelValue = useCallback((value: string) => {
        setSelectedPanel(value);        
        localStore.setSelectedPanel(value);
    }, [selectedPanel])


    const clearSidePanel = useCallback(() => {
        setSelectedPanel(null);
    }, [selectedPanel])

    useEffect(() => {
        if (panelPath.length > 0) {
            setCurrentPath(panelPath.at(-1).path);
        }
    }, [panelPath])

    const onPathClick = useCallback((pathIn: PanelPath) => {
        if (pathIn.path === panelPath.at(-1).path) return;
        const idx = panelPath.findIndex(item => item.path === pathIn.path);
        setPanelPath(panelPath.slice(0, idx + 1));
    }, [panelPath])

    const navigate = useCallback((value: any) => {
        if (value === -1) {
            setPanelPath(prevPath => prevPath.slice(0, -1));
        } else {
            setPanelPath(prevPath => [...prevPath, value]);
        }
    }, [panelPath])

    const clearPath = useCallback(() => {
        setPanelPath([]);
    }, [panelPath])

    return {
        selectedPanel,
        setSelectedPanelValue,
        selectedTaskUuid,
        setSelectedTaskUuid,
        panelSecRoute,
        setPanelSecRoute,

        panelPath, setPanelPath,
        currentPath,
        formData, setFormData,
        onPathClick,
        navigate,
        clearPath,
        clearSidePanel,
        updateLayer, setUpdateLayer,
        lastPanel, setLastPanel,
    }
}