import { Room } from "@/commons/interface/building/room"
import { StdResponse, bimAxios } from "@/config/axios"


export function getRooms(projectUuid: string, type: number, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/rooms`, {
        params: {
            ...params,
            type: type,
        }
    })
}

export function addRoom(projectUuid: string, data: Room, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/rooms`, data, {
        params: {
            ...params,
        }
    })
}


export function updateRoom(uuid: string, data: Room, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.put(`/rooms/${uuid}`, data, {
        params: {
            ...params,
        }
    })
}

export function deleteRoom(projectUuid: string, roomUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/rooms/${roomUuid}`, {
        params: {
            ...params,
        }
    })
}
