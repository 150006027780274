import localStore from '@/commons/store/local-store';
import axios, { AxiosResponse } from 'axios';

export interface StdResponse<T = any> extends AxiosResponse<T> {
    message: string
}

// 状态码定义
// const codeMessage = {
//     200: 'ok',
//     400: '请求数据错误',
//     401: '用户没有权限',
//     404: '请求的资源不存在',
//     500: '服务器异常',
//     503: '服务器过载或维护',
//     504: '网关超时',
// }

// TODO 将这里的baseUrl替换为apiFox的本地环境url，后续替换为上铁真实接口
const crhBaseUrl = 'http://127.0.0.1:4523/m1/4486279-0-default';

const bimAxios = axios.create({
    baseURL: '/api-asset-bim',
    timeout: 60000,
});

bimAxios.interceptors.request.use(
    config => {
        // 从本地localStore获取token
        let token = localStore.token || '';
        // let token = localStore.userId || '';
        config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
bimAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.status === 401) {
            // 未授权登录
            localStore.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
)

const manageAxios = axios.create({
    baseURL: '/api-asset-manage',
    timeout: 60000,
});

manageAxios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        let token = localStore.token || '';
        config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
manageAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.status === 401) {
            // 未授权登录
            localStore.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
)

const secureAxios = axios.create({
    baseURL: '/api-asset-secure',
    timeout: 60000,
});

secureAxios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        let token = localStore.token || '';
        config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
secureAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.status === 401) {
            // 未授权登录
            localStore.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
)

const crhAxios = axios.create({
    baseURL: crhBaseUrl,
    timeout: 60000,
});

crhAxios.interceptors.request.use(
    config => {
        // TODO 
        return config;
    },
    err => {
        console.log(err);
    }
)
crhAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.status === 401) {
            // 未授权登录
            localStore.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
)

export { bimAxios, manageAxios, secureAxios, crhAxios };
