import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { AxisT, BasicControl, DYMODE_MODE, Listeners, MOUSE, Plane, Point, TnEngineContext, TnEngineExtraContext, ViewEditor, dymode } from "pytha";
import { Vector3 } from "three";

export class SelectComAxisControl extends BasicControl {

    id = EXTRA_CTRL_TYPE.SELECT_COM_AXIS_CONTROL;

    needInit: boolean;

    step: number = 0

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    point_down: Point = new Point();
    point_move: Point = new Point();

    capturePoint: Vector3;
    // orthogonalPoint: Vector3;
    // polarPoint: Vector3;

    axisX: Vector3;
    axisY: Vector3;
    axisZ: Vector3;
    workPlane: Plane = new Plane();

    helpBlockAxis: AxisT = new AxisT();


    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.needInit = true;

        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();

        this.editor = editor;
        this.listeners = listeners;

        this.axisX = this.context.ucsContext.getAxisList().axisX.clone();
        this.axisY = this.context.ucsContext.getAxisList().axisY.clone();
        this.axisZ = this.context.ucsContext.getAxisList().axisZ.clone();

        this.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisStart).add(this.onSelectComAxisStart);

        console.log('SelectComAxisControl added')
    }

    dispose(): void {
        this.editor.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisStart).remove(this.onSelectComAxisStart);
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.editor.selectControl.setEnabled(true);
        this.editor.clampControl.enabled = true;
        if (this.helpBlockAxis && this.helpBlockAxis.viewObj && this.helpBlockAxis.viewObj.parent) {
            this.extraContext.sceneContext.scene.remove(this.helpBlockAxis.viewObj);
        }
        console.log('SelectComAxisControl remove')
    }

    onSelectComAxisStart = () => {
        let initData = {};
        this.listeners.signals.setInitControlData.dispatch(initData);

        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.editor.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
        this.editor.selectControl.setEnabled(false);
        this.editor.clampControl.enabled = false;
        this.editor.captureControl.enable = true;
        this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
        this.listeners.signals.sendToDymodeTip.dispatch('设置组件轴:');
        this.extraContext.stmodeContext.setStmode('设置组件轴');
        let position = new Vector3(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);
        this.initHelpAxis(position);
    }

    onPointerDown = (event) => {
        if (event.button === MOUSE.RIGHT) {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
            return;
        }
        if (event.button !== MOUSE.LEFT) return;
        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;

        let a = this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_down);
        this.point_down.set(a.x, a.y, a.z);
        this.point_down.copy(a);

        if (this.capturePoint) {
            this.point_down.copy(this.capturePoint);
        }

        this.firstStep();
    }

    onPointerMove = (event) => {
        // this.point_move.set(this.extraContext.mousePosContext.x, this.extraContext.mousePosContext.y, this.extraContext.mousePosContext.z);

        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
        this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_move, this.workPlane);

        if (this.capturePoint) {
            this.point_move.copy(this.capturePoint);
        }

        this.helpBlockAxis.origin.copy(this.point_move);
        this.helpBlockAxis.updateViewGeometry();
        this.listeners.signals.needRender.dispatch();
    }

    firstStep = () => {
        this.extraContext.sceneContext.scene.remove(this.helpBlockAxis.viewObj);
        this.listeners.getSignal(EXTRA_SINGAL.onSelectComAxisEnd).dispatch(this.helpBlockAxis.clone());
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    /**
     * 初始化辅助坐标系
     */
    initHelpAxis = (position: Vector3) => {
        this.axisX.copy(this.context.ucsContext.currentUcs.axisX);
        this.axisY.copy(this.context.ucsContext.currentUcs.axisY);
        this.axisZ.copy(this.context.ucsContext.currentUcs.axisZ);
        let onePoint = this.context.ucsContext.currentUcs.origin.clone();
        this.workPlane.setFromNormalAndCoplanarPoint(this.context.ucsContext.currentUcs.axisZ.clone(), onePoint);


        this.helpBlockAxis.origin.copy(position);
        this.helpBlockAxis.axisX.copy(this.axisX);
        this.helpBlockAxis.axisY.copy(this.axisY);
        this.helpBlockAxis.axisZ.copy(this.axisZ);
        this.helpBlockAxis.setWorkPlane(this.axisZ);


        this.helpBlockAxis.setValue('origin', position);
        this.extraContext.sceneContext.scene.add(this.helpBlockAxis.render());
    }

    initControl(data: any): void {
        this.onSelectComAxisStart();
    }

    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this.firstStep();
        }
    }
}