import { Button, Modal } from 'antd';
import './index.less'
import classNames from 'classnames';

export interface specialConfig {
    onOk?: () => void,
    onCancel?: () => void
    closable?: boolean
}

export interface baseConfig {
    onOk?: () => void,
    onCancel?: () => void
    closable?: boolean
    title?: string
    content?: string
    okText?: string
    cancelText?: string
    isDanger?: boolean
}

export default function TnModal() {

    const deleteModal = (props: specialConfig) => {
        const instance = Modal.confirm({
            title: '确认删除',
            content: '点击确认，删除所选中条目',
            wrapClassName: classNames({
                'delete-modal-panel tn-modal-panel': true,
                'closable-hidden': !props.closable
            }),
            // closable: false,
            icon: null,
            onCancel: () => {
                if (props.onCancel) {
                    props.onCancel();
                } else {
                    Modal.destroyAll();
                }
            },
            footer: (
                <div className='tn-modal-footer'>
                    <Button className='modal-footer-button danger' onClick={() => {
                        if (props.onOk) props.onOk();
                    }}>确认</Button>
                    <Button className='modal-footer-button second' onClick={() => {
                        if (props.onCancel) {
                            props.onCancel();
                        } else {
                            Modal.destroyAll();
                        }
                    }}>取消</Button>
                </div>
            )
        });
        return instance;
    }

    const revokeModal = (props: specialConfig) => {
        const instance = Modal.confirm({
            title: '撤回',
            content: '确认撤回后会将您已发起的模型文件合并请求进行撤回，建议确认无误后再进行撤回，确认要撤回吗？',
            wrapClassName: classNames({
                'delete-modal-panel tn-modal-panel': true,
                'closable-hidden': !props.closable
            }),
            // closable: false,
            icon: null,
            onCancel: () => {
                if (props.onCancel) {
                    props.onCancel();
                } else {
                    Modal.destroyAll();
                }
            },
            footer: (
                <div className='tn-modal-footer'>
                    <Button className='modal-footer-button primary' type="primary" onClick={() => {
                        if (props.onOk) props.onOk();
                    }}>确认</Button>
                    <Button className='modal-footer-button second' onClick={() => {
                        if (props.onCancel) {
                            props.onCancel();
                        } else {
                            Modal.destroyAll();
                        }
                    }}>取消</Button>
                </div>
            )
        });
        return instance;
    }

    const baseModal = (props: baseConfig) => {
        const title = props.title ? props.title : "删除";
        const content = props.content ? props.content : "确认删除后会将列表中您所选中的内容进行删除，建议确认无误后再进行删除，确认要删除吗？";
        const okText = props.okText ? props.okText : "确认";
        const cancelText = props.cancelText ? props.cancelText : "取消";
        const instance = Modal.confirm({
            title: title,
            content: content,
            wrapClassName: classNames({
                'delete-modal-panel tn-modal-panel': true,
                'closable-hidden': !props.closable
            }),
            // closable: false,
            icon: null,
            onCancel: () => {
                if (props.onCancel) {
                    props.onCancel();
                } else {
                    Modal.destroyAll();
                }
            },
            footer: (
                <div className='tn-modal-footer'>
                    <Button className={`modal-footer-button ${props.isDanger?'danger':'primary'}`} type="primary" onClick={() => {
                        if (props.onOk) props.onOk();
                    }}>{okText}</Button>
                    <Button className='modal-footer-button second' onClick={() => {
                        if (props.onCancel) {
                            props.onCancel();
                        } else {
                            Modal.destroyAll();
                        }
                    }}>{cancelText}</Button>
                </div>
            )
        });
        return instance;
    }

    return {
        deleteModal, revokeModal, baseModal
    }
}