import SideBarTool from "@/commons/components/side-bar-tool";
import { useEffect, useState } from "react";
import { SideBarBtnType } from "@/commons/enums/side-bar-btn-type";
import RoadNetWorkListItem from "./list-item";
import { RoadNetwork } from "@/commons/interface/building/road-network";
import { addRoadNetwork, deleteRoadNetworkByUuids, getRoadNetworkListByProjectUuid } from "@/api/building/road-network";
import { useParams } from "react-router-dom";

import "../../commons/styles/side-bar.less"
import "./index.less"
import { graphicStore } from "@/commons/store/graphic-store";
import { deletePrimitivesInGraphic } from "@/api/geometry/primitive";
import { message } from "antd";
import { BlockT, ModelBase } from "pytha";
import { BIM_BASE_CTRL_TYPE } from "tnbimbase";

import { LoadingOutlined } from "@ant-design/icons";

interface Iprops {
    width: number
}

export default function RoadNetworkPanel(prop: Iprops) {

    const { projectId } = useParams();

    const [roadNetworkList, setRoadNetworkList] = useState<RoadNetwork[]>([])

    const [selectItemIndexList, setSelectItemIndexList] = useState<string[]>([])

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        generateData()
    }, [])

    useEffect(() => {
        if (!graphicStore.extraContext || !graphicStore.extraContext.getCurrentViewEditor()) {
            //没有getCurrentViewEditor的话，说明是页面刷新，此时需要等待当前viewEditor被创建
            setLoading(true);
            setTimeout(() => {
                graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal('updateRoadNetworkList').add(updateRoadNetworkList);
                setLoading(false);
            }, 5000);
        } else {
            graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal('updateRoadNetworkList').add(updateRoadNetworkList)
        }
        return (() => {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('updateRoadNetworkList').remove(updateRoadNetworkList)
        })
    }, [roadNetworkList])

    useEffect(() => {
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onSelectChanged.add(onSelectChange)

        return (() => {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onSelectChanged.remove(onSelectChange)
        })
    }, [selectItemIndexList, roadNetworkList])

    const onSelectChange = (entities: ModelBase[]) => {
        if (entities?.length > 0) {
            for (let i = 0; i < entities.length; i++) {
                let midEntity = entities[i]
                if (midEntity.baseType == "BlockT") {
                    let block = midEntity as BlockT
                    if (block?.extraData?.roadUuid?.length > 0) {
                        setSelectItemIndexList([...selectItemIndexList, block?.extraData?.roadUuid])
                    }
                }
            }
        }
    }

    const updateRoadNetworkList = () => {
        generateData()
    }

    const generateData = () => {
        getRoadNetworkListByProjectUuid(projectId).then(res => {
            setRoadNetworkList(res.data || [])
            setSelectItemIndexList([])
        })
    }


    const onComfirmClick = () => {
        if (graphicStore.extraContext.getCurrentViewEditor().cmdControl?.currentControl?.id == BIM_BASE_CTRL_TYPE.ADD_ROAD_NETWORK) {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('roadNetworkData').dispatch({ type: 2, projectUuid: projectId })
        } else {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("ADDROADNETWORK");
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal('roadNetworkData').dispatch({ type: 2, projectUuid: projectId })
        }
    }

    const onAddClick = () => {
        addRoadNetwork(projectId, {}).then(res => {
            generateData()
        })
    }

    const onDeleteClick = () => {
        if (selectItemIndexList?.length > 0) {
            let deleteEntityUuids: string[] = []
            for (let i = 0; i < selectItemIndexList.length; i++) {
                let midRoad = roadNetworkList.find(item => item.uuid == selectItemIndexList[i])
                if (midRoad?.roadUuid?.length > 0) {
                    deleteEntityUuids.push(midRoad?.roadUuid)
                    let roadUuid = midRoad?.roadUuid
                    let model = graphicStore.extraContext.getCurrentViewEditor().entityDict[roadUuid]
                    if (model) {
                        graphicStore.extraContext.getCurrentViewEditor().removeObject(model)
                    }
                }
            }
            deleteRoadNetworkByUuids(selectItemIndexList).then(() => {
                generateData()
                return deletePrimitivesInGraphic(deleteEntityUuids)
            }).then(res => {
                message.success("成功删除")
            }).catch(e => {
                message.error("删除失败！")
            }).finally(() => {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
            })
        }
    }


    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[SideBarBtnType.ADD, SideBarBtnType.DELETE, SideBarBtnType.COMFIRM]}
                onConfirmBtnClick={onComfirmClick}
                onAddBtnClick={onAddClick}
                onDeleteBtnClick={onDeleteClick}
            />
            <div className="body">
                {loading && <LoadingOutlined />}
                {!loading &&
                    <>
                        <div className="title">
                            路网
                        </div>
                        <div className="detail">
                            <div className="detail-main">
                                {roadNetworkList?.map((item, index) => (
                                    <RoadNetWorkListItem
                                        key={index}
                                        data={item}
                                        selectList={selectItemIndexList}
                                        setSelectList={setSelectItemIndexList}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}