import './axis-draw.less'
import { ReactComponent as CloseSvg } from '@/commons/icons/panel-icon/close.svg'
import { Input, message } from "antd";
import { ReactComponent as CornerSvg } from '@/commons/icons/axis-net/corner.svg';
import { useState, forwardRef, useEffect, useRef } from "react";
import { floatVerification } from "@/commons/utils/antd-input";
import AxisNetCreateList, { AxisNetItem } from "./axis-create-list";
import LocalScene from "@/commons/components/local-scene";
import genUUID from "@/commons/utils/gen-uuid";
import { BUILDING_SINGAL, SCircleT, SDTextT, SDcalignedT, SLineT } from "tnbimbase";
import { Vector3 } from "three";
import { graphicStore } from "@/commons/store/graphic-store";
import { BlockT, DTextT, LineT, ModelBase, Point } from "pytha";
import { Storey } from "@/commons/interface/building/storey";
import { LayerType } from "@/commons/enums/layer-type";


interface IProps {
    storey: Storey
    onSave: (corner: string) => void
    onClose: () => void
}

const DeepListChild = forwardRef(AxisNetCreateList);
const BayListChild = forwardRef(AxisNetCreateList);

export default function AxisNetDraw(props: IProps) {

    const deepRef = useRef(null);
    const bayRef = useRef(null);
    const [blurFlag, setBlurFlag] = useState<string>(genUUID()); // 失焦信号，用于计算预览轴网
    const [corner, setCorner] = useState<string>("0");
    const [entities, setEntities] = useState<any[]>([]);

    useEffect(() => {
        reGenTempAxisNet(0, 0, 0, corner);
    }, [blurFlag])

    const reGenTempAxisNet = (baseX, baseY, baseZ, corner) => {
        let totalBayArr = parseNumberArrAccumulate(bayRef?.current?.list);
        let totalDeepArr = parseNumberArrAccumulate(deepRef?.current?.list);

        if ((!totalBayArr || totalBayArr?.length === 0) && (!totalDeepArr || totalDeepArr?.length === 0)) {
            let blackLine = new LineT(new Vector3(0, 0, 0), new Vector3(1, 1, 1));
            blackLine.color.set(0, 0, 0);
            blackLine.isColorByLayer = false;
            setEntities([blackLine]);
            return null;
        }

        let totalBay: number = totalBayArr?.length > 0 ? totalBayArr[totalBayArr?.length - 1] : 3300;
        let totalDeep: number = totalDeepArr?.length > 0 ? totalDeepArr[totalDeepArr?.length - 1] : 3300;

        let needAddPriArr = []; // 待渲染的图元
        let HorizontalAxisLine: SLineT[] = []; // 横线
        let VerticalAxisLine: SLineT[] = []; // 纵线

        // let baseX: number = Number(0);
        // let baseY: number = Number(0);
        // let baseZ: number = Number(0);

        if (totalBayArr?.length > 0) {
            let bayY = baseY + totalDeep;

            let start = new Vector3(baseX, baseY, baseZ);
            let end = new Vector3(baseX, bayY, baseZ);
            let newLine: SLineT = new SLineT(start, end);
            newLine.isGrid = true;
            newLine.isGridMain = true;
            newLine.gridUuid = newLine.uuid;
            newLine.xaxisAngle = 90 + (Number(corner) || 0);
            newLine.isColorByLayer = false;
            newLine.color.set(1, 0, 0);
            newLine.storeyUuid = props.storey.uuid;
            VerticalAxisLine.push(newLine);
            needAddPriArr.push(newLine);

            totalBayArr?.forEach((item) => {
                let start = new Vector3(baseX + item, baseY, baseZ);
                let end = new Vector3(baseX + item, bayY, baseZ);
                let newLine: SLineT = new SLineT(start, end);
                newLine.isGrid = true;
                newLine.isGridMain = true;
                newLine.gridUuid = newLine.uuid;
                newLine.xaxisAngle = 90 + (Number(corner) || 0);
                newLine.isColorByLayer = false;
                newLine.color.set(1, 0, 0);
                newLine.storeyUuid = props.storey.uuid;
                VerticalAxisLine.push(newLine);
                needAddPriArr.push(newLine);
            })
        }
        if (totalDeepArr?.length > 0) {
            let deepX = baseX + totalBay;

            let start = new Vector3(baseX, baseY, baseZ);
            let end = new Vector3(deepX, baseY, baseZ);
            let newLine: SLineT = new SLineT(start, end);
            newLine.isGrid = true;
            newLine.isGridMain = true;
            newLine.gridUuid = newLine.uuid;
            newLine.xaxisAngle = 0 + (Number(corner) || 0);
            newLine.isColorByLayer = false;
            newLine.color.set(1, 0, 0);
            newLine.storeyUuid = props.storey.uuid;
            HorizontalAxisLine.push(newLine);
            needAddPriArr.push(newLine);

            totalDeepArr?.forEach((item) => {
                let start = new Vector3(baseX, baseY + item, baseZ);
                let end = new Vector3(deepX, baseY + item, baseZ);
                let newLine: SLineT = new SLineT(start, end);
                newLine.isGrid = true;
                newLine.isGridMain = true;
                newLine.gridUuid = newLine.uuid;
                newLine.xaxisAngle = 0 + (Number(corner) || 0);
                newLine.isColorByLayer = false;
                newLine.color.set(1, 0, 0);
                newLine.storeyUuid = props.storey.uuid;
                HorizontalAxisLine.push(newLine);
                needAddPriArr.push(newLine);
            })
        }
        // 添加看轴线的轴号的两条线和圆
        HorizontalAxisLine?.forEach((item, idx) => {
            addExtendLineAndCircleX(item, baseX + totalBay, baseX, needAddPriArr);
        })
        VerticalAxisLine?.forEach((item, idx) => {
            addExtendLineAndCircleY(item, baseY + totalDeep, baseY, needAddPriArr);
        })
        // 添加标注
        if (HorizontalAxisLine?.length > 1) {
            for (let i = 0; i < HorizontalAxisLine?.length - 1; i++) {
                let dimensionMinX = baseX - 2600;
                let dimensionMaxX = baseX + totalBay + 2600;

                let firstLine: LineT = HorizontalAxisLine[i];
                let secondLine: LineT = HorizontalAxisLine[i + 1];

                let firstLineY = firstLine._geo.start.y;
                let secondLineY = secondLine._geo.start.y;

                let firstPoint1 = new Vector3(dimensionMinX, firstLineY, 0);
                let secondPoint1 = new Vector3(dimensionMinX, secondLineY, 0);

                let firstPoint2 = new Vector3(dimensionMaxX, firstLineY, 0);
                let secondPoint2 = new Vector3(dimensionMaxX, secondLineY, 0);

                let direction = new Vector3(-0.01, 0, 0);
                let normal = new Vector3(0, 0, 1);

                let dim1 = new SDcalignedT(firstPoint1, secondPoint1, direction, normal, null, true);
                dim1.leftGridUuid = firstLine.uuid;
                dim1.rightGridUuid = secondLine.uuid;
                dim1.isColorByLayer = false;
                dim1.color.set(1, 0, 0);
                dim1.storeyUuid = props.storey.uuid;
                let dim2 = new SDcalignedT(firstPoint2, secondPoint2, direction, normal, null, true);
                dim2.leftGridUuid = firstLine.uuid;
                dim2.rightGridUuid = secondLine.uuid;
                dim2.isColorByLayer = false;
                dim2.color.set(1, 0, 0);
                dim2.storeyUuid = props.storey.uuid;
                needAddPriArr.push(dim1);
                needAddPriArr.push(dim2);
            }
        }

        if (VerticalAxisLine?.length > 1) {
            for (let i = 0; i < VerticalAxisLine?.length - 1; i++) {
                let dimensionMinY = baseY - 2600;
                let dimensionMaxY = baseY + totalDeep + 2600;

                let firstLine: LineT = VerticalAxisLine[i];
                let secondLine: LineT = VerticalAxisLine[i + 1];

                let firstLineX = firstLine._geo.start.x;
                let secondLineX = secondLine._geo.start.x;

                let firstPoint1 = new Vector3(firstLineX, dimensionMinY, 0);
                let secondPoint1 = new Vector3(secondLineX, dimensionMinY, 0);

                let firstPoint2 = new Vector3(firstLineX, dimensionMaxY, 0);
                let secondPoint2 = new Vector3(secondLineX, dimensionMaxY, 0);

                let direction = new Vector3(0, 0.01, 0);
                let normal = new Vector3(0, 0, 1);

                let dim1 = new SDcalignedT(firstPoint1, secondPoint1, direction, normal, null, true);
                dim1.leftGridUuid = firstLine.uuid;
                dim1.rightGridUuid = secondLine.uuid;
                dim1.isColorByLayer = false;
                dim1.color.set(1, 0, 0);
                dim1.storeyUuid = props.storey.uuid;
                let dim2 = new SDcalignedT(firstPoint2, secondPoint2, direction, normal, null, true);
                dim2.leftGridUuid = firstLine.uuid;
                dim2.rightGridUuid = secondLine.uuid;
                dim2.isColorByLayer = false;
                dim2.color.set(1, 0, 0);
                dim2.storeyUuid = props.storey.uuid;
                needAddPriArr.push(dim1);
                needAddPriArr.push(dim2);
            }
        }

        // // 添加轴号
        let hFlag = deepRef.current.axisNumber;
        HorizontalAxisLine?.forEach(line => {
            let circles = needAddPriArr?.filter(pri => pri.type == 'SCircleT' && pri.gridUuid === line.uuid);
            let circleCenters = circles?.map((cir: SCircleT) => cir._geoCircle.center.clone()) || [];

            if (circleCenters?.length > 0) {
                circleCenters.forEach(center => {
                    let dtext1 = new SDTextT(hFlag, center, graphicStore.extraContext.fontContext.state.dimFontData, graphicStore.context.renderContext.gridCircleSize * 0.533);
                    dtext1._getMeshVerticesBuffer();
                    let min = dtext1._boundingBox.min;
                    let max = dtext1._boundingBox.max;
                    let hl = Math.abs((max.x - min.x) / 2);
                    let hb = Math.abs((max.y - min.y) / 2);
                    let newCenter = new Vector3((center.x - hl), (center.y - hb), center.z);

                    let dtext2 = new SDTextT(hFlag, newCenter, graphicStore.extraContext.fontContext.state.dimFontData, graphicStore.context.renderContext.gridCircleSize * 0.533);
                    dtext2.isGrid = true;
                    dtext2.gridUuid = line.uuid;
                    dtext2.isColorByLayer = false;
                    dtext2.color.set(1, 0, 0);
                    dtext2.storeyUuid = props.storey.uuid;
                    needAddPriArr.push(dtext2);
                })
            }
            hFlag = getNextLetter(hFlag);
        })

        let vFlag = bayRef.current.axisNumber;
        VerticalAxisLine?.forEach(line => {

            let circles = needAddPriArr?.filter(pri => pri.type == 'SCircleT' && pri.gridUuid === line.uuid);
            let circleCenters = circles?.map((cir: SCircleT) => cir._geoCircle.center.clone()) || [];

            if (circleCenters?.length > 0) {
                circleCenters.forEach(center => {
                    let dtext1 = new DTextT(vFlag, center, graphicStore.extraContext.fontContext.state.dimFontData, graphicStore.context.renderContext.gridCircleSize * 0.533);
                    dtext1._getMeshVerticesBuffer();
                    let min = dtext1._boundingBox.min;
                    let max = dtext1._boundingBox.max;
                    let hl = Math.abs((max.x - min.x) / 2);
                    let hb = Math.abs((max.y - min.y) / 2);
                    let newCenter = new Vector3((center.x - hl), (center.y - hb), center.z);
                    let dtext2 = new SDTextT(vFlag, newCenter, graphicStore.extraContext.fontContext.state.dimFontData, graphicStore.context.renderContext.gridCircleSize * 0.533);
                    dtext2.isGrid = true;
                    dtext2.gridUuid = line.uuid;
                    dtext2.isColorByLayer = false;
                    dtext2.color.set(1, 0, 0);
                    dtext2.storeyUuid = props.storey.uuid;
                    needAddPriArr.push(dtext2);
                })
            }
            vFlag = (Number(vFlag) + 1).toString();
        })


        if (Number(corner) !== 0) {
            needAddPriArr?.forEach((item: ModelBase) => {
                item.rotate(new Point(baseX, baseY, baseZ), graphicStore.context.ucsContext.getAxisList().axisZ, (Number(corner) * Math.PI / 180));
            })
        }
        setEntities(needAddPriArr);
        return needAddPriArr;
    }

    const parseNumberArrAccumulate = (params: AxisNetItem[]): number[] => {
        return params?.reduce((acc: number[], item: AxisNetItem) => {
            let spacing: number = Number(item.spacing) || 0;
            let count: number = Number(item.count) || 0;

            if (spacing !== 0 && count !== 0) {
                let lastValue = acc.length > 0 ? acc[acc.length - 1] : 0;
                for (let i = 1; i <= count; i++) {
                    lastValue += spacing;
                    acc.push(lastValue);
                }
            }

            return acc;
        }, []);
    };

    // 添加看轴线的轴号的两条线、两个圆
    const addExtendLineAndCircleX = (item, maxX, minX, needAddPriArr) => {

        let gridUuid = item.uuid;
        let lineDistance = graphicStore.context.renderContext.gridLineDistanceToCircle;
        let radius = graphicStore.context.renderContext.gridCircleSize;
        let circleDistance = lineDistance + radius;

        let start = item._geo.start.x < item._geo.end.x ? item._geo.start : item._geo.end;
        let end = item._geo.start.x > item._geo.end.x ? item._geo.start : item._geo.end;

        let newPoint1 = new Vector3(maxX + lineDistance, end.y, end.z);
        let endExtendLine = new SLineT(end, newPoint1);
        endExtendLine.isGrid = true;
        endExtendLine.isDimension = true;
        endExtendLine.gridUuid = gridUuid;
        endExtendLine.isColorByLayer = false;
        endExtendLine.color.set(1, 0, 0);
        endExtendLine.storeyUuid = props.storey.uuid;
        needAddPriArr.push(endExtendLine);

        let newPoint2 = new Vector3(minX - lineDistance, start.y, start.z);
        let startExtendLine = new SLineT(start, newPoint2);
        startExtendLine.isGrid = true;
        startExtendLine.isDimension = true;
        startExtendLine.gridUuid = gridUuid;
        startExtendLine.isColorByLayer = false;
        startExtendLine.color.set(1, 0, 0);
        startExtendLine.storeyUuid = props.storey.uuid;
        needAddPriArr.push(startExtendLine);

        let circleCenter1 = new Vector3(maxX + circleDistance, end.y, end.z);
        let circle1 = new SCircleT(circleCenter1, radius);
        circle1.isGrid = true;
        circle1.gridUuid = gridUuid;
        circle1.isColorByLayer = false;
        circle1.color.set(1, 0, 0);
        circle1.storeyUuid = props.storey.uuid;
        needAddPriArr.push(circle1);

        let circleCenter2 = new Vector3(minX - circleDistance, start.y, start.z);
        let circle2 = new SCircleT(circleCenter2, radius);
        circle2.isGrid = true;
        circle2.gridUuid = gridUuid;
        circle2.isColorByLayer = false;
        circle2.color.set(1, 0, 0);
        circle2.storeyUuid = props.storey.uuid;
        needAddPriArr.push(circle2);
    }
    const addExtendLineAndCircleY = (item, maxY, minY, needAddPriArr) => {
        let gridUuid = item.uuid;
        let lineDistance = graphicStore.context.renderContext.gridLineDistanceToCircle;
        let radius = graphicStore.context.renderContext.gridCircleSize;
        let circleDistance = lineDistance + radius;


        let start = item._geo.start.y < item._geo.end.y ? item._geo.start : item._geo.end;
        let end = item._geo.start.y > item._geo.end.y ? item._geo.start : item._geo.end;

        let newPoint1 = new Vector3(end.x, maxY + lineDistance, end.z);
        let endExtendLine = new SLineT(end, newPoint1);
        endExtendLine.isGrid = true;
        endExtendLine.isDimension = true;
        endExtendLine.gridUuid = gridUuid;
        endExtendLine.isColorByLayer = false;
        endExtendLine.color.set(1, 0, 0);
        endExtendLine.storeyUuid = props.storey.uuid;
        needAddPriArr.push(endExtendLine);

        let newPoint2 = new Vector3(start.x, minY - lineDistance, start.z);
        let startExtendLine = new SLineT(start, newPoint2);
        startExtendLine.isGrid = true;
        startExtendLine.isDimension = true;
        startExtendLine.gridUuid = gridUuid;
        startExtendLine.isColorByLayer = false;
        startExtendLine.color.set(1, 0, 0);
        startExtendLine.storeyUuid = props.storey.uuid;
        needAddPriArr.push(startExtendLine);

        let circleCenter1 = new Vector3(end.x, maxY + circleDistance, end.z);
        let circle1 = new SCircleT(circleCenter1, radius);
        circle1.isGrid = true;
        circle1.gridUuid = gridUuid;
        circle1.isColorByLayer = false;
        circle1.color.set(1, 0, 0);
        circle1.storeyUuid = props.storey.uuid;
        needAddPriArr.push(circle1);

        let circleCenter2 = new Vector3(start.x, minY - circleDistance, start.z);
        let circle2 = new SCircleT(circleCenter2, radius);
        circle2.isGrid = true;
        circle2.gridUuid = gridUuid;
        circle2.isColorByLayer = false;
        circle2.color.set(1, 0, 0);
        circle2.storeyUuid = props.storey.uuid;
        needAddPriArr.push(circle2);
    }

    const cornerBlur = () => {
        let newCorner = floatVerification(corner);

        if (newCorner === '') {
            setCorner(newCorner);
            return;
        }
        newCorner = (((Number(newCorner) % 360) + 360) % 360).toString();
        setCorner(newCorner);
        setBlurFlag(genUUID());
    }

    const onSave = () => {
        if (entities?.length >= 3) {
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch("ASSEMBLEAXISNETCONTROL");
            let blockT = new BlockT();
            blockT.setEntities([...entities]);
            let re = reGenTempAxisNet(0, 0, 0, corner);
            // 标底高
            let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.AXIS_NET)?.uuid || null;
            graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal(BUILDING_SINGAL.onAssembleAxisNet)
                .dispatch(blockT, re, new Vector3(0, 0, 0), layerUuid, (props.storey?.bottomElevation * 1000) || 0);
        }
        props.onSave(corner);
    }

    // 字母轴获取下一个字母
    const getNextLetter = (input: string) => {
        if (!input || !input.match(/^[A-Z]+$/)) {
            message.warning('输入不能为空且只能输入英文字母');
            return null;
        }

        const len = input.length;
        let carry = 1;
        let result = '';

        for (let i = len - 1; i >= 0; i--) {
            const charCode = input.charCodeAt(i) + carry;
            if (charCode > 'Z'.charCodeAt(0)) {
                result = 'A' + result;
                carry = 1;
            } else {
                result = String.fromCharCode(charCode) + result;
                carry = 0;
            }
        }

        if (carry) {
            // 处理最高位需要进位的情况
            result = 'A' + result;
        }

        return result;
    }

    const onCancel = () => {
        props.onClose();
    }

    return (
        <div className="axis-net-draw-container">
            <div className="top-panel">
                <div className="text">绘制轴网</div>
                <div className="cancel-icon" onClick={onCancel}><CloseSvg /></div>
            </div>
            <div className="main-panel">
                <LocalScene
                    entities={entities}
                    color="#000"
                    react_id={"preview-axis-net"}
                    width={257}
                    height={261}
                    top={-0.01}
                    left={0.1}
                />

                <DeepListChild ref={deepRef} title="X向轴间距" defaultAxisNumber="A" setBlurFlag={setBlurFlag} />
                <BayListChild ref={bayRef} title="Y向轴间距" defaultAxisNumber="1" setBlurFlag={setBlurFlag} />
            </div>
            <div className="enter-panel">
                <div className="text">转角（度）：</div>
                <Input
                    value={corner}
                    onChange={(e) => setCorner(e.target.value)}
                    onBlur={() => cornerBlur()}
                    suffix={<CornerSvg />}
                />
            </div>
            <div className="bottom-panel">
                <div className="btn save" onClick={onSave}>确定</div>
                <div className="btn cancel" onClick={onCancel}>取消</div>
            </div>
        </div>
    )
}