import localStore from "@/commons/store/local-store";
import { StdResponse, bimAxios } from "@/config/axios";

export function saveLayer(data: any, params?: {}): Promise<StdResponse<any>> {
    const projectUuid = localStore.projectId;
    return bimAxios.post(`/projects/${projectUuid}/layers`, data, {
        params: {
            ...params,
        }
    })
}

export function findLayersInProjectUuid(projectUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/layers`, {
        params: {
            ...params,
        }
    })
}


export function deleteLayer(layerUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/layers/${layerUuid}`, {
        params: {
            ...params,
        }
    })
}
export function updateLayer(data: any, params?: {}): Promise<StdResponse<any>> {
    const projectUuid = localStore.projectId;
    return bimAxios.put(`/projects/${projectUuid}/layers`, data, {
        params: {
            ...params,
        }
    })
}
