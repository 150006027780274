import SideBarTool from "@/commons/components/side-bar-tool";
import { Input, Select, message } from "antd";
import { useEffect, useState } from "react";
import './index.less';
import { ExtrudeSolidT, Line, LineType, Point, Polygon, RenderMode } from "pytha";
import { Vector3 } from "three";
import { DoorT } from "tnbimbase";
import { graphicStore } from "@/commons/store/graphic-store";
import { LayerType } from "@/commons/enums/layer-type";
import genUUID from "@/commons/utils/gen-uuid";
import { Layer } from "@/commons/interface/layer";
import { useParams } from "react-router-dom";
import { saveLayer } from "@/api/geometry/layer";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import LocalScene from "@/commons/components/local-scene";
import { getTextures } from "@/api/spec-common/texture";
import { TEXTURE_CATEGORY } from "@/commons/enums/texture";
import { Texture } from "@/commons/interface/texture";

export default function DoorPanel() {
    const { projectId } = useParams();
    const [selectedType, setSelectedType] = useState<string>('alloy-glass');
    const [height, setHeight] = useState<number>(2000); // 门洞高
    const [leftWidth, setLeftWidth] = useState<number>(800); // 左门扇宽
    const [rightWidth, setRightWidth] = useState<number>(800); // 右门扇宽
    const [width, setWidth] = useState<number>(1600); // 门洞宽
    const [entities, setEntities] = useState<any[]>([]);
    const [aluminiumAlloyTexture, setAluminiumAlloyTexture] = useState<string>(''); // 铝合金材质
    const [glassTexture, setGlassTexture] = useState<string>(''); // 玻璃材质
    const [woodTexture, setWoodTexture] = useState<string>(''); // 木材质
    const [metalTexture, setMetalTexture] = useState<string>(''); // 金属材质

    useEffect(() => {
        // 查询铝合金、玻璃、木、金属的材质
        getTextures(projectId, { category: TEXTURE_CATEGORY.aluminiumAlloy.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setAluminiumAlloyTexture(resData[0].uuid);
            }
        })
        getTextures(projectId, { category: TEXTURE_CATEGORY.glass.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setGlassTexture(resData[0].uuid);
            }
        })
        getTextures(projectId, { category: TEXTURE_CATEGORY.wood.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setWoodTexture(resData[0].uuid);
            }
        })
        getTextures(projectId, { category: TEXTURE_CATEGORY.metal.key }).then(res => {
            let resData: Texture[] = res.data || [];
            if (resData.length > 0) {
                setMetalTexture(resData[0].uuid);
            }
        })
    }, [])

    useEffect(() => {
        updateScene();
    }, [height, width])

    const updateScene = () => {
        if (!!!height) {
            setEntities([]);
            return null;
        }
        if (!!!width) {
            setEntities([]);
            return null;
        }
        // 构造门图元
        // 门框，门框宽50mm，深100mm
        let frameWidth = 50;
        let frameDeep = 100;
        let direction = new Vector3(0, 0, -1);
        let framePolygon = new Polygon([], new Vector3(1, 0, 0), new Point(0, 0, 0));
        let framePoints: Point[] = [
            new Point(0, 0, 0),
            new Point(0, height + frameWidth, 0),
            new Point(width + frameWidth * 2, height + frameWidth, 0),
            new Point(width + frameWidth * 2, 0, 0),
            new Point(width + frameWidth, 0, 0),
            new Point(width + frameWidth, height, 0),
            new Point(frameWidth, height, 0),
            new Point(frameWidth, 0, 0)
        ]
        let curves = [];
        for (let i = 0; i < framePoints.length; i++) {
            if (i != framePoints.length - 1) {
                curves.push(new Line(framePoints[i], framePoints[i + 1]));
            } else {
                curves.push(new Line(framePoints[i], framePoints[0]));
            }
        }
        framePolygon.set(curves);
        let frameSolid = new ExtrudeSolidT(framePolygon, direction, frameDeep);
        if (selectedType == 'alloy-glass') {
            if (aluminiumAlloyTexture) {
                frameSolid.materialMap = { [aluminiumAlloyTexture]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] };
                frameSolid.needUpdate = true;
                frameSolid.needTextureMaterial = true;
            }
        } else if (selectedType == 'wood') {
            if (woodTexture) {
                frameSolid.materialMap = { [woodTexture]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] };
                frameSolid.needUpdate = true;
                frameSolid.needTextureMaterial = true;
            }
        } else if (selectedType == 'metal') {
            if (metalTexture) {
                frameSolid.materialMap = { [metalTexture]: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] };
                frameSolid.needUpdate = true;
                frameSolid.needTextureMaterial = true;
            }
        }
        let doorEntities = [];
        doorEntities.push(frameSolid);
        // 左门，门厚50mm
        let doorDeep = 50;
        if (!!leftWidth) {
            let leftDoorPolygon = new Polygon([], new Vector3(1, 0, 0), new Point(frameWidth, (frameDeep - doorDeep) / 2, 0));
            let z = -((frameDeep - doorDeep) / 2);
            let leftDoorPoints: Point[] = [
                new Point(frameWidth, 0, z),
                new Point(frameWidth, height, z),
                new Point(frameWidth + leftWidth, height, z),
                new Point(frameWidth + leftWidth, 0, z),
            ]
            let curves = [];
            for (let i = 0; i < leftDoorPoints.length; i++) {
                if (i != leftDoorPoints.length - 1) {
                    curves.push(new Line(leftDoorPoints[i], leftDoorPoints[i + 1]));
                } else {
                    curves.push(new Line(leftDoorPoints[i], leftDoorPoints[0]));
                }
            }
            leftDoorPolygon.set(curves);
            let leftDoorSolid = new ExtrudeSolidT(leftDoorPolygon, direction, doorDeep);
            if (selectedType == 'alloy-glass') {
                if (glassTexture) {
                    leftDoorSolid.materialMap = { [glassTexture]: [0, 1] };
                    leftDoorSolid.needUpdate = true;
                    leftDoorSolid.needTextureMaterial = true;
                }
            } else if (selectedType == 'wood') {
                if (woodTexture) {
                    leftDoorSolid.materialMap = { [woodTexture]: [0, 1] };
                    leftDoorSolid.needUpdate = true;
                    leftDoorSolid.needTextureMaterial = true;
                }
            } else if (selectedType == 'metal') {
                if (metalTexture) {
                    leftDoorSolid.materialMap = { [metalTexture]: [0, 1] };
                    leftDoorSolid.needUpdate = true;
                    leftDoorSolid.needTextureMaterial = true;
                }
            }
            doorEntities.push(leftDoorSolid);
        }
        // 右门，门厚50mm
        if (!!rightWidth) {
            let leftDoorWidth = leftWidth || 0;
            let rightDoorPolygon = new Polygon([], new Vector3(1, 0, 0), new Point(frameWidth + leftDoorWidth, (frameDeep - doorDeep) / 2, 0));
            let z = -((frameDeep - doorDeep) / 2);
            let rightDoorPoints: Point[] = [
                new Point(frameWidth + leftDoorWidth, 0, z),
                new Point(frameWidth + leftDoorWidth, height, z),
                new Point(frameWidth + leftDoorWidth + rightWidth, height, z),
                new Point(frameWidth + leftDoorWidth + rightWidth, 0, z),
            ]
            let curves = [];
            for (let i = 0; i < rightDoorPoints.length; i++) {
                if (i != rightDoorPoints.length - 1) {
                    curves.push(new Line(rightDoorPoints[i], rightDoorPoints[i + 1]));
                } else {
                    curves.push(new Line(rightDoorPoints[i], rightDoorPoints[0]));
                }
            }
            rightDoorPolygon.set(curves);
            let rightDoorSolid = new ExtrudeSolidT(rightDoorPolygon, direction, doorDeep);
            if (selectedType == 'alloy-glass') {
                if (glassTexture) {
                    rightDoorSolid.materialMap = { [glassTexture]: [0, 1] };
                    rightDoorSolid.needUpdate = true;
                    rightDoorSolid.needTextureMaterial = true;
                }
            } else if (selectedType == 'wood') {
                if (woodTexture) {
                    rightDoorSolid.materialMap = { [woodTexture]: [0, 1] };
                    rightDoorSolid.needUpdate = true;
                    rightDoorSolid.needTextureMaterial = true;
                }
            } else if (selectedType == 'metal') {
                if (metalTexture) {
                    rightDoorSolid.materialMap = { [metalTexture]: [0, 1] };
                    rightDoorSolid.needUpdate = true;
                    rightDoorSolid.needTextureMaterial = true;
                }
            }
            doorEntities.push(rightDoorSolid);
        }
        setEntities(doorEntities);
        return doorEntities;
    }

    const onAssembleDoor = () => {
        if (!!!height) {
            message.warning("请输入门洞高");
            return;
        }
        if (!!!width) {
            message.warning("请输入左门扇宽或右门扇宽");
            return;
        }
        let entities = updateScene();
        // 门框，门框宽50mm，深100mm
        let frameWidth = 50;
        let totalWidth = frameWidth + (leftWidth || 0) + (rightWidth || 0) + frameWidth;
        let totalHeight = height + frameWidth;
        let door = new DoorT(new Point(0, 0, 0), entities, '门', new Vector3(0, 0, 1), new Vector3(1, 0, 0), totalWidth, totalHeight);
        if (!graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl || graphicStore.extraContext.getCurrentViewEditor().cmdControl.currentControl.id !== EXTRA_CTRL_TYPE.ASSEMBLE_DOOR) {
            // 激活布置门的二级控制器
            graphicStore.extraContext.getCurrentViewEditor().cmdControl.activateSubCommandControl("ASSEMBLEDOOR");
            let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.DOOR)?.uuid || null;
            if (!layerUuid) {
                // 不存在门图层，新建门图层
                let uuid = genUUID();
                let newLayer: Layer = {
                    uuid: uuid,
                    name: "门",
                    color: "#c9c9c9",
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    lock: false,
                    hidden: false,
                    projectUuid: projectId,
                    originLayer: false,
                    type: LayerType.DOOR,
                };
                saveLayer(newLayer).then(res => {
                    if (res.status === 200) {
                        graphicStore.context.layerContext.addLayer(newLayer);
                        graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleDoor).dispatch(newLayer.uuid, door);
                    }
                }).catch(err => {
                    message.error("无法置为门图层");
                    console.log(err);
                    let currentLayerUuid = graphicStore.context.layerContext.currentLayerUuid;
                    graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleDoor).dispatch(currentLayerUuid, door);

                })
            } else {
                graphicStore.extraContext.listeners.getSignal(EXTRA_SINGAL.onAssembleDoor).dispatch(layerUuid, door);
            }
        }
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    门
                </div>
                <div className="detail door-panel">
                    <div className="row">
                        <div className="label">类型:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectedType}
                            // allowClear
                            onChange={(value) => setSelectedType(value)}
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            <Select.Option value="alloy-glass">铝合金玻璃</Select.Option>
                            <Select.Option value="wood">木</Select.Option>
                            <Select.Option value="metal">金属</Select.Option>
                        </Select>
                    </div>
                    <div className="title-row">
                        <div className="title-row-text">尺寸</div>
                    </div>
                    <div className="row">
                        <div className="label">门洞高:</div>
                        <Input
                            className="input-box"
                            value={height}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setHeight(parseInt(value))
                                } else {
                                    if (value == '') {
                                        setHeight(null);
                                    } else {
                                        setHeight(0)
                                    }
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    <div className="row">
                        <div className="label">门洞宽:</div>
                        <Input
                            readOnly
                            className="input-box onlyread"
                            value={width}
                            suffix="mm"
                        />
                    </div>
                    <div className="row">
                        <div className="label">左门扇宽:</div>
                        <Input
                            className="input-box"
                            value={leftWidth}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setLeftWidth(parseInt(value))
                                    setWidth(parseInt(value) + rightWidth);
                                } else {
                                    if (value == '') {
                                        setLeftWidth(null);
                                    } else {
                                        setLeftWidth(0)
                                    }
                                    setWidth(rightWidth);
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    <div className="row compact">
                        <div className="label">右门扇宽:</div>
                        <Input
                            className="input-box"
                            value={rightWidth}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (!isNaN(parseInt(value))) {
                                    setRightWidth(parseInt(value))
                                    setWidth(parseInt(value) + leftWidth);
                                } else {
                                    if (value == '') {
                                        setRightWidth(null);
                                    } else {
                                        setRightWidth(0)
                                    }
                                    setWidth(leftWidth);
                                }
                            }}
                            suffix="mm"
                        />
                    </div>
                    {/* <div className="schematic-diagram">
                        <Image width={215} height={176} preview src={DoorImg} />
                    </div> */}
                    <div className="show-scene">
                        <LocalScene
                            entities={entities}
                            color="#F9FAFA"
                            react_id={"door-panel-scene"}
                            width={215}
                            height={176}
                            top={1}
                            left={1}
                            onlyRenderType={RenderMode.WIREFRAME}
                        />
                    </div>
                    <div className="ope-area">
                        <div className="primary-btn" onClick={onAssembleDoor}>确定</div>
                        <div className="secondary-btn"><div className="text">取消</div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}