import { useState } from "react";
import "./main-dropdown-box-item.less";
import { ReactComponent as DownArrowSvg } from './icons/down-arrow.svg'
import { ReactComponent as UpArrowSvg } from './icons/up-arrow.svg'
import { SubDropdownBoxItem } from "./sub-dropdown-box-item";
import classNames from 'classnames'


interface Iprops {
    selected: boolean //是否被选中
    onClick: () => void
    name: string
    dataList: { uuid: string, name: string, isSelected: boolean }[]
    setSelectedEntity: (uuid: string, selected: boolean) => void
    updateName: (uuid: string, name: string) => void
    type: string
    title: string
}

export function MainDropdownBoxItem(props: Iprops) {
    const [expand, setExpand] = useState<boolean>(false);

    const onClickExpand = () => {
        setExpand(!expand);
    }

    return (
        <div className="main-dropdown-box-item">
            {!expand &&
                <>
                    <div className={classNames({
                        "main-dropdown-box-item-top": true,
                        "selected": props.selected
                    })} onClick={props.onClick}>
                        <span style={{ whiteSpace: "pre" }}>{props.name}</span>
                        <div className="right-svg" onClick={onClickExpand}>
                            <UpArrowSvg className="svg" />
                        </div>
                    </div>
                </>
            }
            {expand &&
                <>
                    <div className={classNames({
                        "main-dropdown-box-item-top": true,
                        "selected": props.selected
                    })} onClick={props.onClick}>
                        <span style={{ whiteSpace: "pre" }}>{props.name}</span>
                        <div className="right-svg" onClick={onClickExpand}>
                            <DownArrowSvg className="svg" />
                        </div>
                    </div>
                    <div className="main-dropdown-box-item-expand">
                        {props.dataList.map((item, index) => (
                            <SubDropdownBoxItem
                                key={index}
                                name={item.name}
                                uuid={item.uuid}
                                selected={item.isSelected}
                                setSelected={props.setSelectedEntity}
                                updateName={props.updateName}
                                type={props.type}
                                title={props.title}
                            />
                        ))}
                    </div>
                </>
            }


        </div>
    )

}