import { SIDE_TYPE } from "@/commons/enums/side-type";
import localStore from "@/commons/store/local-store";
import { GroundPanel } from "@/panel/building/ground";
import { FloorPanel } from "@/panel/building/floor";
import FencePanel from "@/panel/fence";
import RoadNetworkPanel from "@/panel/road-network-panel";
import RoadPanel from "@/panel/road-panel";
import AxisNetPanel from "@/panel/axis-net";
import { useEffect, useState } from "react";
import { CeilingPanel } from "@/panel/building/ceiling";
import WallPanel from "@/panel/wall";
import ColumnPanel from "@/panel/column";
import ElevatorCarPanel from "@/panel/elevator-car";
import TexturePanel from "@/panel/texture";
import RoomPanel from "@/panel/room";
import ElevatorPanel from "@/panel/elevator";
import PumpRoomPanel from "@/panel/pump-room";
import FireControlRoomPanel from "@/panel/fire-control-room";
import ParkingSpace from "@/panel/parking-space";
import NoMotorParkingSpace from "@/panel/no-motor-parking-space";
import { StoreyPanel } from "@/panel/building/storey";
import { PatrolPointPanel } from "@/panel/electricity/patrol-point";
import HolePanel from "@/panel/hole";
import DoorPanel from "@/panel/door";
import WindowPanel from "@/panel/window";
import TreePanel from "@/panel/tree";
import { SmokeDetectorPanel } from "@/panel/fire/smoke-detector";
import { FireExtinguisherPanel } from "@/panel/fire/fire-extinguisher";
import { SprayHeadPanel } from "@/panel/fire/spray-head";
import { FireHydrantPanel } from "@/panel/fire/fire-hydrant";
import { MonitorPanel } from "@/panel/electricity/monitor";
import ComponentPanel from "@/panel/component";
import StreetLamp from "@/panel/street-lamp";

interface Iprops {
    width: number;
}
export default function SideBar(props: Iprops) {
    const [sidePanel, setSidePanel] = useState<string>("");

    useEffect(() => {
        setSidePanel(localStore.selectedPanel);
    }, [localStore.selectedPanel]);

    return (
        <div style={{
            height: '100%'
        }}>
            {sidePanel === SIDE_TYPE.road_network && <RoadNetworkPanel width={props.width} />}
            {sidePanel === SIDE_TYPE.ground && <GroundPanel width={props.width} />}
            {sidePanel === SIDE_TYPE.road && <RoadPanel width={props.width} />}

            {sidePanel === SIDE_TYPE.axis_net && <AxisNetPanel />}
            {sidePanel === SIDE_TYPE.fence && <FencePanel />}
            {sidePanel === SIDE_TYPE.hole && <HolePanel />}
            {sidePanel === SIDE_TYPE.floor && <FloorPanel />}
            {sidePanel === SIDE_TYPE.ceiling && <CeilingPanel />}
            {sidePanel === SIDE_TYPE.wall && <WallPanel />}

            {sidePanel === SIDE_TYPE.column && <ColumnPanel />}

            {sidePanel === SIDE_TYPE.elevator_car && <ElevatorCarPanel />}
            {sidePanel === SIDE_TYPE.door && <DoorPanel />}
            {sidePanel === SIDE_TYPE.window && <WindowPanel />}

            {sidePanel === SIDE_TYPE.texture && <TexturePanel />}
            {sidePanel === SIDE_TYPE.room && <RoomPanel />}
            {sidePanel === SIDE_TYPE.elevator && <ElevatorPanel />}
            {sidePanel === SIDE_TYPE.pump_room && <PumpRoomPanel />}
            {sidePanel === SIDE_TYPE.fire_control_room && <FireControlRoomPanel />}

            {sidePanel === SIDE_TYPE.parking_space && <ParkingSpace />}
            {sidePanel === SIDE_TYPE.no_motor_parking_space && <NoMotorParkingSpace />}

            {sidePanel === SIDE_TYPE.storey && <StoreyPanel width={props.width} />}
            {sidePanel === SIDE_TYPE.monitor && <MonitorPanel width={props.width} />}            
            {sidePanel === SIDE_TYPE.patrol_point && <PatrolPointPanel width={props.width} />}            
            {sidePanel === SIDE_TYPE.tree && <TreePanel />}

            {sidePanel === SIDE_TYPE.smoke_detector && <SmokeDetectorPanel />}
            {sidePanel === SIDE_TYPE.fire_extinguisher && <FireExtinguisherPanel />}
            {sidePanel === SIDE_TYPE.spray_head && <SprayHeadPanel />}
            {sidePanel === SIDE_TYPE.fire_hydrant && <FireHydrantPanel />}
            {sidePanel === SIDE_TYPE.component && <ComponentPanel />}
            {sidePanel === SIDE_TYPE.street_lamp && <StreetLamp />}
        </div>
    )
}
