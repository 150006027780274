import localStore from "@/commons/store/local-store";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";
import qs from "qs";


export function createPrimitive(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives`, data, {
        params: {
            ...params,
            projectUuid: localStore.projectId
        }
    })
}

export function createPrimitiveMulti(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/multi`, data, {
        params: {
            ...params,
            projectUuid: localStore.projectId
        }
    })
}

export function findPrimitivesInGraphic(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives`, {
        params: {
            ...params,
        }
    })
}
export function findPrimitivesInGraphicByTypes(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives/types`, {
        params: {
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}
export function findPrimitivesByName(projectUuid: string, name: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives/name`, {
        params: {
            ...params,
            name: name,
        }
    })
}
export function findPrimitivesByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/primitives/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function deletePrimitivesInGraphic(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/delete`, uuids, {
        params: {
            ...params,
            projectUuid: localStore.projectId
        }
    })
}
export function updateComponentEntities(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/components/multi`, data, {
        params: {
            ...params,
        }
    })
}