import { graphicStore } from "../store/graphic-store";

export function onActiveSubControl(cmd: string){
    //TODO 可以在激活控制器前添加权限判断
    if(!graphicStore.extraContext || !graphicStore.extraContext.getCurrentViewEditor()) return;
    const editor = graphicStore.extraContext.getCurrentViewEditor();

    //如果当前控制器已被激活，则结束当前二级控制器，再重新生成二级控制器
    if(editor.cmdControl.currentControl){
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.dispatch();
    }
    
    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.onOpeCommandActive.dispatch(cmd);

}