import * as ModelUtils from './model-utils'

export const HexStringToKeywords = {
    '#ff0000': '红',
    '#ffff00': '黄',
    '#00ff00': '绿',
    '#00ffff': '青',
    '#0000ff': '蓝',
    '#ff00ff': '洋红',
    '#ffffff': '白',
    '#808080': '8',
    '#c0c0c0': '9',
}

export const HexStringToRGBString = (value) => {

    let m = /^\#([A-Fa-f\d]+)$/.exec(value);
    const hex = m[1];
    const size = hex.length;

    if (size === 3) {

        // #ff0
        let r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
        let g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
        let b = parseInt(hex.charAt(2) + hex.charAt(2), 16);

        return `${r},${g},${b}`;

    } else if (size === 6) {

        // #ff0000
        let r = parseInt(hex.charAt(0) + hex.charAt(1), 16);
        let g = parseInt(hex.charAt(2) + hex.charAt(3), 16);
        let b = parseInt(hex.charAt(4) + hex.charAt(5), 16);

        return `${r},${g},${b}`;

    }

    return null
}

export const HexStringToRGB = (value) => {

    let m = /^\#([A-Fa-f\d]+)$/.exec(value);
    const hex = m[1];
    const size = hex.length;

    if (size === 3) {

        // #ff0
        let r = parseInt(hex.charAt(0) + hex.charAt(0), 16);
        let g = parseInt(hex.charAt(1) + hex.charAt(1), 16);
        let b = parseInt(hex.charAt(2) + hex.charAt(2), 16);

        return { r, g, b, a: 1.0 };

    } else if (size === 6) {

        // #ff0000
        let r = parseInt(hex.charAt(0) + hex.charAt(1), 16);
        let g = parseInt(hex.charAt(2) + hex.charAt(3), 16);
        let b = parseInt(hex.charAt(4) + hex.charAt(5), 16);

        return { r, g, b, a: 1.0 };

    }

    return null
}

export const HexToRGB = (value) => {
    let hex = Math.floor(value);

    let r = (hex >> 16 & 255) / 255;
    let g = (hex >> 8 & 255) / 255;
    let b = (hex & 255) / 255;

    return { r, g, b, a: 1.0 };
}

export const RGBToHex = (value) => {
    return (value.r) << 16 ^ (value.g) << 8 ^ (value.b) << 0;
}

export const RGBToHexString = (value) => {
    let res = ('000000' + RGBToHex(value).toString(16)).slice(- 6);
    return `#${res}`;
}

export const HSVToRGB = (value) => {
    var h = value.h, s = value.s, v = value.v;
    // s = s / 100;
    // v = v / 100;
    var r = 0, g = 0, b = 0;
    var i = Math.floor((h / 60) % 6);
    var f = h / 60 - i;
    var p = v * (1 - s);
    var q = v * (1 - f * s);
    var t = v * (1 - (1 - f) * s);
    switch (i) {
        case 0:
            r = v; g = t; b = p;
            break;
        case 1:
            r = q; g = v; b = p;
            break;
        case 2:
            r = p; g = v; b = t;
            break;
        case 3:
            r = p; g = q; b = v;
            break;
        case 4:
            r = t; g = p; b = v;
            break;
        case 5:
            r = v; g = p; b = q;
            break;
        default:
            break;
    }
    r = Math.round(r * 255.0)
    g = Math.round(g * 255.0)
    b = Math.round(b * 255.0)
    return { r, g, b, a: value.a };
}

// export const HSVToHSL = (value) => {
//     let h = value.h/60
// }

export const RGBToHSV = (value) => {
    var h = 0, s = 0, v = 0;
    var r = value.r, g = value.g, b = value.b;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    v = max / 255;
    if (max === 0) {
        s = 0;
    } else {
        s = 1 - (min / max);
    }
    if (max === min) {
        h = 0;
    } else if (max === r && g >= b) {
        h = 60 * ((g - b) / (max - min)) + 0;
    } else if (max === r && g < b) {
        h = 60 * ((g - b) / (max - min)) + 360
    } else if (max === g) {
        h = 60 * ((b - r) / (max - min)) + 120
    } else if (max === b) {
        h = 60 * ((r - g) / (max - min)) + 240
    }
    h = Math.round(h * 100) / 100;
    s = Math.round(s * 100) / 100;
    v = Math.round(v * 100) / 100;
    return { h, s, v, a: value.a }
}

export const RGBToHSL = (value) => {
    // let r=value.r/255;
    // let g=value.g/255;
    // let b=value.b/255;

    // var min=Math.min(r,g,b);
    // var max=Math.max(r,g,b);
    // let l=(min+max)/2;
    // let h = 0;
    // let s = 0;
    // var difference = max-min;

    // if(max==min){
    // 	h=0;
    // 	s=0;
    // }else{
    // 	s=l>0.5?difference/(2.0-max-min):difference/(max+min);
    // 	switch(max){
    // 		case r: h=(g-b)/difference+(g < b ? 6 : 0);break;
    // 		case g: h=2.0+(b-r)/difference;break;
    // 		case b: h=4.0+(r-g)/difference;break;
    // 	}
    // 	h=Math.round(h*60 * 100) / 100;
    // }

    // s=Math.round(s*100)/100;
    // l=Math.round(l*100)/100;
    // return {h,s,l, a: value.a};

    const r = value.r / 255, g = value.g / 255, b = value.b / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let hue, saturation;
    const lightness = (min + max) / 2.0;

    if (min === max) {

        hue = 0;
        saturation = 0;

    } else {

        const delta = max - min;

        saturation = lightness <= 0.5 ? delta / (max + min) : delta / (2 - max - min);

        switch (max) {

            case r: hue = (g - b) / delta + (g < b ? 6 : 0); break;
            case g: hue = (b - r) / delta + 2; break;
            case b: hue = (r - g) / delta + 4; break;

        }

        hue /= 6;

    }

    return { h: hue * 360, s: saturation, l: lightness, a: value.a };
}

export const HSLToRGB = (value) => {
    // var h=value.h/360;
    // var s=value.s;
    // var l=value.l;
    // // var rgb=[];
    // var r,g,b;

    // if(s==0){
    //     r = Math.round(l*255);
    //     g = Math.round(l*255);
    //     b = Math.round(l*255);
    //     return {r, g, b, a: value.a};
    // }else{
    // 	var q=l>=0.5?(l+s-l*s):(l*(1+s));
    // 	var p=2*l-q;
    // 	var tr=r=h+1/3;
    // 	var tg=g=h;
    // 	var tb=b=h-1/3;
    //     let rgb = [r, g, b]
    // 	for(var i=0; i<rgb.length;i++){
    // 		var tc=rgb[i];
    // 		if(tc<0){
    // 			tc=tc+1;
    // 		}else if(tc>1){
    // 			tc=tc-1;
    // 		}
    // 		switch(true){
    // 			case (tc<(1/6)):
    // 				tc=p+(q-p)*6*tc;
    // 				break;
    // 			case ((1/6)<=tc && tc<0.5):
    // 				tc=q;
    // 				break;
    // 			case (0.5<=tc && tc<(2/3)):
    // 				tc=p+(q-p)*(4-6*tc);
    // 				break;
    // 			default:
    // 				tc=p;
    // 				break;
    // 		}
    // 		rgb[i]=Math.round(tc*255);
    // 	}
    //     return {r: rgb[0], g: rgb[1], b: rgb[2], a: value.a};
    // }

    // h,s,l ranges are in 0.0 - 1.0
    let h = ModelUtils.euclideanModulo(value.h, 1);
    let s = ModelUtils.clamp(value.s, 0, 1);
    let l = ModelUtils.clamp(value.l, 0, 1);
    var r, g, b;

    if (s === 0) {

        r = g = b = l;

    } else {

        const p = l <= 0.5 ? l * (1 + s) : l + s - (l * s);
        const q = (2 * l) - p;

        r = hue2rgb(q, p, h + 1 / 3);
        g = hue2rgb(q, p, h);
        b = hue2rgb(q, p, h - 1 / 3);

    }
    return { r, g, b, a: 1.0 }
}

function hue2rgb(p, q, t) {

    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return Math.round((p + (q - p) * 6 * t) * 255);
    if (t < 1 / 2) return Math.round(q * 255);
    if (t < 2 / 3) return Math.round((p + (q - p) * 6 * (2 / 3 - t)) * 255);
    return Math.round(p * 255);

}