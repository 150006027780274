import { StdResponse, bimAxios } from "@/config/axios";


export function getUserByLogin(username: string, password: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/users/login`, null, {
        params: {
            ...params,
            username: username,
            password: password,
        }
    })
}

export function getUsersCheckLogin(params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/users/check-login`, {
        params: {
            ...params,
        }
    })
}