import { useState } from "react";
import { Storey } from "../interface/building/storey";

export interface StoreyStatus {
    storeys: Storey[]
    setStoreys: (storeys: Storey[]) => void

    currentStorey: Storey
    setCurrentStorey: (storey: Storey) => void
}

export function useStoreyService() {
    const [storeys, setStoreys] = useState<Storey[]>([]);
    const [currentStorey, setCurrentStorey] = useState<Storey>(null);

    return {
        storeys, setStoreys,
        currentStorey, setCurrentStorey,
    }
}