/**
 * 本文件存储了额外在外边注册的signal信号的名称
 */

export const EXTRA_SINGAL = {
    onAssembleFenceByLine: "onAssembleFenceByLine", // 通过选基准线布置围墙
    onAssembleFenceByPoint: "onAssembleFenceByPoint", // 通过指定角点布置围墙
    onAssembleHole: "onAssembleHole", // 布置洞口
    onAssembleWall: "onAssembleWall", // 布置墙
    onAssembleDoor: "onAssembleDoor", // 布置门
    onAssembleWindow: "onAssembleWindow", // 布置窗
    openComponentModal: "openComponentModal", // 打开编辑为组件的弹窗
    onSelectComAxisStart: "onSelectComAxisStart", // 创建组件时添加临时坐标系
    onSelectComAxisEnd: "onSelectComAxisEnd", // 创建组件时添加临时坐标系完成
    // onAddComponent: "onAddComponent", // 创建组件
}