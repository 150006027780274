import { Component } from "@/commons/interface/speccommon/component";
import { StdResponse, bimAxios } from "@/config/axios";

export function createComponent(data: any, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/components`, data, {
        params: {
            ...params,
        }
    })
}

export function getComponents(params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/components`, {
        params: {
            ...params,
        }
    })
}
export function getComponentInfoByName(projectId:string,name: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectId}/components/name`, {
        params: {
            ...params,
            name:name,
        }
    })
}

export function deleteComponent(componentUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/components/${componentUuid}`, {
        params: {
            ...params,
        }
    })
}

export function updateComponent(data: Component, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.put(`/components`, data, {
        params: {
            ...params,
        }
    })
}