import classNames from 'classnames';
import './index.less';
import '@/commons/styles/tn-menu.less';
import { MENU_MODULE, MENU_MODULE_LIST } from '@/commons/enums/menu-item';
import BuildingMenu from './building-menu';
import { useEffect, useRef, useState } from 'react';
import ElectricityMenu from './electricity-menu';
import WaterMenu from './water-menu';
import FireMenu from './fire-menu';
import HavcMenu from './havc-menu';
import GeometryMenu from './geometry-menu';
import FileMenu from './file-menu';


interface Iprops {

}


export default function MenuBar(props: Iprops) {

    const scrollRef = useRef(null);
    const [activeMajor, setActiveMajor] = useState<number>(MENU_MODULE.BUILDING);

    const handleWheel = (event) => {
        event.preventDefault();
        const delta = event.deltaY || event.detail || event.wheelDelta;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += delta;
        }
    };

    useEffect(() => {
        const divElement = scrollRef.current;
        if (divElement) {
            divElement.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, [handleWheel]);

    return (
        <div className='project-menu-container'>
            <div className='top-container'>
                {MENU_MODULE_LIST?.map((item, idx) => (
                    <div key={idx}
                        className={classNames({
                            'major-block': true,
                            'major-block-active': item.value === activeMajor
                        })}
                        onClick={() => setActiveMajor(item.value)}
                    >{item.label}</div>
                ))}
            </div>
            <div className='bottom-container' ref={scrollRef}>
                {activeMajor === MENU_MODULE.FILE && <FileMenu />}
                {activeMajor === MENU_MODULE.GEOMETRY && <GeometryMenu />}
                {activeMajor === MENU_MODULE.BUILDING && <BuildingMenu />}
                {activeMajor === MENU_MODULE.ELECTRICITY && <ElectricityMenu />}
                {activeMajor === MENU_MODULE.WATER && <WaterMenu />}
                {activeMajor === MENU_MODULE.FIRE && <FireMenu />}
                {activeMajor === MENU_MODULE.HAVC && <HavcMenu />}
            </div>
        </div>
    )
}