import { useEffect, useState } from 'react';
import "../../../commons/styles/side-bar.less"
import "./index.less";
import SideBarTool from '@/commons/components/side-bar-tool';
import { graphicStore } from '@/commons/store/graphic-store';
import { Input, Image, message } from 'antd';
import { onActiveSubControl } from "@/commons/utils/graphic-method";
import { BIM_SINGAL, BUILDING_SUB_CONTROL } from "tnbimbase";
import { floatVerification } from "@/commons/utils/antd-input";
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { LayerType } from "@/commons/enums/layer-type";
import { findLayersInProjectUuid, saveLayer } from "@/api/geometry/layer";
import genUUID from '@/commons/utils/gen-uuid';
import { LineType, Layer } from "pytha";
import { getTextureUrl, getTextures } from '@/api/spec-common/texture';
import { TEXTURE_CATEGORY } from '@/commons/enums/texture';
import { Texture } from '@/commons/interface/texture';
import { TextureSelect } from '@/commons/components/texture-select';


export function CeilingPanel() {
    const { projectId } = useParams();


    const [thickness, setThickness] = useState<string>('0'); // 厚度
    const [zbias, setZbias] = useState<string>('0');  //相对标高

    const [loading, setLoading] = useState<boolean>(false);  //布置按钮的loading状态

    const [selectTextureUuid, setSelectTextureUuid] = useState<string>(''); // 选择的材质uuid
    const [textureList, setTextureList] = useState<Texture[]>([]); //材质列表

    const [uploading, setUploading] = useState<boolean>(false);  //上传按钮的loading状态

    const onThicknessChange = (e) => {
        setThickness(floatVerification(e.target.value));
    }

    const onZbiasChange = (e) => {
        setZbias(floatVerification(e.target.value));
    }

    const onSelectChange = (value) => {
        setSelectTextureUuid(value);
    }


    const generateData = (needUpdateMap: boolean = false) => {
        getTextures(
            projectId,
            {
                category: TEXTURE_CATEGORY.ceiling.key
            }).then(res => {
                if (res.status === 200) {
                    let textures = res.data as Texture[];
                    textures.forEach(texture => {
                        if (texture && texture.uuid && texture.url) {
                            texture.completeUrl = getTextureUrl(texture.url);
                        }
                    })

                    if (needUpdateMap) {
                        textures.forEach(texture => {
                            if (texture && texture.uuid && texture.url) {
                                graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = getTextureUrl(texture.url);
                            }
                        })
                    }
                    setTextureList(textures);
                    if (needUpdateMap) {
                        message.success(`上传成功!`);
                        setUploading(false);
                    }
                }
            }).finally(() => {
                if(uploading) {
                    setUploading(false);
                }
            });
    }

    useEffect(() => {
        setLoading(false);
        generateData();
    }, [projectId])


    const onAssembleClick = (useRegion: boolean = true) => {
        setLoading(true);
        findLayersInProjectUuid(projectId).then(res => {
            let layers = res.data || [];
            let layer = layers.find(layer => layer.type === LayerType.CEILING);
            if (layer) {
                onActiveSubControl(BUILDING_SUB_CONTROL.ADDCEILING.name);
                graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.INIT_CEILING_CTRL)
                    .dispatch({ zbias: zbias, useRegion: useRegion, thickness: thickness, layerUuid: layer.uuid, textureUuid: selectTextureUuid });
                setLoading(false);
            } else {
                let uuid = genUUID();
                let newLayer: Layer = {
                    uuid: uuid,
                    name: "吊顶",
                    color: "#000000",
                    lineWidth: 0.25,
                    lineType: LineType.BASE_LINE,
                    lock: false,
                    hidden: false,
                    originLayer: false,
                    type: LayerType.CEILING,
                };
                saveLayer(newLayer).then(_ => {
                    onActiveSubControl(BUILDING_SUB_CONTROL.ADDCEILING.name);
                    graphicStore.extraContext.listeners.getSignal(BIM_SINGAL.INIT_CEILING_CTRL)
                        .dispatch({ zbias: zbias, useRegion: useRegion, thickness: thickness, layerUuid: newLayer.uuid, textureUuid: selectTextureUuid })
                    let newLayers = [...graphicStore.context.layerContext.layers, newLayer];
                    graphicStore.context.layerContext.setLayers(newLayers);  //更新几何库图层
                }).finally(() => {
                    setLoading(false);
                })
            }
        }).finally(() => {
            if (loading) {
                setLoading(false);
            }
        });
    }

    const handleChange = (info) => {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            generateData(true);
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setUploading(false);
        }
    }

    const beforeUpload = (file) => {
        setUploading(true);
        return true;
    }


    //TODO 下面的通用物理信息没加



    return (
        <div className="side-bar-main building-ceiling-container">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    吊顶
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">厚度:</div>
                        <Input
                            value={thickness}
                            className="input-box"
                            onChange={onThicknessChange}
                            suffix="mm"
                        />
                    </div>

                    <div className="row">
                        <div className="label">相对标高:</div>
                        <Input
                            value={zbias}
                            className="input-box"
                            onChange={onZbiasChange}
                            suffix="mm"
                        />
                    </div>
                    <div className='test'>
                        <Image width={228} height={54} preview={false} src={require('./base-bias.png')} className='img' />
                        <div className='image-text'>
                            {zbias}mm
                        </div>
                    </div>
                    <div className="row texture-row">
                        <div className="label">材质:</div>
                        <TextureSelect
                            selectUuid={selectTextureUuid}
                            textureList={textureList}
                            onChange={onSelectChange}
                            category='ceiling'
                            beforeUpload={beforeUpload}
                            onUploadChange={handleChange}
                            loading={uploading}
                        />

                    </div>
                    <div className="ope-area">
                        {loading &&
                            <div className="secondary-btn">
                                <LoadingOutlined />
                            </div>
                        }
                        {!loading &&
                            <div className="secondary-btn" onClick={() => { onAssembleClick() }}>
                                <div className="text">选择面</div>
                            </div>
                        }

                        {loading &&
                            <div className="secondary-btn">
                                <LoadingOutlined />
                            </div>
                        }
                        {!loading &&
                            <div className="secondary-btn" onClick={() => { onAssembleClick(false) }}>
                                <div className="text">绘制吊顶</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}