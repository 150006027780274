import { useEffect, useState } from 'react';
import '../building/ground/index.less';
import SideBarTool from '@/commons/components/side-bar-tool';
import { graphicStore } from '@/commons/store/graphic-store';
import { Select } from 'antd';
import { AddLayerCommand, LineType, ModelBase } from "pytha";
import { useParams } from 'react-router-dom';
import genUUID from '@/commons/utils/gen-uuid';
import { Layer } from '@/commons/interface/layer';
import { LayerType } from '@/commons/enums/layer-type';
import { BUILDING_SINGAL, BUILDING_SUB_CONTROL } from 'tnbimbase';
import LocalScene from '@/commons/components/local-scene';
import { Component } from '@/commons/interface/speccommon/component';
import { getComponents } from '@/api/spec-common/component';
import { ComponentType } from '@/commons/enums/component-type';
import { ReactComponent as AssembleSvg } from '@/commons/icons/side/groud-panel/assemble.svg'
import { ReactComponent as UploadBlackSvg } from "@/commons/icons/panel-icon/upload-black.svg";
import { ImportComponent } from '@/commons/components/import-component/import-component';

export default function TreePanel() {
    const { projectId } = useParams();

    const [treeList, setTreeList] = useState<Component[]>([]);

    const [selectLampUuid, setSelectLampUuid] = useState<String>();

    useEffect(() => {
        getComponentList()
    }, [projectId])

    const onAssembleClick = () => {
        let selectLamp = treeList.find(item => item.uuid == selectLampUuid)
        if (selectLamp == null) {
            return
        }
        let layerUuid = graphicStore.context.layerContext.layers.find(item => item.type == LayerType.TREES)?.uuid ||null;
        if (!layerUuid) {
            // 没有乔灌木图层，新增
            layerUuid = genUUID();
            let newLayer: Layer = {
                id: layerUuid,
                uuid: layerUuid,
                name: '乔灌木',
                color: '#2b2b2b',
                lineWidth: 0.25,
                lineType: LineType.BASE_LINE,
                originLayer: false,
                projectUuid: projectId,
                type: LayerType.TREES,

            }
            new AddLayerCommand(newLayer).execute();
        }
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.signals.onOpeCommandActive.dispatch(BUILDING_SUB_CONTROL.ADDBUILDINGCOMPONENTCONTROL.name);
        graphicStore.extraContext.getCurrentViewEditor()?.listeners.getSignal(BUILDING_SINGAL.initBuildingBlockData).dispatch({ type: "tree", entities: selectLamp.primitives, layerUuid: layerUuid });
    }

    const onImportUpright = () => {
        let input = document.getElementById('import-upright');
        input.click();
    }

    const importSuccess = () => {
        getComponentList();
    }

    const getComponentList = () => {
        getComponents({ projectUuid: projectId, type: ComponentType.TREE }).then(res => {
            let list: Component[] = res.data || [];
            list.forEach(item => {
                let entities = item.entities.map((item: any) => {
                    let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(item.type))();
                    entity.fromJson(item);
                    return entity;
                });
                item.primitives = entities;
            })
            setTreeList(list);
        })
    }

    return (
        <div className="side-bar-main">
            <SideBarTool
                activeBtnList={[]}
            />
            <div className="body">
                <div className="title">
                    乔灌木
                </div>
                <div className="detail">
                    <div className="row">
                        <div className="label">乔灌木:</div>
                        <Select
                            size='small'
                            className="select-box"
                            value={selectLampUuid}
                            // allowClear
                            onChange={(value) => setSelectLampUuid(value)}
                            optionLabelProp='label'
                            dropdownStyle={{ maxHeight: '183px', overflow: 'auto' }}
                        >
                            {treeList.map((item) => (
                                <Select.Option value={item.uuid} key={item.uuid} label={item.name}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        <LocalScene
                                            left={1}
                                            top={1}
                                            color="#DAE3E5"
                                            height={40}
                                            width={40}
                                            entities={item.primitives}
                                            react_id={`upright-${item.uuid}`}
                                        />
                                        {item.name}
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                        <div className="upload-button" onClick={onImportUpright}><UploadBlackSvg /></div>
                        <input type="file" accept=".txt" id="import-upright" onChange={(e) => ImportComponent(e, projectId, ComponentType.TREE, null, importSuccess)} style={{ display: 'none' }} />

                    </div>
                    <div className="ope-btn">
                        <div className="insert-button" onClick={onAssembleClick}>
                            <div className="icon"><AssembleSvg className="icon-svg" /></div>
                            <div className="text">布置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}