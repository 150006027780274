import { useContext, useEffect, useRef, useState } from "react";
import { EditableCell, EditableRow } from "tncet-common";
import './index.less';
import AxisNetDraw from "./axis-draw";
import { Modal, Table, message } from "antd";
import SideBarTool from "@/commons/components/side-bar-tool";
import { SideBarBtnType } from "@/commons/enums/side-bar-btn-type";
import { ReactComponent as AddSvg } from '@/commons/icons/panel-icon/add-box-black.svg';
import { ReactComponent as AddHoverSvg } from '@/commons/icons/panel-icon/add-box-white.svg';
import { ReactComponent as BoxSvg } from '@/commons/icons/axis-net/box.svg';
import { ReactComponent as BoxSelectedSvg } from '@/commons/icons/axis-net/box-selected.svg';
import { ReactComponent as NetSvg } from '@/commons/icons/axis-net/net.svg';
import { ReactComponent as NetHoverSvg } from '@/commons/icons/axis-net/net-white.svg';
import { Storey } from "@/commons/interface/building/storey";
import { floatVerification } from "@/commons/utils/antd-input";
import IconButton from "@/commons/widget/icon-button";
import TnModal from "@/commons/widget/base-modal";
import genUUID from "@/commons/utils/gen-uuid";
import { useParams } from "react-router-dom";
import { addStorey, deleteStoreys, getStoreys, updateStorey } from "@/api/building/storey";
import { graphicStore } from "@/commons/store/graphic-store";
import { ModelBase, UpdatePrimitivesCommand } from "pytha";
import { Vector3 } from "three";
import { StoreyContext } from "@/App";
import { StoreyStatus } from "@/commons/context/storey-store";


export default function AxisNetPanel() {
    const { projectId } = useParams();
    const ref = useRef(null);
    const storeyContext = useContext<StoreyStatus>(StoreyContext);
    const [showModel, setShowModel] = useState<boolean>(false);
    const [storeys, setStoreys] = useState<Storey[]>([]);
    const [selected, setSelected] = useState<Storey>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    useEffect(() => {
        if (!projectId) {
            setStoreys([]);
            storeyContext.setStoreys([]);
            return;
        }
        getStoreys(projectId).then(res => {
            if (res.status === 200) {
                let data: Storey[] = res.data || [];
                setStoreys(data);
                storeyContext.setStoreys(data);
            }
        }).catch(err => {
            console.log('err', err)
        })
    }, [projectId])

    // useEffect(() => {
    //     if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
    //     graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onAssembleBlockFinish).add(onAssembleBlockFinish);

    //     return () => {
    //         if (!graphicStore?.extraContext?.getCurrentViewEditor()) return;
    //         graphicStore.extraContext.getCurrentViewEditor().listeners.getSignal(BUILDING_SINGAL.onAssembleBlockFinish).remove(onAssembleBlockFinish);
    //     }
    // }, [graphicStore?.extraContext?.getCurrentViewEditor()])

    // const onAssembleBlockFinish = (entities, basePoint) => {
    //     let layerUuid = graphicStore.context.layerContext.layers?.find(item => item?.type === LayerType.AXIS_NET)?.uuid || null;
    //     if (!layerUuid) {
    //         layerUuid = graphicStore.context.layerContext.currentLayerUuid;
    //     }
    //     let first = new Vector3(0, 0, 0);
    //     let second = basePoint;
    //     let dir = second.clone().sub(first.clone()).normalize();
    //     let dist = first.distanceTo(second);
    //     entities?.forEach((item: ModelBase) => {
    //         item.layerUuid = layerUuid;
    //         item.isColorByLayer = true;
    //         item.move(dir, dist);
    //     })
    //     graphicStore.extraContext.getCurrentViewEditor().history.execute(new AddPrimitivesCommand(entities));
    // }

    const onClose = () => setShowModel(false);

    const onSave = (corner: string) => {
        setShowModel(false);
    }

    const onDeleteClick = () => {
        if (selectedRowKeys?.length === 0) {
            message.info("未选中层");
            return;
        }
        TnModal().deleteModal({
            closable: false,
            onOk() {
                let uuids = [...selectedRowKeys];

                deleteStoreys(projectId, uuids).then(res => {
                    let newStoreys = storeys?.filter(item => !selectedRowKeys?.some(key => key === item.uuid));
                    setStoreys(newStoreys);
                    storeyContext.setStoreys(newStoreys);
                    setSelectedRowKeys([]);
                    let storeyPris = graphicStore.extraContext.getCurrentViewEditor().getEntities()?.filter(item => uuids?.some(uuid => item?.storeyUuid === uuid));
                    storeyPris?.forEach(pri => {
                        graphicStore.extraContext.getCurrentViewEditor().removeObjectByUuid(pri.uuid);
                    })
                    graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
                    message.success("删除成功");
                }).catch(err => {
                    console.log('err', err)
                }).finally(() => {
                    Modal.destroyAll();
                })
            },
            onCancel() {
                Modal.destroyAll();
            },
        })

    }

    const addBtnClick = () => {
        if (!projectId) return;
        let storey: Storey = {
            uuid: genUUID(),
            projectUuid: projectId,
            height: 3000,
            bottomElevation: 0,
        };
        if (storeys?.length > 0) {
            let { maxSerialNum, lastBottomElevation, lastHeight } = storeys?.reduce((acc, item) => {
                acc.maxSerialNum = Math.max(acc.maxSerialNum, item.serialNum || 0);
                acc.lastBottomElevation = item.bottomElevation || acc.lastBottomElevation;
                acc.lastHeight = item.height || acc.lastHeight;
                return acc;
            }, { maxSerialNum: 0, lastBottomElevation: 0, lastHeight: 0 })
            storey.name = `${storeys?.length + 1}F`
            storey.serialNum = maxSerialNum + 1;
            storey.bottomElevation = ((lastBottomElevation * 1000) + lastHeight) / 1000;
        } else {
            storey.name = `1F`;
            storey.serialNum = 1;
        }
        addStorey(projectId, storey).then(res => {
            storeys.push(storey);
            setStoreys([...storeys]);
            storeyContext.setStoreys([...storeys]);
            message.success("添加成功");
        }).catch(err => {
            console.log('err', err)
        })
    }

    const onSelectAllCick = () => {
        if (selectedRowKeys?.length === storeys?.length || selectedRowKeys?.length === 0) {
            // 全部选中或无数据 或者 无选中
            const newSelectedRowKeys = selectedRowKeys?.length === storeys?.length ? [] : storeys.map(item => item.uuid);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            // 部分选中
            setSelectedRowKeys(storeys?.map(item => item.uuid));
        }
    }

    const onBoxClick = (recrod: Storey) => {
        let idx = selectedRowKeys?.findIndex(item => item === recrod.uuid);
        if (idx < 0) {
            selectedRowKeys.push(recrod.uuid);
            setSelectedRowKeys([...selectedRowKeys])
        } else {
            selectedRowKeys.splice(idx, 1);
            setSelectedRowKeys([...selectedRowKeys])
        }
    }

    const onChangeField = (item: Storey, oldItem: Storey, index: number, field) => {
        if (field === 'height' || field === 'bottomElevation') {
            let value = floatVerification(item[field]?.toString());
            if (!value || value == '') {
                item[field] = 0;
            } else {
                item[field] = Number(value) || 0;
            }
        }
        if (item[field] === oldItem[field]) {
            return;
        }

        storeys[index] = { ...item };

        if (field === 'height') {
            let dist = item[field] - oldItem[field];

            // 连锁更新所有后续 storey 的 bottomElevation
            for (let i = index + 1; i < storeys.length; i++) {
                const previousStorey = storeys[i - 1];
                storeys[i].bottomElevation = ((previousStorey.bottomElevation * 1000) + previousStorey.height) / 1000;
            }

            setStoreys([...storeys]);
            storeyContext.setStoreys([...storeys]);
            storeys?.forEach((storey, idx) => {
                if (idx >= index) {
                    updateStorey(storey.uuid, { ...storey }).then(res => {
                        if (idx > index) {
                            let pris: ModelBase[] = graphicStore.extraContext.getCurrentViewEditor().getEntities()?.filter(item => item?.storeyUuid === storey.uuid);
                            let newPris: ModelBase[] = [];
                            pris.forEach(item => {
                                let priClone = item.clone();
                                priClone.uuid = item.uuid;
                                priClone.move(new Vector3(0, 0, 1), dist);
                                newPris.push(priClone);
                            })
                            new UpdatePrimitivesCommand(pris, newPris).execute();
                            graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
                        }
                    }).catch(err => {
                        message.error("修改失败");
                    })
                }
            })
        } else if (field === 'bottomElevation') {
            let dist = (item[field] - oldItem[field]) * 1000;
            // 连锁更新所有后续 storey 的 bottomElevation
            for (let i = index + 1; i < storeys.length; i++) {
                const previousStorey = storeys[i - 1];
                storeys[i].bottomElevation = ((previousStorey.bottomElevation * 1000) + previousStorey.height) / 1000;
            }

            setStoreys([...storeys]);
            storeyContext.setStoreys([...storeys]);
            storeys?.forEach((storey, idx) => {
                if (idx >= index) {
                    updateStorey(storey.uuid, { ...storey }).then(res => {
                        let pris: ModelBase[] = graphicStore.extraContext.getCurrentViewEditor().getEntities()?.filter(item => item?.storeyUuid === storey.uuid);
                        let newPris: ModelBase[] = [];
                        pris.forEach(item => {
                            let priClone = item.clone();
                            priClone.uuid = item.uuid;
                            priClone.move(new Vector3(0, 0, 1), dist);
                            newPris.push(priClone);
                        })
                        // graphicStore.extraContext.getCurrentViewEditor().history.execute(new UpdatePrimitivesCommand(pris, newPris));
                        new UpdatePrimitivesCommand(pris, newPris).execute();
                        graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
                    }).catch(err => {
                        message.error("修改失败");
                    })
                }
            })
        } else {
            setStoreys([...storeys]);
            storeyContext.setStoreys([...storeys]);
            updateStorey(item.uuid, { ...item }).then(res => {
            }).catch(err => {
                message.error("修改失败");
            })
        }
    }

    return (
        <div className="axis-net-container">
            <SideBarTool
                activeBtnList={[SideBarBtnType.DELETE]}
                onDeleteBtnClick={onDeleteClick}
            />
            <div className="side-title">轴网</div>
            <div className="head">
                设置层
                <IconButton
                    icon={<AddSvg />}
                    hoverIcon={<AddHoverSvg />}
                    onClick={addBtnClick}
                />
            </div>
            <div className="list" ref={ref}>
                <div className="list-content">
                    <Table
                        dataSource={storeys}
                        className='tn-storey-table'
                        pagination={false}
                        bordered={false}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            }
                        }}
                        rowKey="uuid"
                    >
                        <Table.Column
                            title={(<div className='left-head-cell'>
                                {selectedRowKeys?.length !== storeys?.length && <BoxSvg style={{ cursor: 'pointer' }} onClick={onSelectAllCick} />}
                                {selectedRowKeys?.length === storeys?.length && storeys?.length !== 0 && <BoxSelectedSvg style={{ cursor: 'pointer' }} onClick={onSelectAllCick} />}
                                层名
                            </div>)}
                            width={54}
                            dataIndex='name'
                            key='name'
                            align='left'
                            ellipsis={true}
                            onCell={(record: any, index) => {
                                return {
                                    record,
                                    editable: true,
                                    dataIndex: 'name',
                                    title: `${record?.name || ''}`,
                                    cellKey: `${record?.uuid}-name`,
                                    selectedKey: selected?.uuid,
                                    handleSave: (item) => { onChangeField(item, record, index, "name") },
                                }
                            }}
                            render={(value, record: any) => (
                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => onBoxClick(record)}>
                                    {!selectedRowKeys?.some(item => item === record.uuid) && <BoxSvg style={{ cursor: 'pointer' }} />}
                                    {selectedRowKeys?.some(item => item === record.uuid) && <BoxSelectedSvg style={{ cursor: 'pointer' }} />}
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: 30, textAlign: 'right', paddingRight: 4 }}>
                                        {value}
                                    </div>
                                </div>
                            )}
                        />
                        <Table.Column
                            title={(<div className='head-cell'>层高(mm)</div>)}
                            width={76}
                            dataIndex='height'
                            key='height'
                            align='center'
                            ellipsis={true}
                            onCell={(record: any, index) => {
                                return {
                                    record,
                                    editable: true,
                                    dataIndex: 'height',
                                    title: `${record?.height || ''}`,
                                    cellKey: `${record?.uuid}-height`,
                                    selectedKey: selected?.uuid,
                                    handleSave: (item) => { onChangeField(item, record, index, "height") },
                                }
                            }}
                        />
                        <Table.Column
                            title={(<div className='head-cell'>楼层标高(m)</div>)}
                            width={78}
                            dataIndex='bottomElevation'
                            key='bottomElevation'
                            align='center'
                            ellipsis={true}
                            onCell={(record: any, index) => {
                                return {
                                    record,
                                    editable: true,
                                    dataIndex: 'bottomElevation',
                                    title: `${record?.bottomElevation || ''}`,
                                    cellKey: `${record?.uuid}-bottomElevation`,
                                    selectedKey: selected?.uuid,
                                    handleSave: (item) => { onChangeField(item, record, index, "bottomElevation") },
                                }
                            }}
                            render={(value: number, record, index) => (
                                <div>{value?.toFixed(3) || Number(0).toFixed(3)}</div>
                            )}
                        />
                        <Table.Column
                            title={(<div className='head-cell'></div>)}
                            // title="操作"
                            width={26}
                            dataIndex='name'
                            key='name'
                            align='center'
                            ellipsis={true}
                            render={(value, record: any) => (
                                <div style={{ display: 'flex', alignItems: 'center' }} >
                                    <IconButton
                                        className="icon-area"
                                        icon={<NetSvg />}
                                        hoverIcon={<NetHoverSvg />}
                                        onClick={() => {
                                            graphicStore?.extraContext?.getCurrentViewEditor()?.listeners.signals.onOpeCommandControlEnd.dispatch();
                                            setShowModel(true)
                                            setSelected(record)
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </Table>
                </div>
            </div>

            <Modal
                open={showModel}
                mask
                destroyOnClose
                onCancel={onClose}
                centered={false}
                width={596}
                footer={null}
                closeIcon={false}
                wrapClassName="tn-base-modal"
            >
                <AxisNetDraw onClose={onClose} onSave={onSave} storey={selected} />
            </Modal>
        </div>
    )
}