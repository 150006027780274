import { EXTRA_CTRL_TYPE } from "@/commons/enums/extra-control-code";
import { EXTRA_SINGAL } from "@/commons/enums/extra-singal";
import { BasicControl, CURSOR_TYPE, DYMODE_MODE, Line, Listeners, MOUSE, Plane, PlineT, Point, RegionT, SelectionBox, TnEngineContext, TnEngineExtraContext, ViewEditor, dymode } from "pytha";
import { Vector2, Vector3 } from "three";
import { AddDoorToBoundingWallCommand, BoundingWallT, DoorT } from "tnbimbase";

export class AssembleDoorControl extends BasicControl {
    id = EXTRA_CTRL_TYPE.ASSEMBLE_DOOR;

    needInit: boolean;


    // step:0 选择要放置门的平面
    // step:1 放置门
    step: number = 0

    layerUuid: string;
    // 选择的要放置门的体
    selectedSolid: BoundingWallT;
    // 选择的要开洞的体的平面
    rightFacesMap: { [key: number]: RegionT } = {};
    // 门临时图元
    helpDoor: DoorT;
    // 布置在了墙的哪个curve上
    boundingWallCurveIndex: number;

    context: TnEngineContext;
    extraContext: TnEngineExtraContext;

    point_down: Point = new Point();
    point_move: Point = new Point();

    capturePoint: Vector3;

    // 门要布置的工作平面，局部坐标轴
    workPlane: Plane = new Plane();
    axisZ: Vector3 = new Vector3();
    axisX: Vector3 = new Vector3();


    private _pickBox: SelectionBox
    constructor(editor: ViewEditor, listeners: Listeners) {
        super();
        this.needInit = true;

        this.context = TnEngineContext.getInstance();
        this.extraContext = TnEngineExtraContext.getInstance();

        this.editor = editor;
        this.listeners = listeners;

        this.listeners.getSignal(EXTRA_SINGAL.onAssembleDoor).add(this.onAssembleDoor);
        this._pickBox = new SelectionBox(editor.camera);

        console.log('AssembleDoorControl added')
    }

    dispose(): void {
        this.listeners.getSignal(EXTRA_SINGAL.onAssembleDoor).remove(this.onAssembleDoor);
        this.editor.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirm);
        this.listeners.signals.onDymodeChanged.remove(this.onDymodeChanged);
        this.listeners.signals.onPointCapture.remove(this.onPointCapture);
        this.listeners.signals.onKeyMouseConfirm.remove(this.onKeyMouseConfirm);
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
        this.listeners.signals.noNeedCapture.dispatch();
        this.editor.selectControl.setEnabled(true);
        this.extraContext.dymodeContext.enabled = false;
        
        this.editor.clampControl.enabled = true;
        this.editor.clampControl.hidden = false;
        this.extraContext.selectContext.forceMeshSelect = false;  //取消强制使用面选择
        this.listeners.signals.onOnePickOnly.dispatch(false);
        if (this.helpDoor && this.helpDoor.viewObj && this.helpDoor.viewObj.parent) {
            this.helpDoor.viewObj.parent?.remove(this.helpDoor.viewObj);
        }

        console.log('AssembleDoorControl removed')
    }

    onPointCapture = (point) => {
        this.capturePoint = point;
    }

    initControl(data: any): void {
        this.onAssembleDoor(data.layerUuid, data.door)
    }

    onAssembleDoor = (layerUuid: string, door: DoorT) => {
        let initData = {
            layerUuid: layerUuid,
            door: door,
        }
        this.listeners.signals.setInitControlData.dispatch(initData);

        this.layerUuid = layerUuid;
        if (door == null) {
            this.extraContext.stmodeContext.pushTip(`缺少门组件`);
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
        }
        this.helpDoor = door.clone();
        // 派发置空选择集的信号
        this.listeners.signals.emptySelectedEntities.dispatch();
        this.extraContext.dymodeContext.setDymodeTip('选择墙上的平面:');
        this.extraContext.dymodeContext.setMode(DYMODE_MODE.TIPONLY);
        this.extraContext.stmodeContext.setStmode('选择墙上的平面');
        this.listeners.signals.onOnePickOnly.dispatch(true);
        // 激活选择
        this.editor.selectControl.setEnabled(true);
        // 钝化夹点
        this.editor.clampControl.enabled = false;
        this.editor.clampControl.hidden = true;
        this.extraContext.selectContext.forceMeshSelect = true;  //强制使用面选择
        this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.PICK);
        // 订阅确认信号
        this.listeners.signals.onKeyMouseConfirm.add(this.onKeyMouseConfirm);
        // 监听鼠标按下
        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
    }

    _dymodeActive = false;
    onDymodeChanged = (value: dymode) => {
        if (value.mode === DYMODE_MODE.POINT) {
            this.point_down.set(value.x, value.y, value.z);
            this._dymodeActive = true;
            this.onKeyMouseConfirm();
        }
    }

    private _foot = new Vector3();
    onKeyMouseConfirm = () => {
        if (this.step == 0) {
            this.extraContext.stmodeContext.pushTip(`请选择墙上的平面`);
        } else if (this.step == 1) {
            if (!this._dymodeActive) {
                this.listeners.signals.onOpeCommandControlEnd.dispatch();
            } else {
                this.workPlane.projectPoint(this.point_down, this._foot);
                this.point_down.copy(this._foot);
                this.insertDoor();
            }
        }
    }

    private _pointLast = new Point();
    private _rightPline: PlineT = new PlineT();

    // 获取所有的平面
    // todo 目前只获得墙面外面的平面
    getSelectedFaces() {
        const boundingWall = this.selectedSolid;
        this._rightPline = boundingWall.basePline.clone();
        if (boundingWall.rightWidth > 0) {
            this._rightPline.offsetToLeft(false, boundingWall.rightWidth);
        }
        this._rightPline._geoCurves.forEach((item, index) => {
            if (item.type == 'Line') {
                let line = item as Line;
                let start = line.getPointAt(0);
                let end = line.getPointAt(1);
                let heightDelta = boundingWall._geo.direction.clone().multiplyScalar(boundingWall._geo.height);
                let topStart = start.clone().add(heightDelta);
                let topEnd = end.clone().add(heightDelta);
                let region = new RegionT([
                    new Line(start, end),
                    new Line(end, topEnd),
                    new Line(topEnd, topStart),
                    new Line(topStart, start),
                ])
                this.rightFacesMap[index] = region;
            }
        })
    }

    private _dir = new Vector3();

    onPointerMove = (event) => {
        if (!!!this.helpDoor) {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
            return;
        }
        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
        this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_move, this.workPlane);
        if (this.capturePoint) {
            this.point_move.copy(this.capturePoint);
        }
        this.workPlane.projectPoint(this.point_move, this._foot);
        this.point_move.copy(this._foot);
        this._dir.subVectors(this.point_move, this.helpDoor.position)
        let length = this._dir.length();
        this._dir.normalize();
        this.helpDoor.move(this._dir, length);
        this.helpDoor.updateViewGeometry();
        this._pointLast.copy(this.point_move);
        this.listeners.signals.needRender.dispatch();
    }

    onPointerDown = (event) => {
        if (event.button === MOUSE.RIGHT) {
            this.listeners.signals.onOpeCommandControlEnd.dispatch();
            return;
        }
        if (event.button !== MOUSE.LEFT) return;
        if (this.step == 0) {
            let selectedEntities = this.editor.selectControl.getSelectedEntityList().filter(entity => !entity.lock && entity.baseType == "ExtrudeSolidT");
            if (selectedEntities.length > 0) {
                this.editor.selectControl.setEnabled(false);
                this.selectedSolid = selectedEntities[0] as BoundingWallT;
                this.getSelectedFaces();
                let mesh: RegionT = this.getSelectedMesh(event);
                if (mesh == null) {
                    this.extraContext.stmodeContext.pushTip(`请选择墙上的平面`);
                    return;
                }
                // 门要放置的面作为工作平面
                // 门的z轴朝外
                this.workPlane.copy(mesh._geoPolygon);
                // 当前光标在该面上的坐标
                let rect = this.editor.renderer.domElement.getBoundingClientRect();
                let x = (event.clientX - rect.x) / rect.width * 2 - 1;
                let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
                this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_down, this.workPlane);
                // 移动位置
                this._dir.subVectors(this.point_down, this.helpDoor.position)
                let length = this._dir.length();
                this._dir.normalize();
                this.helpDoor.move(this._dir, length);
                // 将门转至该平面上
                let normalRotateAxis = this.helpDoor.normal.clone().cross(this.workPlane.normal).normalize();
                if (Math.abs(this.workPlane.normal.dot(this.helpDoor.normal) + 1) < 1e-6) {
                    normalRotateAxis = this.helpDoor.axisX.clone();
                }
                let normalDeltaAngle = this.workPlane.normal.angleTo(this.helpDoor.normal);
                this.helpDoor.rotate(this.point_down, normalRotateAxis, normalDeltaAngle);
                // 门是朝上的，z是0,0,1
                this.axisX.copy(new Vector3(0, 0, 1)).cross(this.workPlane.normal).normalize();
                let tempAxisX = this.helpDoor.axisX;
                let axisXRotateAxis = tempAxisX.clone().cross(this.axisX).normalize();
                let axisXDeltaAngle = tempAxisX.angleTo(this.axisX);
                if (axisXRotateAxis.length() < 1e-6 || Math.abs(tempAxisX.dot(this.axisX) + 1) < 1e-6) {
                    axisXRotateAxis = this.workPlane.normal.clone();
                }
                this.helpDoor.rotate(this.point_down, axisXRotateAxis, axisXDeltaAngle);
                // 显示临时图元
                if (this.helpDoor && this.helpDoor.viewObj && this.helpDoor.viewObj.parent) {
                    this.helpDoor.viewObj.parent?.remove(this.helpDoor.viewObj);
                }
                this._pointLast.copy(this.point_down);
                this.helpDoor.render();
                this.extraContext.sceneContext.scene.add(this.helpDoor.viewObj);
                // 下一步，放置门
                this.step = 1;
                this.extraContext.dymodeContext.setMode(DYMODE_MODE.POINT);
                this.listeners.signals.sendToDymodeTip.dispatch('指定门位置:');
                this.extraContext.stmodeContext.setStmode('指定门位置');
                this.listeners.signals.onCursorChange.dispatch(CURSOR_TYPE.CROSS);
                this.listeners.signals.needCapture.dispatch();
                this.listeners.signals.onDymodeChanged.add(this.onDymodeChanged);
                this.listeners.signals.onPointCapture.add(this.onPointCapture);
                // 钝化选择
                this.listeners.signals.emptySelectedEntities.dispatch();
                this.editor.selectControl.setEnabled(false);
                // 监听鼠标移动
                this.editor.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
            }
        } else if (this.step == 1) {
            // 指定了门位置
            let rect = this.editor.renderer.domElement.getBoundingClientRect();
            let x = (event.clientX - rect.x) / rect.width * 2 - 1;
            let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
            this.context.ucsContext.unprojectCursorPoint(x, y, this.editor.camera, this.point_down, this.workPlane);
            if (this.capturePoint) {
                this.point_down.copy(this.capturePoint);
            }
            this.workPlane.projectPoint(this.point_down, this._foot);
            this.point_down.copy(this._foot);
            this.insertDoor();
        }
    }

    insertDoor = () => {
        this._dir.subVectors(this.point_down, this.helpDoor.position)
        let length = this._dir.length();
        this._dir.normalize();
        this.helpDoor.move(this._dir, length);
        this.helpDoor.updateViewGeometry();
        this._pointLast.copy(this.point_move);
        let door: DoorT = this.helpDoor.clone();
        door.layerUuid = this.layerUuid;
        door.isColorByLayer = true;
        door.boundingWallCurveIndex = this.boundingWallCurveIndex;
        this.editor.history.execute(new AddDoorToBoundingWallCommand((this.selectedSolid as BoundingWallT), door));
        this.listeners.signals.onOpeCommandControlEnd.dispatch();
    }

    // 获得选中的面
    getSelectedMesh(event) {
        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let PickEpsilon = this.extraContext.editStatusContext.PickEpsilon;
        let top_left_x = event.clientX - PickEpsilon;
        let top_left_y = event.clientY - PickEpsilon;
        let bottom_right_x = event.clientX + PickEpsilon;
        let bottom_right_y = event.clientY + PickEpsilon;

        let start_x = (top_left_x - rect.x) / rect.width * 2 - 1;
        let start_y = -(top_left_y - rect.y) / rect.height * 2 + 1;
        let end_x = (bottom_right_x - rect.x) / rect.width * 2 - 1;
        let end_y = -(bottom_right_y - rect.y) / rect.height * 2 + 1;
        this._pickBox.update(new Vector2(start_x, start_y), new Vector2(end_x, end_y))
        let rightSelected = [];
        for (let key in this.rightFacesMap) {
            let entity = this.rightFacesMap[key];
            if (!!entity && !!entity?.rangeShoot(this._pickBox._frustum)) {
                rightSelected.push(key);
            }
        }
        if (rightSelected.length <= 0) return null;
        // 寻找离相机最近的面
        const cameraPosition = this.extraContext.getCurrentViewEditor().camera.position;
        let region = null;
        let boundingWallCurveIndex = null;
        let shortestDistance = Infinity;
        for (let i = 0; i < rightSelected.length; i++) {
            let key = rightSelected[i]
            const distance = this.rightFacesMap[key].getBoundingBox().distanceToPoint(cameraPosition)
            if (distance < shortestDistance) {
                region = this.rightFacesMap[key];
                shortestDistance = distance;
                boundingWallCurveIndex = key;
            }
        }
        this.boundingWallCurveIndex = +boundingWallCurveIndex;
        return region;
    }
}