export const TEXTURE_CATEGORY:
    {
        [key: string]:
        {
            key: number,
            name: string,
            type?: {
                [key: string]:
                {
                    key: number,
                    name: string
                }
            }
        }
    }
    = {
    ground: {
        key: 1,
        name: "地面",
        type: {
            hard: { key: 1, name: "硬质" },
            soft: { key: 2, name: "软质" }
        }
    }
    ,
    tree: {
        key: 2,
        name: "乔灌木",
        type: {
            normal: { key: 1, name: "乔灌木" },
        }
    },

    ceiling: {
        key: 22,
        name: "吊顶",
        type: {}
    },

    glass: {
        key: 101,
        name: "玻璃",
        type: {}
    },
    aluminiumAlloy: {
        key: 102,
        name: "铝合金",
        type: {}
    },
    wood: {
        key: 103,
        name: "木",
        type: {}
    },
    metal: {
        key: 104,
        name: "金属",
        type: {}
    },
}




export const getCategoryName = (category: number) => {
    const categoryKeyList = Object.keys(TEXTURE_CATEGORY);
    for (let i = 0; i < categoryKeyList.length; i++) {
        const categoryItem = TEXTURE_CATEGORY[categoryKeyList[i]];
        if (categoryItem.key === category) {
            return categoryItem.name
        }
    }
    return null
}


export const getTypeName = (category: number, type: number) => {

    const categoryKeyList = Object.keys(TEXTURE_CATEGORY);

    for (let i = 0; i < categoryKeyList.length; i++) {
        const categoryItem = TEXTURE_CATEGORY[categoryKeyList[i]];
        if (categoryItem.key === category) {
            const typeKeyList = Object.keys(categoryItem.type);
            for (let j = 0; j < typeKeyList.length; j++) {
                const typeItem = categoryItem.type[typeKeyList[j]];
                if (typeItem.key === type) {
                    return typeItem.name;
                }
            }
        }
    }

    return '';
}