/**
 * url提取最后一段路由
 * @param url string
 * @param mark string url需要包含的子字符串
 * @returns 
 */
export function urlExtractLastPart(url: string, mark: string): string {
    // 判断字符串中是否存在子字符串mark
    if (url.includes(mark)) {
        // 如果存在，提取最后一段字符
        const parts = url.split("/");
        if(parts.length > 0){
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }else{
            return "";
        }
    } else {
        return "";
    }
}

/**
 * 从字符串中提取第一段连续数字串
 * @param str 被分割的字符串
 * @returns 
 */
export  function extractFirstNumber(str: string) {
    const match = str.match(/\d+/);
    return match ? match[0] : '';
}