export const NumFixed = (value: number, n: number) => {
    return Math.round(value * Math.pow(10, n))/Math.pow(10, n);
}

export const NumMax = (value: number, n: number) => {
    if ( (Math.round(value * Math.pow(10, n)) - value * Math.pow(10, n)) === 0 ) {
        return value;
    }
    return NumFixed(value, n);
}

export function PrefixInteger(num, length) {
    return (Array(length).join('0') + num).slice(-length);
}